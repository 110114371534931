import styled from 'styled-components'
import { toRem } from 'shared/styles/functions'
import { handleColorType } from './util'

export const ColorStatus = styled.span`
	display: inline-block;
	border-radius: 13px;
	opacity: 1;
	font-size: 10px;
	font-family: 'Medium';
	text-transform: uppercase;
	padding: 6px 12px;
	${({ color }) => handleColorType(color)};
`

export const CustomersListWrapper = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;

	.page-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.inputs-wrapper {
			display: flex;
			justify-content: space-between;

			.select {
				width: ${toRem(308)};
				//text-align: right; Not possible in Select component
				margin-left: 20px;
			}

			#search {
				height: 57px;
			}
		}

		@media (max-width: 700px) {
			& {
				flex-direction: column;

				> h1 {
					align-self: center;
					margin-bottom: 1.25rem;
				}
			}
		}
	}

	.search-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;
	}

	table {
		button {
			background: none;
			outline: none;
			border: none;
		}
	}

	.icon {
		cursor: pointer;
	}
`

export const LoadingContent = styled.section`
	display: flex;
	justify-content: center;
`

export const CustomersInput = styled.div`
	display: flex;
	padding: 20px 0px 20px 0px;
	justify-content: flex-end;
	align-items: center;

	#upload-button {
		margin-top: ${toRem(3)};
	}

	@media (max-width: 410px) {
		& {
			flex-direction: column;

			> * {
				margin: 1.25rem;
				& label {
					margin: 0;
				}
			}
		}
	}
`
