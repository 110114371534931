import { DefaultStyles } from '@buildbox/components'
import { DefaultTheme } from 'styled-components'

export const colors = {
	primary: '#292928',
	secondary: '#FFC709',
	backgroundPreviewImg: '#FFC70929',
	grey: '#979E97',
	darkGrey: '#4F4F4D',
	modalBackground: '#0000008F',
	facebook: '#4c69ba',
	btnSecond: '#00000029',
	backgroundState: '#4F4F4D29',
	colorTextPlaceholder: '#8F8F8B',
	lightGrey: '#EEEEEE',
	white: '#FFFFFF',
	pageBackground: '#F9F9F9',
	separatorColor: '#DFDFD8',
	picpayGreen: '#21C25E',
	backgroundGreen: '#B0E6507A',
	statusActiveColor: '#566E2C',
	statusInactiveColor: '#00000052',
	blackWithOpacity: '#00000000',

	lightBrown: '#4f4f4d',
	error: '#ff3333'
}

export const tableStyle: DefaultStyles = {
	primaryColor: colors.darkGrey,
	secondaryColor: colors.darkGrey,
	backgroundColor: colors.pageBackground,
	fontSize: 12,
	fontFamily: 'Regular',
	padding: [8, 8, 8, 8]
}

export const primaryInputStyle: DefaultStyles = {
	primaryColor: colors.grey,
	secondaryColor: colors.grey,
	backgroundColor: colors.white,
	fontSize: 14,
	fontFamily: 'Regular'
}

export const primaryButtonStyle: DefaultStyles = {
	primaryColor: colors.darkGrey,
	secondaryColor: colors.grey,
	backgroundColor: colors.secondary,
	padding: [16, 36, 16, 36],
	fontSize: 10,
	fontFamily: 'Bold'
}

export const selectStyle: DefaultStyles = {
	primaryColor: colors.darkGrey,
	secondaryColor: colors.white,
	backgroundColor: colors.white,
	fontSize: 16,
	fontFamily: 'Regular'
}

export const datePickerPrimaryStyles: DefaultStyles = {
	// primaryColor: colors.darkGrey,
	// secondaryColor: colors.white,
	backgroundColor: colors.white,
	fontSize: 16,
	fontFamily: 'Regular'
}

export const secondaryButtonStyle: DefaultStyles = {
	primaryColor: colors.white,
	backgroundColor: colors.darkGrey,
	padding: [16, 36, 16, 36],
	fontSize: 10,
	fontFamily: 'Medium'
}

// Please, don't use this anymore.
// It will be deprecated
export { theme }
const theme: DefaultTheme = {
	colors: {
		primary: colors.primary,
		secondary: colors.secondary,
		titleColor: colors.grey,
		modalBackground: colors.modalBackground,
		pageBackground: colors.pageBackground,
		white: colors.white
	}
}
