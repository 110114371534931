const routesEnum = {
	LOGIN: '/login',
	CHANGE_PASSWORD: '/set-pw/:token',
	RESET_PASSWORD: '/reset-pw/:token',
	FORGOT_PASSWORD: '/forgot-pw',
	TRENDS_PAGE: '/page/trends',
	REPORTS_PAGE: '/page/reports',
	USERS_PAGE: '/page/users',
	EVENTS_PAGE: '/page/events',
	CUSTOMERS_PAGE: '/page/customers',
	DASHBOARD_PAGE: '/page/dashboard',
	TIER_TIMER: '/page/timer'
}

export default routesEnum
