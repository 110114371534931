import { toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const ReportsPageWrapper = styled.div`
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.table-loading {
		display: flex;
		justify-content: center;
	}

	.search-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 32px;
	}

	.table-container {
		margin-top: ${toRem(50)};

		.report-title {
			min-width: 250px;
		}
		.report-subtitle {
			min-width: 250px;
		}
		.report-image-container {
			width: 96px;
			height: 45px;
			overflow: hidden;
			border-radius: 2px;
			background: ${colors.backgroundPreviewImg};

			.report-image {
				width: 100%;
				height: 100%;
			}
		}
		.icon {
			min-width: 16px;
			border: none;
			display: flex;
			margin: auto;
			background: transparent;
			cursor: pointer;
			.icon-image {
			}
		}
	}

	@media (max-width: 600px) {
		.modal-wrapper {
			padding: 32px 16px;
		}
	}
`

export const TableImagePlaceholder = styled.div`
	width: 96px;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
	background: ${colors.backgroundPreviewImg};
	border: 1px solid ${colors.secondary};
	.placeholder-icon {
		width: ${toRem(20)};
		height: ${toRem(20)};
		color: ${colors.secondary};
	}
`
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	div {
		display: flex;
		align-items: center;

		#search {
			min-width: ${toRem(250)};
		}
		#button {
			height: ${toRem(45)};
		}
	}

	@media (max-width: 700px) {
		& {
			flex-direction: column;

			> h1 {
				align-self: center;
				margin-bottom: ${toRem(20)};
			}

			div {
				flex-direction: column;

				#button {
					margin-top: ${toRem(20)};
				}
			}
		}
	}
`

export const InputSearchWrapper = styled.div`
	margin-right: ${toRem(20)};

	display: flex;
	justify-content: center;
	align-items: center;

	.loading-wrapper {
		margin-right: 50px;
	}
`
