import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'

function Analytics() {
	const { user } = useTypedSelector(['user'])
	const location = useLocation()

	useEffect(() => {
		if (user?._id) {
			ReactGA.set({ userId: user._id })
		}

		ReactGA.pageview(location.pathname)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	return <></>
}

export default Analytics
