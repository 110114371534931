import styled from 'styled-components'
import { colors } from 'shared/styles/theme'
import { fontType } from 'shared/styles/functions'

export const MainBZElectionAccessSection = styled.div`
	margin-top: 20px;
    margin-bottom: 40px;
`

export const BzElectionSettingsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

export const BZElectionCategory = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	div + div {
		margin-top: 12px;
	}
`

export const LineSeparator = styled.div`
	width: 100%;
	height: 1px;
	background: ${colors.separatorColor};
	margin-bottom: 24px;
`

export const InputCheckBoxContainer = styled.div`
	display: flex;
	align-items: center;

	p {
		margin-left: 8px;
		color: ${colors.lightBrown};
		font-size: 14px;
		${fontType(0)};
		margin-top: 3px;
	}
`

export const SectionContentWrapper = styled.div`
	display: flex;
	align-items: center;

	p {
		color: ${colors.lightBrown};
		${fontType(0)};
		font-size: 14px;
		margin-right: 16px;
	}

	input {
		width: 44px;
		height: 36px;
		border: 1px solid #979e977a;
		border-radius: 2px;
		padding: 5px;
		margin-top: 3px;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
`

export const BZElectionCategoryTitle = styled.div`
	color: ${colors.lightBrown};
	${fontType(0)};
	font-size: 14px;
	margin-bottom: 8px;
`
