import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

import { StylesConfig } from 'react-select'

import { colors } from '../../styles/theme'

export const SectionSpeechFormWrapper = styled.div``

export const Container = styled.div``

export const Title = styled.div`
	color: ${colors.darkGrey};
	font-size: 14px;
	${fontType(0)}
`

export const LineSeparator = styled.div`
	width: 100%;
	background: ${colors.separatorColor};
	height: 1px;
	margin-top: 8px;
`

export const InputWrapper = styled.div`
	margin-top: 24px;
`

// '#858585'
export const reactSelectStyles: StylesConfig & {
	dropdownButton?: any
} = {
	container: (styles, state) => ({
		...styles,
		width: '100%',
		boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.08)'
	}),
	menu: (styles, state) => ({
		...styles
	}),
	option: (styles, state) => ({
		...styles,
		fontFamily: 'Regular',
		background: state.isSelected ? '#EEEEEE' : 'white',
		color: state.isSelected ? '#858585' : colors.darkGrey,
		cursor: 'pointer',
		transition: 'all 0.2s',
		':hover': {
			background: '#EEEEEE',
			color: '#858585'
		},

		input: {
			// display: 'none'
		}
	}),
	menuList: (styles, state) => ({
		...styles
	}),
	singleValue: (styles, state) => ({
		...styles
	}),
	placeholder: (styles, state) => ({
		...styles
	}),
	noOptionsMessage: (styles, state) => ({
		...styles,
		fontFamily: 'Regular'
	}),
	multiValue: (styles, state) => ({
		...styles
	}),
	multiValueLabel: (styles, state) => ({
		...styles
	}),
	multiValueRemove: (styles, state) => ({
		...styles
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: (styles, state) => ({
		...styles
	}),
	control: (styles, state) => ({
		...styles,
		fontFamily: 'Regular',
		outline: 'none'
		// ':hover': {
		// 	borderColor: '#EEEEEE',
		// 	boxShadow: 'none'
		// }
	}),
	valueContainer: (styles, state) => ({
		...styles
	}),
	input: (styles, state) => ({
		...styles
	}),
	dropdownButton: (styles: any, state: any) => ({
		...styles,
		width: '100%',
		height: 57,
		padding: 16,
		fontFamily: 'Regular'
	})
}
