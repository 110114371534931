import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps } from '@buildbox/components'
import {
	fetchReportsPage,
	getReportList,
	initializeReport
} from 'shared/services/report.service'
import { IReport, IReportsPage } from 'shared/interfaces/report'
import { ITableColumn, ITableRow, IViewProps } from './types'
import ReportsPage from './view'
import { ModalActionsEnum } from 'modules/ModalReport/types'
import { Image } from 'react-feather'
import { useDebounce } from 'use-debounce/lib'
import { TableImagePlaceholder } from './styles'
import { backScrollBody } from 'shared/util/scroll'
import { mapCategoryReport } from 'shared/util/translate'

const REPORTS_PER_PAGE = 10

function ReportsPageContainer(): JSX.Element {
	const [reportsPage, setReportsPage] = useState<IReportsPage>({
		pageIndex: 1,
		numberOfPages: 0,
		totalDocs: 0,
		pageContent: []
	})
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [showModalReport, setShowModalReport] = useState(false)
	const [modalAction, setModalAction] = useState<ModalActionsEnum>('CREATE')
	const [currentReport, setCurrentReport] = useState<IReport>(
		initializeReport()
	)
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)

	const [isDowloadCharge, setIsDowloadCharge] = useState(false)

	function handleReloadReportsPage() {
		getReportsPage(reportsPage.pageIndex, search)
	}

	async function handleDeleteReportsPage() {
		if (reportsPage.pageContent.length === 1 && reportsPage.pageIndex > 1) {
			await getReportsPage(reportsPage.pageIndex - 1, search)
			return
		}

		await getReportsPage(reportsPage.pageIndex, search)
	}

	async function getReportsPage(pageIndex: number, searchString = '') {
		try {
			setIsLoading(true)
			const pageData = await fetchReportsPage(
				pageIndex,
				REPORTS_PER_PAGE,
				searchString
			)
			setReportsPage(pageData)

			handleRefetch(pageIndex, pageData.pageContent)

			const rows = pageData.pageContent.map((report) => {
				return {
					image: renderReportImage(report.coverImage),
					title: renderTitle(report.title),
					subtitle: renderSubtitle(report.subtitle),
					category: renderCategory(report.category),
					editIcon: renderEditIcon(report),
					trashIcon: renderTrashIcon(report)
				}
			})

			setTableRows(rows)
		} catch (err) {
			handleRefetch(reportsPage.pageIndex, reportsPage.pageContent)
		} finally {
			setIsLoading(false)
		}
	}

	function renderCategory(category: string): JSX.Element {
		const words = mapCategoryReport(category)

		return <span>{words}</span>
	}

	function handleRefetch(pageIndex: number, arr: any[]) {
		const isEmpty = !!!arr.length
		const notFirstPage = pageIndex !== 1

		if (isEmpty && notFirstPage) {
			getReportsPage(1, search)
		}
	}

	function renderTitle(title: string): JSX.Element {
		return <p className='report-title'>{title}</p>
	}

	function renderSubtitle(subtitle: string): JSX.Element {
		return <p className='report-subtitle'>{subtitle}</p>
	}

	function renderReportImage(reportImage: string): JSX.Element {
		return (
			<div className='report-image-container'>
				{reportImage ? (
					<img className='report-image' src={reportImage} alt='' />
				) : (
					<TableImagePlaceholder>
						<Image className='placeholder-icon' />
					</TableImagePlaceholder>
				)}
			</div>
		)
	}

	function renderEditIcon(report: IReport): JSX.Element {
		return (
			<button
				className='icon'
				onClick={() => activateModalReport('EDIT', report)}
			>
				<img
					className='icon-image'
					src={require('../../assets/images/file-text.svg')}
					alt='Editar'
				/>
			</button>
		)
	}

	function renderTrashIcon(report: IReport): JSX.Element {
		return (
			<button
				className='icon'
				onClick={() => activateModalReport('DELETE', report)}
			>
				<img
					className='icon-image'
					src={require('../../assets/images/trash.svg')}
					alt='Excluir'
				/>
			</button>
		)
	}

	function handleCreateReport() {
		activateModalReport('CREATE', initializeReport())
	}

	function activateModalReport(action: ModalActionsEnum, report: IReport) {
		setCurrentReport(report)
		setModalAction(action)
		setShowModalReport(true)
	}

	function handleHideModalReport() {
		setShowModalReport(false)
		backScrollBody()
	}

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleSearchDebounce() {
		getReportsPage(1, searchDebounce)
	}

	async function handleFetchDowload() {
		setIsDowloadCharge(true)

		await getReportList()

		setIsDowloadCharge(false)
	}

	useEffect(handleSearchDebounce, [searchDebounce])

	const tableColumns: ITableColumn[] = [
		{ Header: '', accessor: 'image', disableSortBy: true },
		{ Header: 'Título', accessor: 'title', sortType: 'basic' },
		{ Header: 'Subtítulo', accessor: 'subtitle', sortType: 'basic' },
		{ Header: 'Categoria', accessor: 'category', sortType: 'basic' },
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		},
		{
			Header: '',
			accessor: 'trashIcon',
			disableSortBy: true
		}
	]

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => getReportsPage(pageIndex + 1, search),
		previousPage: (pageIndex) => getReportsPage(pageIndex + 1, search),
		gotoPage: (pageIndex) => getReportsPage(pageIndex + 1, search),
		pageCount: reportsPage.numberOfPages,
		pageIndex: reportsPage.pageIndex - 1,
		totalDocs: reportsPage.totalDocs
	}

	const viewProps: IViewProps = {
		tableColumns,
		tableRows,
		reportsPerPage: REPORTS_PER_PAGE,
		navProps,
		isLoading,
		showModalReport,
		modalAction,
		currentReport,
		handleCreateReport,
		handleHideModalReport,
		handleReloadReportsPage,
		handleDeleteReportsPage,
		handleSearch,
		search,
		handleFetchDowload,
		isDowloadCharge
	}
	return createElement(ReportsPage, viewProps)
}

export default ReportsPageContainer
