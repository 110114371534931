import { UtilValidators } from '@buildbox/utils'
import cogoToast from 'cogo-toast'
import { createElement, FormEvent, useEffect, useState } from 'react'
import { IUpdateUser, UserRolesEnum } from 'shared/interfaces/user'
import { fetchUpdateUser } from 'shared/services/user.service'
import { successMessages } from 'shared/util/Messages'
import cogoDefaultOptions from 'shared/util/toaster'
import { IModalUserEdit, IViewProps } from './types'
import UserEdit from './view'

const { checkEmail } = UtilValidators

function ModalEditUserContainer(props: IModalUserEdit): JSX.Element {
	const { data, hideModal, show, handleAfterClose } = props

	const [role, setRole] = useState<UserRolesEnum>('EDITOR')
	const [isLoading, setLoading] = useState(false)

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [position, setPosition] = useState('')

	const [isFormValid, setFormValid] = useState(false)
	const [filledData, setFilledData] = useState(false)

	const [checkIfDataHasBeenChanged, setCheckIfDataHasBeenChanged] = useState(
		false
	)
	const [oldInputValues, setOldInputValues] = useState<string[]>([])

	function wasThereAnUpdateInInputs() {
		const newInputValues = [firstName, lastName, email, position, role]
		const hasNewData = oldInputValues
			// checks whether it contains the same data
			.map((x) => newInputValues.includes(x))
			// make sure you have any different items
			.some((x) => x === false)

		return hasNewData
	}

	async function handleSubmit(e: FormEvent) {
		e.preventDefault()

		try {
			setLoading(true)
			const updateUser: IUpdateUser = {
				firstName,
				lastName,
				email,
				role,
				position
			}

			await fetchUpdateUser(data._id, updateUser)

			cogoToast.success(successMessages.userUpdated, cogoDefaultOptions)
			handleAfterClose()
			hideModal()
		} catch (erro) {
			//			cogoToast.error('Erro na alteração de cadastro.', cogoDefaultOptions)
		} finally {
			setLoading(false)
		}
	}

	function populateDataUser() {
		if (data.firstName) setFirstName(data.firstName)

		if (data.lastName) setLastName(data.lastName)

		if (data.email) setEmail(data.email)

		if (data.position) setPosition(data.position)

		if (data.role) setRole(data.role)

		setOldInputValues([
			data.firstName,
			data.lastName,
			data.email,
			data.position,
			data.role
		])
		setCheckIfDataHasBeenChanged(true)
	}

	function handleValidateForm() {
		let isValid = true

		if (checkEmpty(firstName)) isValid = false
		if (checkEmpty(lastName)) isValid = false
		if (!checkEmail(email)) isValid = false
		if (checkEmpty(position)) isValid = false

		setFormValid(isValid)
	}

	function checkEmpty(value: string) {
		return value === '' ? true : false
	}

	function handleRoleChange(switchState: Boolean) {
		const isAdmin = switchState ? 'ADMIN' : 'EDITOR'
		setRole(isAdmin)
	}

	useEffect(() => {
		if (!checkIfDataHasBeenChanged) return

		const hasUpdate = wasThereAnUpdateInInputs()
		setFilledData(hasUpdate)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstName, lastName, email, position, role])

	useEffect(() => {
		populateDataUser()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])
	useEffect(handleValidateForm, [firstName, lastName, email, position, role])

	const viewProps: IViewProps = {
		filledData,
		data,
		handleSubmit,
		hideModal,
		show,
		firstName,
		setFirstName,
		lastName,
		email,
		handleRoleChange,
		position,
		role,
		setEmail,
		setLastName,
		setPosition,
		setRole,
		isFormValid,
		isLoading,
		populateDataUser
	}

	return createElement(UserEdit, viewProps)
}

export default ModalEditUserContainer
