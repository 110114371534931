import { toRem, transition } from '../../styles/functions'
import styled, { css } from 'styled-components'
import { OmitStyledComponentsDefaultProps } from '@buildbox/components/lib/styles/types'

export const FloatLabelInputWrapper = styled.div<
    OmitStyledComponentsDefaultProps<'tertiaryColor'>
    >`
	${({ styles }) => css`
        position: relative;

        label.fli-label {
            color: ${styles.secondaryColor};
            font-size: ${toRem(styles.fontSize)};
            left: 16px;
            position: absolute;
            top: 10px;
            cursor: text;

            ${transition()}
            font-family:${styles.fontFamily}
        }

        div.fli-input {
            background: ${styles.backgroundColor};
            border-radius: ${styles.round ? '30px' : '4px'};
            border: solid 1px ${styles.secondaryColor};
            color: ${styles.secondaryColor};
            font-size: ${toRem(styles.fontSize)};
            padding: ${styles.padding};
            width: 100%;

            ${transition()}
            font-family:${styles.fontFamily}

            &:focus,
            &:valid {
                border: solid 1px ${styles.primaryColor};

                + label {
                    color: ${styles.primaryColor};
                    font-size: ${toRem(styles.fontSize - 4)};
                    left: 16px;
                    top: 12px;
                }
            }

            &[disabled] {
                border: dashed 1px ${styles.secondaryColor};

                + label {
                    color: ${styles.secondaryColor};
                    font-size: ${toRem(styles.fontSize - 4)};
                    left: 16px;
                    top: 12px;
                }
            }
        }
    `}
`
