import { Container } from 'shared/styles'
import { PageWrapper } from './styles'
import { routesData } from 'modules/Routes/data'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import Header from '../Header'
import React, { useState } from 'react'
import SideNav from 'shared/components/SideNav'
import { useHistory } from 'react-router-dom'

export default function Page({ children }: any): JSX.Element {
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useTypedSelector(['user'])
	const history = useHistory()

	function handleSetIsOpen() {
		setIsOpen(!isOpen)
	}
	const userType = user.role
	if(!userType) history.push('/login')
	
	const name = user.firstName

	const headerProps = {
		isOpen,
		handleSetIsOpen,
		name
	}

	const sideNavProps = {
		isOpen,
		handleSetIsOpen,
		routes: routesData,
		name
	}

	return (
		<PageWrapper>
			<Header {...headerProps} />
			<SideNav {...sideNavProps} />

			<div className='container-wrapper'>
				<Container>{children}</Container>
			</div>
		</PageWrapper>
	)
}
