import React from 'react'
import { ICheckBox } from './types'

import activeCheckBoxIcon from '../../../assets/images/check-fill.svg'

import inactiveCheckBoxIcon from '../../../assets/images/check-blank.svg'

import { CheckBoxButton, CheckBoxVariationWrapper } from './styles'

export default function CheckBox({
	checked,
	handleClick,
	id = 'input-checkbox'
}: ICheckBox) {
	return (
		<CheckBoxVariationWrapper>
			<CheckBoxButton htmlFor={id}>
				{checked ? (
					<img src={activeCheckBoxIcon} alt='plus' />
				) : (
					<img src={inactiveCheckBoxIcon} alt='plus' />
				)}
			</CheckBoxButton>

			<input
				type='checkbox'
				checked={checked}
				onClick={handleClick}
				id={id}
				accept=''
			/>
		</CheckBoxVariationWrapper>
	)
}
