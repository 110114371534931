import { IImageUpload } from 'shared/components/ImageUpload/types'
import { ILanguageOptionProps } from 'shared/components/SwitchLanguage/types'
import { Node } from 'slate'

export const TrendCategoryValues = [
	'POLITICS',
	'MACRO_STRATEGY',
	'SECTOR_MONITOR',
	'INTERNATIONAL_POLITICS'
]

export const INITIAL_BODY_VALUE: Node[] = [
	{
		type: 'paragraph',
		children: [{ text: '' }]
	}
]

export const IMAGE_INITIAL_VALUE: IImageUpload = { file: null, url: '' }

export const CATEGORY_OPTIONS = [
	{
		label: 'Banco',
		value: 'Banco'
	},

	{
		label: 'Asset',
		value: 'Asset'
	},

	{
		label: 'Fundo de Pensão',
		value: 'Fundo de Pensão'
	},

	{
		label: 'Seguradora',
		value: 'Seguradora'
	},

	{
		label: 'Corporate',
		value: 'Corporate'
	},

	{
		label: 'Compliance',
		value: 'Compliance'
	}
]

export function convertNumberToString(value: number): string {
	if (isNaN(value) || value === 0 || value === null) return ''
	return value.toString()
}

export function handleInitializeArrayOptions(
	ArrayLanguage: string[]
): ILanguageOptionProps {
	const portugueseOption = ArrayLanguage.find((element) => element === 'PT')
	const englishOption = ArrayLanguage.find((element) => element === 'EN')

	if (portugueseOption && englishOption) {
		return {
			portuguese: true,
			english: true
		}
	} else if (portugueseOption) {
		return {
			portuguese: true,
			english: false
		}
	} else if (englishOption) {
		return {
			portuguese: false,
			english: true
		}
	} else {
		return {
			portuguese: false,
			english: false
		}
	}
}

export function ArrayIsEmpty(array: any[]): boolean {
	return array.length === 0
}

export const INITAL_SPEECH_DATA = {
	candidates: [],
	types: [],
	categories: []
}

export const INITAL_SPEECH_VALUE = {
	candidate: null,
	type: [],
	theme: [],
	date: '',
	city: ''
}
