import { deleteEvent } from 'shared/services/event.service'
import { deleteReport } from 'shared/services/report.service'
import { deleteTrend } from 'shared/services/trend.service'
import { deleteUser } from 'shared/services/user.service'
import { successMessages } from 'shared/util/Messages'
import { TargetTypesEnum } from './types'

export const TITLE_ENUM: { [key in TargetTypesEnum]: string } = {
	EVENT: 'Excluir evento',
	REPORT: 'Excluir relatório',
	TREND: 'Excluir Hot Topic',
	USER: 'Excluir usuário'
}

export const MESSAGE_ENUM: { [key in TargetTypesEnum]: string } = {
	EVENT: 'Você deseja excluir o evento',
	REPORT: 'Você deseja excluir o relatório',
	TREND: 'Você deseja excluir o Hot Topic',
	USER: 'Você deseja excluir o usuário'
}

export const SUCCESS_MESSAGE: { [key in TargetTypesEnum]: string } = {
	EVENT: successMessages.eventDeleted,
	REPORT: successMessages.liveDeleted,
	TREND: successMessages.trendDeleted,
	USER: successMessages.userDeleted
}

// TODO: Type properly
export const FUNC_ENUM: {
	[key in TargetTypesEnum]: (id: string) => Promise<any>
} = {
	EVENT: deleteEvent,
	REPORT: deleteReport,
	TREND: deleteTrend,
	USER: deleteUser
}
