import { fontType, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const TierControllerWrapper = styled.div`
	background-color: ${colors.white};
	width: 270px;
	height: 120px;
	margin: 10px;
	padding: 32px 24px;
`

export const Title = styled.span`
	color: ${colors.darkGrey};
	font-size: ${toRem(20)};
	${fontType(-1)};
`
export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 4px;
`

export const SpanLabel = styled.span`
	${fontType(1)};
	font-size: 12px;
	color: #4f4f4d;
`
