import { SelectOption } from '@buildbox/components'
import { readTiers } from 'shared/services/tierTimer.service'

export function handleTierSelectOptions(): SelectOption[] {
	let options: SelectOption[] = []

	;(async () => {
		const tiers = await readTiers()

		tiers.forEach((tier) => {
			options.push({ label: tier.name, value: tier.name })
		})
	})()

	return options
}
