import * as serviceWorker from './serviceWorker'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
// import ReactGA from 'react-ga'
//import { APP_ENV } from 'shared/util/env'
// const { ANALYTICS_ID } = APP_ENV

// ReactGA.initialize(ANALYTICS_ID)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
