import { Node } from 'slate'

class UrlMatcherResult {
	type: 'url' | 'text' = 'text'
	value: string = ''
}

function urlMatcherInText(inputString: string): UrlMatcherResult[] {
	if (!inputString) return []

	const results: UrlMatcherResult[] = []

	function addText(text: string | null) {
		if (!text) return

		const result = new UrlMatcherResult()
		result.type = 'text'
		result.value = text
		results.push(result)
	}

	function addUrl(url: string | null) {
		if (!url) return

		const result = new UrlMatcherResult()
		result.type = 'url'
		result.value = url
		results.push(result)
	}

	const findUrlRegex = /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
	const cleanUrlRegex = /^(.+?)([.,?!'"]*)$/

	let match: RegExpExecArray | null
	let indexOfStartOfString = 0

	do {
		match = findUrlRegex.exec(inputString)

		if (match) {
			const text = inputString.substr(
				indexOfStartOfString,
				match.index - indexOfStartOfString
			)
			addText(text)

			var dirtyUrl = match[0]
			var urlDirtyMatch = cleanUrlRegex.exec(dirtyUrl)
			if (urlDirtyMatch) {
				addUrl(urlDirtyMatch[1])
				addText(urlDirtyMatch[2])
			}

			indexOfStartOfString = match.index + dirtyUrl.length
		}
	} while (match)

	const remainingText = inputString.substr(
		indexOfStartOfString,
		inputString.length - indexOfStartOfString
	)
	addText(remainingText)

	return results
}

export function automaticallyIdentifyLinks(content: Node[]): Node[] {
	// Each item in the Array contains a paragraph in Node format
	const modifiedContentValue: Node[] = content.map((node) => {
		// Each Node has a "type" and a "children" property
		const children = node.children as any[]
		// The children property is an array with the contents of the paragraph
		// Each item in children has a "text" property with the content and can contain other text formatting properties

		if (children.some((item) => item.hyperlink)) {
			const modifiedChildrenFinalValue = children.map((childrenItem) => ({
				...childrenItem,
				hyperlink: false
			}))
			return { ...node, children: modifiedChildrenFinalValue }
		} else {
			const modifiedChildren = children.map((childrenItem) => {
				// If childrenItem already has the property "hyperlink", just return it
				if (childrenItem.hyperlink) return childrenItem

				const resultParserUrlText: UrlMatcherResult[] = urlMatcherInText(
					childrenItem.text
				)

				let newChildrenItemValues: any[] = []
				if (resultParserUrlText && resultParserUrlText.length > 0) {
					resultParserUrlText.forEach((result) => {
						if (result.type === 'url') {
							if (
								[')', ']', '}'].includes(
									result.value[result.value.length - 1]
								) &&
								newChildrenItemValues.length &&
								newChildrenItemValues[newChildrenItemValues.length - 1] &&
								['(', '[', '{'].includes(
									newChildrenItemValues[newChildrenItemValues.length - 1].text[
									newChildrenItemValues[newChildrenItemValues.length - 1].text
										.length - 1
									]
								)
							) {
								newChildrenItemValues.push({
									...childrenItem,
									text: result.value.slice(0, result.value.length - 1),
									hyperlink: true
								})
								newChildrenItemValues.push({
									...childrenItem,
									text: result.value[result.value.length - 1]
								})
							} else {
								newChildrenItemValues.push({
									...childrenItem,
									text: result.value,
									hyperlink: true
								})
							}
						} else {
							newChildrenItemValues.push({
								...childrenItem,
								text: result.value
							})
						}
					})
				} else {
					newChildrenItemValues.push({
						...childrenItem,
						text: ' '
					})
				}

				return newChildrenItemValues
			})

			// Concatenating the new Childrens generated with the existing Childrens
			let modifiedChildrenFinalValue: any[] = []
			modifiedChildren.forEach((modifiedChildrenItem) => {
				if (Array.isArray(modifiedChildrenItem)) {
					modifiedChildrenItem.forEach((i) => {
						if (i.text) {
							modifiedChildrenFinalValue = modifiedChildrenFinalValue.concat(i)
						}
					})
				} else {
					modifiedChildrenFinalValue = modifiedChildrenFinalValue.concat(
						modifiedChildrenItem
					)
				}
			})

			return { ...node, children: modifiedChildrenFinalValue }
		}
	})

	return modifiedContentValue
}
