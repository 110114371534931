import { login } from 'shared/services/auth.service'
import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { Creators as TokenActions } from 'shared/store/ducks/token'
import { Creators as UserActions } from 'shared/store/ducks/user'
import { IViewProps, IUserLogin } from './types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Login from './view'
import routesEnum from '../Routes/enum'
import { logout } from 'shared/util/session'
import { UtilValidators } from '@buildbox/utils'

function LoginContainer(): JSX.Element {
	const dispatch = useDispatch()
	const history = useHistory()
	const { checkEmail, checkPassword } = UtilValidators
	const [userLogin, setUserLogin] = useState<IUserLogin>({
		email: '',
		password: ''
	})
	const [isFormValid, setIsFormValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	async function handleLogin() {
		try {
			setIsLoading(true)
			const response = await login(userLogin.email, userLogin.password)

			const { user, accessToken } = response

			dispatch(TokenActions.setToken({ accessToken: accessToken }))
			dispatch(UserActions.setUser(user))

			history.push(routesEnum.USERS_PAGE)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target

		setUserLogin({
			...userLogin,
			[id]: value.trim()
		})
	}

	useEffect(() => {
		const { email, password } = userLogin

		const isEmailValid = checkEmail(email)
		const isPasswordValid = checkPassword(password)

		const result = isEmailValid && isPasswordValid

		setIsFormValid(result)
	}, [checkEmail, checkPassword, userLogin])

	useEffect(() => {
		logout()
	}, [])

	const viewProps: IViewProps = {
		handleLogin,
		handleChange,
		userLogin,
		isFormValid,
		isLoading
	}

	return createElement(Login, viewProps)
}

export default LoginContainer
