import { fontType, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const ModalDeleteWrapper = styled.div`
	${fontType(0)};
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		.text {
			font-size: ${toRem(14)};
			color: #4f4f4d;
			${fontType(0)};
			text-align: center;
		}
		.target-name {
			font-size: ${toRem(14)};
			color: #4f4f4d;
			${fontType(0)};
			margin-bottom: 40px;
			text-align: center;
		}

		.loading-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.delete-button {
		}
	}
`
