import { fontType, toRem, transition } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const ImageUploadWrapper = styled.div`
	position: relative;

	.label {
		width: 100%;
		height: 168px;
		opacity: ${(props) => props.style?.opacity};
		display: flex;

		border-radius: 4px;
		overflow: hidden;
		.preview-image {
			width: 100%;
			height: 100%;
		}

		.placeholder {
			background: #ffc70929;
			justify-content: center;
			align-items: center;
			display: flex;
			width: 100%;
			height: 100%;
			border: 1px solid ${colors.secondary};

			.wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.icon {
					color: ${colors.grey};
					margin-bottom: 8px;
				}
				.text {
					${fontType(1)};
					text-align: center;
					color: ${colors.grey};
					text-transform: uppercase;
					font-size: ${toRem(10)};
					letter-spacing: ${toRem(0.4)};
				}
			}
		}

		.input {
			display: none;
		}
	}

	.trash-icon {
		height: 35px;
		width: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		visibility: hidden;
		opacity: 0;
		position: absolute;
		right: 16px;
		bottom: 16px;
		color: ${colors.white};
		background: #8c030a;
		padding: 8px;
		border-radius: 4px;
		cursor: pointer;
		${transition()};
		box-shadow: 0px 2px 3px #00000014;
	}

	&:hover {
		.trash-icon {
			opacity: 1;
			visibility: visible;
		}
	}
`
