import {
	Transforms,
	Editor,
	Node,
	Descendant,
	Element as SlateElement,
	Range
} from 'slate'
import { ReactEditor } from 'slate-react'
import { jsx } from 'slate-hyperscript'
import { uploadToS364 } from 'shared/services/awsService'

export const HOTKEYS: { [key: string]: string } = {
	'mod+b': 'bold', // alt + b
	'mod+i': 'italic', // alt + i
	'mod+u': 'underline', // alt + u
	"mod+'": 'code' // alt + '
}
export const LIST_TYPES = ['numbered-list', 'bulleted-list']
export const ALIGN_TYPES = [
	'align_center',
	'align_justify',
	'align_left',
	'align_right'
]

export const isBlockActive = (editor: ReactEditor, format: string) => {
	const [match]: any = Editor.nodes(editor, {
		match: (n) => n.type === format
	})

	return !!match
}

export const isMarkActive = (editor: ReactEditor, format: string) => {
	const marks = Editor.marks(editor)
	return marks ? marks[format] === true : false
}

export const toggleBlock = (editor: ReactEditor, format: string) => {
	const isActive = isBlockActive(editor, format)
	const isList = LIST_TYPES.includes(format)

	Transforms.unwrapNodes(editor, {
		match: (n) => LIST_TYPES.includes(n.type as string),
		split: true
	})

	const type = isActive ? 'paragraph' : isList ? 'list-item' : format

	Transforms.setNodes(editor, {
		type: type
	})

	if (!isActive && isList) {
		const block = { type: format, children: [] }
		Transforms.wrapNodes(editor, block)
	}
}

export function validURL(value: string) {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	) // fragment locator
	return !!pattern.test(value)
}

export const toggleMark = (editor: ReactEditor, format: string) => {
	const isActive = isMarkActive(editor, format)

	if (isActive) {
		Editor.removeMark(editor, format)
	} else {
		Editor.addMark(editor, format, true)
	}
}

function getMeta(url: string): Promise<{ width: number; height: number }> {
	return new Promise((res, rej) => {
		var img = new Image()
		img.src = url
		img.addEventListener('load', function () {
			if (this.naturalWidth && this.naturalHeight) {
				res({
					width: this.naturalWidth,
					height: this.naturalHeight
				})
			} else {
				rej(undefined)
			}
		})
	})
}

function isBase64(url: string): boolean {
	return url.search('data:image') >= 0
}

export const insertImage = (
	editor: ReactEditor,
	url: string | ArrayBuffer | null
) => {
	const urlString = String(url)
	;(async () => {
		let urlToSend = urlString
		const size = await getMeta(urlString)
		const isBase = isBase64(urlString)

		if (isBase) {
			const { Location } = await uploadToS364(urlString)
			urlToSend = Location
		}

		const text = { text: '' }
		const image = { type: 'image', url: urlToSend, children: [text], size }
		Transforms.insertNodes(editor, image)
		Transforms.insertNodes(editor, { type: 'paragraph', children: [text] })
	})()
}

export const insertHyperLink = (
	editor: ReactEditor,
	url: string | ArrayBuffer | null
) => {
	if (editor.selection) {
		wrapLink(editor, url)
	}
}

const wrapLink = (editor: ReactEditor, url: string | ArrayBuffer | null) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor)
	}

	const { selection } = editor
	const isCollapsed = selection && Range.isCollapsed(selection)
	const link: any = {
		type: 'hyperlink',
		url,
		children: isCollapsed ? [{ text: url }] : []
	}

	if (isCollapsed) {
		Transforms.insertNodes(editor, link)
	} else {
		Transforms.wrapNodes(editor, link, { split: true })
		Transforms.collapse(editor, { edge: 'end' })
	}
}

const unwrapLink = (editor: ReactEditor) => {
	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'hyperlink'
	})
}

export const isLinkActive = (editor: ReactEditor) => {
	const [link]: any = Editor.nodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'hyperlink'
	})
	return !!link
}

// Not Implement
export const serialize = (nodes: Node[]) => {
	return nodes.map((n) => Node.string(n)).join('\n')
}

// Not Implemented
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const deserialize = (el: any): Node | null | string | Descendant[] => {
	if (el.nodeType === 3) {
		return el.textContent
	} else if (el.nodeType !== 1) {
		return null
	}

	const children = Array.from(el.childNodes).map(deserialize)

	switch (el.nodeName) {
		case 'BODY':
			return jsx('fragment', {}, children)
		case 'BR':
			return '\n'
		case 'BLOCKQUOTE':
			return jsx('element', { type: 'quote' }, children)
		case 'P':
			return jsx('element', { type: 'paragraph' }, children)
		case 'A':
			return jsx(
				'element',
				{ type: 'link', url: el.getAttribute('href') },
				children
			)
		default:
			return el.textContent
	}
}

export const INITIAL_BODY_VALUE: Node[] = [
	{
		type: 'paragraph',
		children: [{ text: '' }]
	}
]

export function urlIsValid(url: string): boolean {
	if (!url) return false
	//eslint-disable-next-line
	const reURL = /((?:http(s)?:\/\/)?(?:www(\d)?\.)?([\w\-]+\.\w{2,})\/?((?:\?(?:[\w\-]+(?:=[\w\-]+)?)?(?:&[\w\-]+(?:=[\w\-]+)?)?))?(#(?:[^\s]+)?)?)/g
	return reURL.test(url)
}
