import React from 'react'
import { IViewProps } from './types'
import { ForgotPasswordWrapper } from './styles'

import { Button, FloatLabelInput, Loading } from '@buildbox/components'
import {
	primaryInputStyle,
	primaryButtonStyle,
	colors
} from 'shared/styles/theme'

import arrowLeftIcon from '../../assets/icons/arrow-left.svg'
import { Link } from 'react-router-dom'

function ForgotPassword(props: IViewProps): JSX.Element {
	const {
		recoverPassword, // FUNÇÃO SUBMIT
		setEmail,
		email,
		isFormValid,
		isLoading
	} = props

	return (
		<>
			<ForgotPasswordWrapper>
				<div className='background-wrapper'>
					<img
						className='logo-wrapper'
						src={require('assets/images/xplogo-login.svg')}
						alt='Logo XP Investimentos'
					/>
					<div className='inputs-wrapper'>
						<div className='header'>
							<Link to='/'>
								<img src={arrowLeftIcon} alt='Voltar' />
								<span>Voltar</span>
							</Link>
						</div>
						<div className='message'>
							<p>
								Digite seu e-mail abaixo e enviaremos um link para redefinir sua
								senha:
							</p>
						</div>
						<form
							onSubmit={(event) => {
								event.preventDefault()
								recoverPassword()
							}}
						>
							<div className='fields'>
								<FloatLabelInput
									className='input'
									label='E-mail'
									id='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									{...primaryInputStyle}
								/>

								{isLoading ? (
									<Loading type='Sentry' primaryColor={colors.secondary} />
								) : (
									<Button
										className='button'
										disabled={!isFormValid}
										type='submit'
										{...primaryButtonStyle}
									>
										{'ENVIAR'}
									</Button>
								)}
							</div>
						</form>
					</div>
				</div>
			</ForgotPasswordWrapper>
		</>
	)
}

export default ForgotPassword
