import { styler } from './styles'
import React from 'react'
import ReactSelect from 'react-select'
import { ISelectProps } from './types'
import { colors } from '../../styles/theme'

export default function Select<SelectOption>({
	primaryColor = colors.darkGrey,
	secondaryColor = colors.grey,
	fontSize = 16,
	tertiaryColor = colors.grey,
	fontFamily = 'sans-serif',
	round = false,
	backgroundColor = 'transparent',
	padding = [6, 6, 6, 6],
	...rest
}: ISelectProps<SelectOption>) {
	return (
		<>
			<ReactSelect
				styles={styler({
					styles: {
						primaryColor,
						secondaryColor,
						fontSize,
						fontFamily,
						round,
						backgroundColor,
						padding,
						tertiaryColor,
						isFilled: !!rest.value
					}
				})}
				{...rest}
			/>
		</>
	)
}
