import React, { useState } from 'react'
import { Button, Icon, Input, InputWrapper } from './styles'
import { ITierInputProps } from './types'
import EditInputIcon from 'assets/images/editInput.svg'
import approveButton from 'assets/images/aprv-btn.svg'

function TierInput(props: ITierInputProps) {
	const { updateTier } = props

	const [inputIsEnable, setInputIsEnable] = useState(true)

	function handleToggleInputIsEnable() {
		if (!inputIsEnable) {
			updateTier()
		}
		setInputIsEnable((state) => !state)
	}

	return (
		<InputWrapper>
			<Input disabled={inputIsEnable} {...props} />
			<Button onClick={handleToggleInputIsEnable}>
				<Icon
					src={inputIsEnable ? EditInputIcon : approveButton}
					alt='interact with the input'
				/>
			</Button>
		</InputWrapper>
	)
}

export default TierInput
