import cogoToast from 'cogo-toast'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { updateTier } from 'shared/services/tierTimer.service'
import handleError from 'shared/util/handleError'
import cogoDefaultOptions from 'shared/util/toaster'
import { Content, SpanLabel, TierControllerWrapper, Title } from './styles'
import TierInput from './TierInput'

import { ITierControllerProps } from './types'

function TierController(props: ITierControllerProps) {
	const { delayInMinutes, name } = props

	const [currentDelay, setCurrentDelay] = useState<string | number>(
		delayInMinutes
	)

	function handleDelay(event: ChangeEvent<HTMLInputElement>) {
		if (!!Number(event.target.value) || event.target.value === '0') {
			setCurrentDelay(Number(event.target.value))
		} else if (event.target.value.length === 0) {
			setCurrentDelay(0)
		}
	}

	function updateTierDelay() {
		const currentDelayNumber = parseInt([currentDelay][0] as string)

		if (currentDelayNumber !== delayInMinutes) {
			;(async () => {
				try {
					const tierUpdate = await updateTier(name, currentDelayNumber)
					cogoToast.success(
						`${tierUpdate.name} atualizado com sucesso!`,
						cogoDefaultOptions
					)
				} catch (error) {
					handleError(error)
				}
			})()
		}
	}

	function formatToDuration() {
		setCurrentDelay(currentDelay + ' min')
	}

	function convertDurationInNumber() {
		if (!currentDelay) return

		if (typeof currentDelay === 'string') {
			const durationNumber = parseInt([currentDelay][0])

			setCurrentDelay(durationNumber)
		}
	}

	useEffect(() => {
		setCurrentDelay(delayInMinutes + ' min')
	}, [delayInMinutes])

	return (
		<TierControllerWrapper>
			<Title>{name}</Title>
			<Content>
				<SpanLabel>Delay</SpanLabel>

				<TierInput
					value={currentDelay}
					onChange={handleDelay}
					updateTier={updateTierDelay}
					onFocus={convertDurationInNumber}
					onBlur={formatToDuration}
				/>
			</Content>
		</TierControllerWrapper>
	)
}

export default TierController
