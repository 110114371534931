import { FloatLabelInputWrapper } from './styles'
import { IFloatLabelTimeInputProps } from './types'

import clockIcon from '../../../assets/icons/clock.svg'
import React from 'react'

import NumberFormat from 'react-number-format'
import { FloatLabelInput } from '@buildbox/components'

function FloatLabelTimeInput({
	label,
	id,
	className,
	setTime,
	time,
	...rest
}: IFloatLabelTimeInputProps) {
	return (
		<FloatLabelInputWrapper className={className}>
			<NumberFormat
				className='input'
				id={id}
				required
				format='##:##'
				mask='_'
				value={time}
				onValueChange={setTime}
				label={label}
				{...rest}
				customInput={FloatLabelInput}
			/>

			<img src={clockIcon} alt='Clock icon' className='icon' />
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelTimeInput
