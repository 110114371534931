import { mediaQuery, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const ForgotPasswordWrapper = styled.div`
	display: flex;
	width: 100%;
	min-height: 100vh;

	.background-wrapper {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		background-image: url(${require('assets/images/login-background-cement-1920@2x.png')});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		${mediaQuery('md')(`
			flex-direction: column;
			align-items: center;
		`)};

		.logo-wrapper {
			margin: 100px 28px 100px 215px;
			max-width: ${toRem(283)};
			${mediaQuery('md')(`
				margin: 100px 0 0 0;
			`)};
		}

		.inputs-wrapper {
			flex-direction: column;
			justify-content: space-between;
			align-items: space-between;
			
			background-color: #fff;
			display: flex;
			width: 360px;
			height: 346px;
			align-items: center;
			padding: 40px 10px;
			margin: 100px 175px 100px 28px;
			border-radius: 4px;

			font-family: 'Roboto', sans-serif;

			${mediaQuery('md')(`
				margin: 0 0 240px 0;
			`)};

			.header {
				max-height: 20px;
				width: 100%;
				padding: 0 16px;
				flex: 1;

				a {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					color: ${({ theme }) => theme.colors.primary};
					cursor: pointer;
					text-decoration: none;

					img {
						width: 20px;
						height: 20px;
						margin-right: 8px;
					}
				}
			}

			.message {
				width: 100%;
				padding: 0 16px;
			}

			.fields {
				text-align: center;
				margin: 0px 16px;
				.input {
					margin-bottom: 12px;
					width: 312px;
					height: 58px;
				}

				.button {
					margin-top: 40px;
				}
			}
		}
	}
`
