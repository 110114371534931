import { IViewProps } from './types'
import { LoginWrapper } from './styles'
import React from 'react'

import { Button, FloatLabelInput, Loading } from '@buildbox/components'
import InputPassword from 'shared/components/InputPassword'
import {
	primaryInputStyle,
	primaryButtonStyle,
	colors
} from 'shared/styles/theme'
import ViewErrors from 'shared/components/ViewErrors'

function ChangePassword(props: IViewProps): JSX.Element {
	const {
		handlePassword,
		handlePasswordCheck,
		email,
		password,
		passwordCheck,
		handleSubmit,
		userName,
		isLoading,
		isFormValid,
		viewErrors,
		message
	} = props

	return (
		<>
			<LoginWrapper>
				<div className='background-wrapper'>
					<div className='message-wrapper'>
						<img
							className='logo-wrapper'
							src={require('assets/images/xp-investimentos-logo.svg')}
							alt='Logo XP Investimentos'
						/>
						<span>
							Olá, <span className='user-name-message'>{userName}.</span>
						</span>
						<br />
						<span>{message}</span>
					</div>

					<div className='inputs-wrapper'>
						<div className='header'></div>
						<div className='fields'>
							<FloatLabelInput
								className='input-email'
								label='E-mail'
								id='email'
								value={email}
								disabled={true}
								{...primaryInputStyle}
							/>
							<InputPassword
								className='input'
								label='Digite uma senha'
								id='password'
								value={password}
								onChange={handlePassword}
								{...primaryInputStyle}
							/>
							<InputPassword
								className='input'
								label='Repetir senha'
								id='passwordCheck'
								value={passwordCheck}
								onChange={handlePasswordCheck}
								{...primaryInputStyle}
							/>

							<ViewErrors errors={viewErrors} color='red' />

							{isLoading ? (
								<Loading type='Sentry' primaryColor={colors.secondary} />
							) : (
								<Button
									className='button'
									disabled={!isFormValid}
									onClick={handleSubmit}
									{...primaryButtonStyle}
								>
									{'CONTINUAR'}
								</Button>
							)}
						</div>
					</div>
				</div>
			</LoginWrapper>
		</>
	)
}

export default ChangePassword
