import { createGlobalStyle } from 'styled-components'

import RobotoBold from '../../assets/fonts/Roboto-Bold.woff'
import RobotoMedium from '../../assets/fonts/Roboto-Medium.woff'
import RobotoRegular from '../../assets/fonts/Roboto-Regular.woff'
import RobotoLight from '../../assets/fonts/Roboto-Light.woff'

import { fontType, mediaQuery } from './functions'

export const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: 'Light';
		src: url(${RobotoLight}) format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Regular';
		src: url(${RobotoRegular}) format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Medium';
		src: url(${RobotoMedium}) format('woff');
		font-weight: 500;
		font-style: normal;
	}

	@font-face {
		font-family: 'Bold';
		src: url(${RobotoBold}) format('woff');
		font-weight: 700;
		font-style: normal;
	}

	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	html {
		font-size: 16px;

		${mediaQuery('xs')(`
			font-size: 14px;
		`)}
	}

	img {
		vertical-align: middle;
		border-style: none;
		max-width: 100%;
		object-fit: cover;
		object-position: center;
	}

	#ct-container {
		${fontType(1)};
	}

	.modal-primary {
		${fontType(0)};
	}

`
