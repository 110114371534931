import React from 'react'
import { Column, Row } from 'shared/styles'
import { primaryInputStyle, selectStyle } from 'shared/styles/theme'
import DateInput from '../DateInput'
import FloatLabelInput from '../FloatLabelInput'
import Select from '../Select'
import {
	Container,
	InputWrapper,
	LineSeparator,
	reactSelectStyles,
	SectionSpeechFormWrapper,
	Title
} from './styles'
import { ISectionSpeechForm } from './types'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { IPlaceholderChange } from './types'

function SectionSpeechForm(props: ISectionSpeechForm) {
	const { listData, value, selectChange, textInputChange } = props

	function handlePlaceholder({
		placeholderButtonLabel,
		value
	}: IPlaceholderChange) {
		if (value.length === 1) {
			return value[0].label
		}

		if (value.length > 1) {
			return `${value.length} ${placeholderButtonLabel}s`
		}

		return `Selecione um ${placeholderButtonLabel}`
	}

	console.log({ type: value.type, typeList: listData.types })

	return (
		<SectionSpeechFormWrapper>
			<Container>
				<Title>Sessão de Discursos</Title>

				<LineSeparator />

				<InputWrapper>
					<Row>
						<Column sm='12' lg='6'>
							<Select
								id='category'
								label='candidadato'
								options={listData.candidates}
								placeholder={'PRÉ-CANDIDATO'}
								value={value.candidate.value ? value.candidate : null}
								onChange={(data) => selectChange(data, 'candidate')}
								{...selectStyle}
							/>
						</Column>
						<Column sm='12' lg='3'>
							<DateInput
								id='title'
								label='Data'
								disabled={false}
								date={value.date || ''}
								setDate={(value) => {
									textInputChange(value.formattedValue, 'date')
								}}
								className='input-date'
								{...primaryInputStyle}
							/>
						</Column>
						<Column sm='12' lg='3'>
							<FloatLabelInput
								id='city'
								label='CIDADE'
								disabled={false}
								value={value.city || ''}
								onChange={(event) =>
									textInputChange(event.target.value, 'city')
								}
								{...primaryInputStyle}
							/>
						</Column>
					</Row>
					<Row>
						<Column lg='6'>
							<ReactMultiSelectCheckboxes
								id='category'
								placeholder='Temas'
								value={value.theme}
								options={listData.categories}
								onChange={(data: any) => selectChange(data, 'theme')}
								className='select'
								isMulti
								noOptionsMessage={() => 'Nenhum tema para mostrar.'}
								placeholderButtonLabel='Tema'
								getDropdownButtonLabel={handlePlaceholder}
								{...selectStyle}
								styles={reactSelectStyles}
							/>
						</Column>
						<Column lg='6'>
							<Select
								id='category'
								label='Categoria'
								options={listData.types}
								placeholder={'TIPO'}
								value={value.type.value ? value.type : null}
								onChange={(data) => selectChange(data, 'type')}
								{...selectStyle}
							/>
						</Column>
					</Row>
				</InputWrapper>
			</Container>
		</SectionSpeechFormWrapper>
	)
}

export default SectionSpeechForm
