import {
	Editable,
	withReact,
	Slate,
	RenderLeafProps,
	RenderElementProps,
	ReactEditor
} from 'slate-react'
import { createEditor, Node } from 'slate'
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey'
import React, { useCallback, useMemo } from 'react'

import { Button } from './Button'
import isUrl from 'is-url'
import imageExtensions from 'image-extensions'

import { Toolbar } from './Toolbar'
import {
	HOTKEYS,
	INITIAL_BODY_VALUE,
	insertImage,
	serialize,
	toggleMark
} from './util'
import {
	EditorTextBoxWrapper,
	Bold,
	Code,
	Italic,
	Underline,
	BlockQuote,
	UnorderedList,
	OrderedList,
	ListItem,
	H1,
	H2,
	Paragraph,
	Divider,
	AlignCenterBlock,
	AlignJustifyBlock,
	AlignRightBlock,
	AlignLeftBlock,
	Hyperlink
} from './styles'
import { IEditorTextBoxProps } from './types'
import ImageElement from './ImageElement'
import { automaticallyIdentifyLinks } from './magicButtonFunction'
import LinkButton from './LinkButton'

function EditorTextBox(props: IEditorTextBoxProps) {
	const {
		fontSize = 16,
		fontFamily = 'sans-serif',
		returnValueStr,
		returnValue,
		returnValueSerialized,
		disabled,
		value
	} = props

	// const [shouldRender, setShouldRender] = useState(false)

	// const isEmpty =
	// 	!!!serialize(value).length &&
	// 	!!value?.length &&
	// 	value[0].type === 'paragraph' &&
	// 	!!(value[0].children as any[]).length &&
	// 	!!!(value[0].children as any[])[0].text

	const isEmpty = !!!serialize(value).length

	const mergedStyles = {
		fontSize,
		fontFamily
	}

	const renderElement = useCallback(
		(props: RenderElementProps) => <Element {...props} />,
		[]
	)
	const renderLeaf = useCallback(
		(props: RenderLeafProps) => <Leaf {...props} />,
		[]
	)

	const editor = useMemo(
		() => withImages(withHistory(withReact(createEditor()))),
		[]
	)

	function handleChange(value: Node[]) {
		returnValue && returnValue(value)
		returnValueStr && returnValueStr(JSON.stringify(value))
		returnValueSerialized && returnValueSerialized(serialize(value))
	}

	function handleParseBodyIdentifyLink() {
		const formatedBody = automaticallyIdentifyLinks(value)

		// Code needed to avoid bugs
		const point = { path: [0, 0], offset: 0 }
		editor.selection = { anchor: point, focus: point }

		returnValue && returnValue(formatedBody)
	}

	return (
		<EditorTextBoxWrapper styles={mergedStyles}>
			<Slate
				editor={editor}
				value={isEmpty ? INITIAL_BODY_VALUE : value}
				onChange={handleChange}
			>
				<Toolbar>
					<div className='toolbar-group'>
						<Button type='mark' format='bold' icon='format_bold' />
						<Button type='mark' format='italic' icon='format_italic' />
						<Button type='mark' format='underline' icon='format_underlined' />
						{/* <Button type='mark' format='code' icon='code' /> */}
						<Button type='block' format='heading-one' icon='looks_one' />
						<Button type='block' format='heading-two' icon='looks_two' />
						{/* <Button type='block' format='block-quote' icon='format_quote' /> */}
						{/* <Button
						type='block'
						format='numbered-list'
						icon='format_list_numbered'
					/> */}
						<Button
							type='block'
							format='bulleted-list'
							icon='format_list_bulleted'
						/>

						<Divider />

						<Button type='image' icon='insert_photo' />

						<Divider />

						<Button
							type='block'
							format='align_center'
							icon='format_align_center'
						/>
						<Button
							type='block'
							format='align_justify'
							icon='format_align_justify'
						/>
					</div>
					<div className='toolbar-group'>
						<Button type='block' format='align_left' icon='format_align_left' />
						<Button
							type='block'
							format='align_right'
							icon='format_align_right'
						/>

						<Divider />

						<Button type='mark' format='hyperlink' icon='link' />
						<LinkButton onClick={handleParseBodyIdentifyLink} />
					</div>
				</Toolbar>
				<Editable
					readOnly={disabled}
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					placeholder='Digite um texto...'
					spellCheck
					autoFocus
					onKeyDown={(event) => {
						for (const hotkey in HOTKEYS) {
							if (isHotkey(hotkey, event as any)) {
								event.preventDefault()
								const mark = HOTKEYS[hotkey]
								toggleMark(editor, mark)
							}
						}
					}}
				/>
			</Slate>
		</EditorTextBoxWrapper>
	)
}

const Element = ({
	attributes,
	children,
	element,
	...rest
}: RenderElementProps) => {
	const type = element.type
	const url = !!element.url
	if (type === 'block-quote') {
		return <BlockQuote {...attributes}>{children}</BlockQuote>
	} else if (type === 'bulleted-list') {
		return <UnorderedList {...attributes}>{children}</UnorderedList>
	} else if (type === 'heading-one') {
		return <H1 {...attributes}>{children}</H1>
	} else if (type === 'heading-two') {
		return <H2 {...attributes}>{children}</H2>
	} else if (type === 'list-item') {
		return <ListItem {...attributes}>{children}</ListItem>
	} else if (type === 'numbered-list') {
		return <OrderedList {...attributes}>{children}</OrderedList>
	} else if (url && type === 'image') {
		return <ImageElement {...{ attributes, element }}>{children}</ImageElement>
	} else if (type === 'align_center') {
		return <AlignCenterBlock {...attributes}>{children}</AlignCenterBlock>
	} else if (type === 'align_justify') {
		return <AlignJustifyBlock {...attributes}>{children}</AlignJustifyBlock>
	} else if (type === 'align_right') {
		return <AlignRightBlock {...attributes}>{children}</AlignRightBlock>
	} else if (type === 'align_left') {
		return <AlignLeftBlock {...attributes}>{children}</AlignLeftBlock>
	} else {
		return <Paragraph {...attributes}>{children}</Paragraph>
	}
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
	if (leaf.bold) {
		children = <Bold>{children}</Bold>
	}

	if (leaf.code) {
		children = <Code>{children}</Code>
	}

	if (leaf.italic) {
		children = <Italic>{children}</Italic>
	}

	if (leaf.underline) {
		children = <Underline>{children}</Underline>
	}

	if (leaf.hyperlink) {
		children = <Hyperlink {...attributes}>{children}</Hyperlink>
	}

	return <span {...attributes}>{children}</span>
}

const withImages = (editor: ReactEditor) => {
	const { insertData, isVoid } = editor

	editor.isVoid = (element) => {
		return element.type === 'image' ? true : isVoid(element)
	}

	editor.insertData = (data: any) => {
		const text = data.getData('text/plain')
		const { files } = data

		if (files && files.length > 0) {
			for (const file of files) {
				const reader = new FileReader()
				const [mime] = file.type.split('/')

				if (mime === 'image') {
					reader.addEventListener('load', () => {
						const url = reader.result
						insertImage(editor, url)
					})

					reader.readAsDataURL(file)
				}
			}
		} else if (isImageUrl(text)) {
			insertImage(editor, text)
		} else {
			insertData(data)
		}
	}

	return editor
}

const isImageUrl = (url: string) => {
	if (!url) return false
	if (!isUrl(url)) return false
	const ext = new URL(url).pathname.split('.').pop()
	return imageExtensions.includes(ext || '')
}

export default EditorTextBox

// Transforms.select(editor, [0])
// Transforms.select(editor, {
// 	anchor: { path: [0, 0], offset: 0 },
// 	focus: { path: [0, 0], offset: 0 }
// })

// useEffect(() => {
// 	console.log({ isActive, isEmpty })
// 	if (!isActive) {
// 		setShouldRender(false)
// 	} else if (!isActive && isEmpty) {
// 		Transforms.select(editor, {
// 			anchor: { path: [0, 0], offset: 0 },
// 			focus: { path: [0, 0], offset: 0 }
// 		})
// 	} else if (isActive && isEmpty) {
// 		Transforms.select(editor, {
// 			anchor: { path: [0, 0], offset: 0 },
// 			focus: { path: [0, 0], offset: 0 }
// 		})
// 		setShouldRender(true)
// 	} else {
// 		setShouldRender(true)
// 	}
// }, [isActive, isEmpty])

// if (!shouldRender) return null

// if (leaf.align_center) {
// 	children = <AlignCenterBlock>{children}</AlignCenterBlock>
// }
// if (leaf.align_justify) {
// 	children = <AlignJustifyBlock>{children}</AlignJustifyBlock>
// }
// if (leaf.align_right) {
// 	children = <AlignRightBlock>{children}</AlignRightBlock>
// }
// if (leaf.align_left) {
// 	children = <AlignLeftBlock>{children}</AlignLeftBlock>
// }
