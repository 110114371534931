import { colors } from '../../styles/theme'
import { FloatLabelInputWrapper } from './styles'

import React, { useRef } from 'react'
import { paddingToString } from 'shared/util/Lib.inside.util'

function ResponsiveInput({
	label,
	id,
	className,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	onChange,
	value,
	...rest
}: any) {
	const inputRef = useRef<any>(null)

	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString
	}

	function onChangeValue() {
		onChange(inputRef.current.innerText)
	}

	return (
		<FloatLabelInputWrapper styles={mergedStyles} className={className}>
			<div
				className='fli-input'
				id={id}
				contentEditable
				onBlur={onChangeValue}
				suppressContentEditableWarning
				ref={inputRef}
			>
				{value}
			</div>
			<label className='fli-label' htmlFor={id}>
				{label}
			</label>
		</FloatLabelInputWrapper>
	)
}

export default ResponsiveInput
