import { createElement, useEffect, useState } from 'react'
import { ITierTimerProps } from 'shared/interfaces/tierTimer'
import { readTiers } from 'shared/services/tierTimer.service'
import { IViewProps } from './types'
import TierTimer from './view'

function TierTimerContainer(): JSX.Element {
	const [tiersArray, setTiersArray] = useState<ITierTimerProps[]>([])
	const [isLoading, setIsLoading] = useState(true)

	function handleFetch() {
		;(async () => {
			try {
				const tiers = await readTiers()

				setTiersArray(tiers)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	useEffect(handleFetch, [])

	const viewProps: IViewProps = {
		tiersArray,
		isLoading,
		handleFetch
	}

	return createElement(TierTimer, viewProps)
}

export default TierTimerContainer
