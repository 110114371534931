import React, { useState, ChangeEvent, useEffect, FormEvent } from 'react'
import { UtilValidators } from '@buildbox/utils'
import { Loading, Modal, FloatLabelInput, Button } from '@buildbox/components'
import {
	colors,
	primaryInputStyle,
	primaryButtonStyle
} from 'shared/styles/theme'
import cogoDefaultOptions from 'shared/util/toaster'
import cogoToast from 'cogo-toast'
import { IModalNewUser, IModalNewUserProps } from './types'
import { ModalNewUserWrapper, LoadingContent, ModalSwitch } from './styles'
import Switch from 'shared/components/Switch'
import { createUser } from 'shared/services/user.service'
import { successMessages } from 'shared/util/Messages'

const { checkEmail } = UtilValidators

function ModalNewUser(props: IModalNewUserProps) {
	const { hideModal, show, handleAfterClose } = props
	const [isLoading, setLoading] = useState(false)

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [position, setPosition] = useState('')
	const [role, setRole] = useState('EDITOR')

	const [isFormValid, setFormValid] = useState(false)

	useEffect(() => {
		clearAll()
	}, [])

	async function handleSubmit(event: FormEvent<HTMLButtonElement>) {
		event.preventDefault()

		const user: IModalNewUser = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			position: position,
			role: role === 'ADMIN' ? 'ADMIN' : 'EDITOR',
			status: 'PENDING'
		}

		try {
			setLoading(true)
			await createUser(user)
			cogoToast.success(successMessages.userCreated, cogoDefaultOptions)
			handleAfterClose()
			hideModal()
			clearAll()
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function clearAll() {
		setFirstName('')
		setLastName('')
		setEmail('')
		setPosition('')
		setRole('EDITOR')
	}

	function handleFirstNameChange(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setFirstName(value)
	}

	function handleLastNameChange(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setLastName(value)
	}

	function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setEmail(value)
	}

	function handlePositionChange(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setPosition(value)
	}

	function handleRoleChange(switchState: Boolean) {
		const isAdmin = switchState ? 'ADMIN' : 'EDITOR'
		setRole(isAdmin)
	}

	useEffect(handleValidateForm, [firstName, lastName, email, position, role])
	function handleValidateForm() {
		let isValid = true

		if (checkEmpty(firstName)) isValid = false
		if (checkEmpty(lastName)) isValid = false
		if (!checkEmail(email)) isValid = false
		if (checkEmpty(position)) isValid = false

		setFormValid(isValid)
	}

	function checkEmpty(value: string) {
		return value === '' ? true : false
	}

	return (
		<>
			<Modal
				className='modal-primary'
				title='Novo Usuário'
				size={372}
				isActive={show}
				onClose={() => {
					hideModal()
					clearAll()
				}}
				scrollRef='scroll-ref'
			>
				<ModalNewUserWrapper>
					<FloatLabelInput
						className='modal-input'
						id='first-name'
						label='Nome'
						value={firstName}
						onChange={handleFirstNameChange}
						maxLength={40}
						{...primaryInputStyle}
					/>

					<FloatLabelInput
						className='modal-input'
						id='last-name'
						label='Sobrenome'
						value={lastName}
						onChange={handleLastNameChange}
						maxLength={40}
						{...primaryInputStyle}
					/>

					<FloatLabelInput
						className='modal-input'
						id='email'
						label='E-mail'
						value={email}
						onChange={handleEmailChange}
						maxLength={80}
						{...primaryInputStyle}
					/>

					<FloatLabelInput
						className='modal-input'
						id='position'
						label='Cargo'
						value={position}
						onChange={handlePositionChange}
						maxLength={32}
						{...primaryInputStyle}
					/>

					<ModalSwitch>
						<Switch
							label='Administrador?'
							isChecked={role === 'ADMIN' ? true : false}
							onChange={handleRoleChange}
						/>
					</ModalSwitch>

					{isLoading ? (
						<LoadingContent>
							<Loading type='Sentry' primaryColor={colors.secondary} />
						</LoadingContent>
					) : (
						<Button
							className='modal-button'
							disabled={!isFormValid}
							onClick={handleSubmit}
							{...primaryButtonStyle}
						>
							{'CADASTRAR'}
						</Button>
					)}
				</ModalNewUserWrapper>
			</Modal>
		</>
	)
}

export default ModalNewUser
