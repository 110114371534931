import { toRem, fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const ForgotPasswordWrapper = styled.div`
	.forgot-password {
		display: inline-block;
		font-size: ${toRem(10)};
		color: ${({ theme }) => theme.colors.primary};
		${fontType(2)};
		text-transform: uppercase;
		margin-top: 24px;
		margin-bottom: 16px;

		.click-here {
			display: inline-block;
			color: ${({ theme }) => theme.colors.primary};
			cursor: pointer;
			text-decoration: underline;
			margin-left: 4px;
		}
	}
`
