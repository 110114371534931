import React from 'react'
import { HandleErrorWrapper, Messages } from './styles'
import { IHandleErrorProps } from './types'

export default function HandleError({
	critic
}: IHandleErrorProps): JSX.Element {
	return (
		<HandleErrorWrapper>
			<br />
			<p>{'● Linha: ' + critic.line}</p>
			{critic.messages.map((messages) => (
				<Messages>{messages}</Messages>
			))}
		</HandleErrorWrapper>
	)
}
