import { Button, Loading, Table } from '@buildbox/components'
import { IViewProps } from './types'
import {
	Header,
	InputSearchWrapper,
	LoadingContent,
	UsersListWrapper
} from './styles'
import { tableStyle, primaryButtonStyle, colors } from 'shared/styles/theme'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import ModalDelete from 'shared/components/ModalDelete'
import ModalEditUser from 'modules/ModalEditUser'
import ModalNewUser from 'modules/ModalNewUser'
import PageTitle from 'shared/components/PageTitle'
import React from 'react'
import InputSearch from 'shared/components/InputSearch'
import { backScrollBody } from 'shared/util/scroll'

function UsersPage(props: IViewProps): JSX.Element {
	const {
		tableRows,
		tableColumns,
		isLoading,
		navProps,
		usersPerPage,
		userEdit,
		showModalNewUser,
		showModalEditUser,
		handleModalNewUser,
		handleModalEditUser,
		updateUserPageAfterModalClosed,
		handleDeleteUserOnClose,
		targetUserDelete,
		handleSearch,
		search,
		handleDeleteUserEnd
	} = props

	return (
		<>
			<UsersListWrapper>
				<Header>
					<PageTitle>Usuários</PageTitle>

					<div>
						<InputSearchWrapper>
							<InputSearch
								id='search'
								label='Buscar usuário'
								onChange={(e) => handleSearch(e.target.value)}
								value={search}
							/>
						</InputSearchWrapper>
						<Button
							id='button'
							onClick={handleModalNewUser}
							{...primaryButtonStyle}
						>
							{'NOVO USUÁRIO'}
						</Button>
					</div>
				</Header>

				{isLoading ? (
					<LoadingContent>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</LoadingContent>
				) : (
					<div className='table-container'>
						{tableRows.length ? (
							<Table
								// Workaround while we need to fix columns type on lib
								columns={tableColumns as any}
								data={tableRows}
								navProps={navProps}
								pageSize={usersPerPage}
								{...tableStyle}
								padding={[26, 16, 26, 16]}
							/>
						) : (
							<DefaultPagePlaceholder text='No momento, não há Usuários para serem exibidos.' />
						)}
					</div>
				)}

				<ModalNewUser
					handleAfterClose={updateUserPageAfterModalClosed}
					show={showModalNewUser}
					hideModal={handleModalNewUser}
				/>

				<ModalEditUser
					show={showModalEditUser}
					handleAfterClose={updateUserPageAfterModalClosed}
					hideModal={() => {
						handleModalEditUser(userEdit)
						backScrollBody()
					}}
					data={userEdit}
				/>

				<ModalDelete
					onClose={handleDeleteUserOnClose}
					onEnd={handleDeleteUserEnd}
					target={targetUserDelete}
					type='USER'
				/>
			</UsersListWrapper>
		</>
	)
}

export default UsersPage
