import { ICustomersPage } from 'shared/interfaces/customer'
import { IEventForm } from './types'

export const ITEMS_PER_PAGE = 10

export const TableColumns: Object[] = [
	{ Header: 'Nome', accessor: 'firstName', sortType: 'basic' },
	{ Header: 'Sobrenome', accessor: 'lastName', sortType: 'basic' },
	{ Header: 'E-mail', accessor: 'email', sortType: 'basic' },
	{ Header: 'Empresa', accessor: 'company', sortType: 'basic' }
]

export const DEFAULT_CUSTOMER_PAGE: ICustomersPage = {
	numberOfPages: 10,
	pageContent: [],
	pageIndex: 1,
	totalDocs: 0
}

export const EVENT_FORM_PLACEHOLDER: IEventForm = {
	title: '',
	date: '',
	time: '',
	notificate: false,
	emailNotificate: false,
	subtitle: '',
	videoLink: ''
}
