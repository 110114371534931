import React from 'react'
import {
	PdfSectionDetailAndRemove,
	PdfSectionLabel,
	PdfSectionLabelText,
	PdfSectionSizeText,
	PdfSectionWrapper
} from './styles'
import { IPdfSectionProps } from './types'
import svgFile from '../../../../assets/images/file.svg'
import svgTrash from '../../../../assets/images/trash.svg'
import formatBytes from 'shared/util/bytes'

export default function PdfSection({
	title,
	size,
	removeFunction,
	genericId
}: IPdfSectionProps) {
	return (
		<PdfSectionWrapper>
			<PdfSectionLabel>
				<img src={svgFile} alt='' />
				<PdfSectionLabelText>{title}</PdfSectionLabelText>
			</PdfSectionLabel>
			<PdfSectionDetailAndRemove>
				<PdfSectionSizeText>{formatBytes(size)}</PdfSectionSizeText>
				<label onClick={() => removeFunction(genericId)}>
					<img src={svgTrash} alt='' />
				</label>
			</PdfSectionDetailAndRemove>
		</PdfSectionWrapper>
	)
}
