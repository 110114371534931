import React, { ChangeEvent, useEffect, useState } from 'react'
import {
	PdfAddFilesButton,
	PdfFilesContainer,
	PdfFilesLabel,
	PdfFilesList,
	PdfFilesNumber,
	PdfFilesWrapper
} from './styles'
import { IPdfFilesProps } from './types'
import svgPlus from '../../../assets/images/upload-button.svg'
import svgFolder from '../../../assets/images/folder.svg'
import PdfSection from './Section'
import { IAttachment } from 'shared/interfaces/attachment'

export default function PdfFiles({ returnPdf, initialPdfs }: IPdfFilesProps) {
	const [pdfs, setPdfsValue] = useState<IAttachment[]>([])

	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedPdf = event.target?.files?.item(0)
		if (!selectedPdf) return

		const result: IAttachment = {
			file: selectedPdf,
			name: selectedPdf.name,
			size: selectedPdf.size
		}

		return setPdfsValue((prevState) => [...prevState, result])
	}

	function removeFunction(url: string) {
		const pdfRemoved = pdfs.filter((pdf) => pdf.name + pdf.size !== url)
		setPdfsValue(pdfRemoved)
	}

	useEffect(() => {
		returnPdf(pdfs)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pdfs])

	useEffect(() => {
		setPdfsValue(initialPdfs)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<PdfFilesContainer>
			<PdfFilesWrapper>
				<PdfFilesList>
					<PdfFilesLabel>
						<img src={svgFolder} alt='folder' />
						{'ARQUIVOS'}
					</PdfFilesLabel>
					<PdfFilesNumber
						className={pdfs && pdfs.length === 0 ? 'Inactive' : 'Active'}
					>
						{pdfs ? pdfs.length : 0}
					</PdfFilesNumber>
				</PdfFilesList>
				<PdfAddFilesButton htmlFor='input'>
					<img src={svgPlus} alt='plus' />
				</PdfAddFilesButton>
			</PdfFilesWrapper>
			{pdfs
				? pdfs.map((item) => (
						<PdfSection
							key={item.name + Math.random()}
							title={item.name}
							size={item.size}
							genericId={item.name + item.size}
							removeFunction={removeFunction}
						/>
				  ))
				: null}
			<input
				className='input'
				type='file'
				accept='.pdf'
				id='input'
				onChange={handleInputChange}
			/>
		</PdfFilesContainer>
	)
}
