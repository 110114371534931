import {
	IDeleteUser,
	IUpdateUser,
	IUser,
	IUsersPage
} from 'shared/interfaces/user'
import { INewUserPayload } from 'shared/interfaces/user.service'
import axiosInstance from './axiosInstance'

export async function createUser(user: INewUserPayload): Promise<IUser> {
	const response = await axiosInstance.post('/user/', {
		user: user
	})

	return response.data.content
}

export async function fetchUsersPage(
	pageIndex: number,
	itemsPerPage: number,
	searchString = ''
): Promise<IUsersPage> {
	const response = await axiosInstance.post('/user/get-page', {
		pageIndex: pageIndex,
		maxItemsInPage: itemsPerPage,
		searchString
	})

	// FIXME: change to content, when WS is corrected
	return response.data.content
}

export async function fetchUpdateUser(
	userId: string,
	user: IUpdateUser
): Promise<IUpdateUser> {
	const response = await axiosInstance.patch('/user/' + userId, user)

	return response.data.content
}

export async function deleteUser(_id: string): Promise<IDeleteUser> {
	const response = await axiosInstance.delete('/user/', { data: { _id } })

	return response.data.content
}

export async function fetchAuthors(): Promise<IUser[]> {
	const response = await axiosInstance.get('/user/author')

	return response.data.content
}

export function fullName(user: IUser) {
	return `${user.firstName} ${user.lastName}`
}
