import styled from 'styled-components'
import { toRem } from '../../shared/styles/functions'

export const UsersListWrapper = styled.div`
	button {
		cursor: pointer;
	}

	.table-container {
		margin-top: ${toRem(50)};

		.icon {
			border: none;
			display: flex;
			margin: auto;
			background: transparent;
			.img {
			}
		}
	}
`

export const LoadingContent = styled.section`
	display: flex;
	margin-top: ${toRem(50)};
	justify-content: center;
`
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	div {
		display: flex;
		align-items: center;
		#search {
			min-width: ${toRem(250)};
		}
		#button {
			height: ${toRem(45)};
		}
	}

	@media (max-width: 700px) {
		& {
			flex-direction: column;

			> h1 {
				align-self: center;
				margin-bottom: ${toRem(20)};
			}
			div {
				flex-direction: column;

				#button {
					margin-top: ${toRem(20)};
				}
			}
		}
	}
`
export const InputSearchWrapper = styled.div`
	margin-right: ${toRem(20)};
`
