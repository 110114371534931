import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const TierTimerWrapper = styled.div`
	${fontType(0)}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const LoadingContent = styled.section`
	display: flex;
	justify-content: center;
`

export const ContentContainer = styled.div`
	margin-top: 32px;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
`
