import { IRouteData } from './types'

import routesEnum from './enum'
import Login from 'modules/Login'
import ChangePassword from 'modules/ChangePassword'
import ForgotPassword from 'modules/ForgotPassword'
import UsersList from 'modules/UsersPage'
import TrendList from 'modules/TrendsPage'
import CustomerList from 'modules/CustomersPage'
import ReportsPage from 'modules/ReportsPage'
import EventsPage from 'modules/EventsPage'
import DashboardPage from 'modules/DashboardPage'
import TierTimer from 'modules/TierTimer'

export const routesData: IRouteData[] = [
	{
		component: DashboardPage,
		key: routesEnum.DASHBOARD_PAGE,
		name: 'Dashboard',
		path: routesEnum.DASHBOARD_PAGE,
		accessTypes: ['ADMIN']
	},
	{
		component: Login,
		key: routesEnum.LOGIN,
		name: 'Login',
		path: routesEnum.LOGIN,
		noWrapper: true
	},
	{
		component: ChangePassword,
		key: routesEnum.CHANGE_PASSWORD,
		name: 'Nova Senha',
		path: routesEnum.CHANGE_PASSWORD,
		noWrapper: true
	},
	{
		component: ChangePassword,
		key: routesEnum.RESET_PASSWORD,
		name: 'Redefinir Senha',
		path: routesEnum.RESET_PASSWORD,
		noWrapper: true
	},
	{
		component: ForgotPassword,
		key: routesEnum.FORGOT_PASSWORD,
		name: 'Recuperar Senha',
		path: routesEnum.FORGOT_PASSWORD,
		noWrapper: true
	},
	{
		component: UsersList,
		key: routesEnum.USERS_PAGE,
		name: 'Usuários',
		path: routesEnum.USERS_PAGE,
		accessTypes: ['ADMIN']
	},
	{
		component: CustomerList,
		key: routesEnum.CUSTOMERS_PAGE,
		name: 'Clientes',
		path: routesEnum.CUSTOMERS_PAGE,
		accessTypes: ['ADMIN']
	},
	{
		component: TrendList,
		key: routesEnum.TRENDS_PAGE,
		name: 'Hot Topics',
		path: routesEnum.TRENDS_PAGE,
		accessTypes: ['ADMIN', 'EDITOR']
	},
	{
		component: EventsPage,
		key: routesEnum.EVENTS_PAGE,
		name: 'Eventos',
		path: routesEnum.EVENTS_PAGE,
		accessTypes: ['ADMIN', 'EDITOR']
	},
	{
		component: ReportsPage,
		key: routesEnum.REPORTS_PAGE,
		name: 'Latests',
		path: routesEnum.REPORTS_PAGE,
		accessTypes: ['ADMIN', 'EDITOR']
	},
	{
		component: TierTimer,
		key: routesEnum.TIER_TIMER,
		name: 'Temporizador',
		path: routesEnum.TIER_TIMER,
		accessTypes: ['ADMIN'],
		noWrapper: false
	}
]
