export function isYoutubeVideo(url: string) {
	if (!url) return false
	const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

	return youtubeRegex.test(url)
}

export function normalizeYoutubeLink(url: string) {
	return url && url.indexOf('http') < 0 ? 'https://' + url : url
}

export function isPowerBIGraph(url: string) {
	if (!url) return false

	const powerBIRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

	return powerBIRegex.test(url)
}
