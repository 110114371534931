import { colors } from 'shared/styles/theme'
import { fontType, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
	position: fixed;
	top: 0;
	z-index: 20;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 80px;
	box-shadow: 0px 2px 3px #00000014;
	border-bottom: 1px solid ${colors.lightGrey};
	background: ${colors.white};
	font-family: 'Regular';
	padding: 10px;

	.menu-content {
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.xp-logo {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.placeholder-spacer {
		display: flex;
		align-items: center;
		color: #4f4f4d;
		opacity: 1;
		.user-circle {
			background: 0% 0% no-repeat padding-box;
			background-color: ${colors.lightGrey};
			border-radius: 50%;
			padding: 16px;
			img {
				width: 18px;
			}
		}
		span {
			font-size: ${toRem(14)};
			${fontType(0)};
			margin-right: 16px;
		}
	}
`
