import { LabelButton } from '@buildbox/components'
import cogoToast from 'cogo-toast'
import React, { ChangeEvent } from 'react'
import { ICritic } from 'shared/interfaces/customer'
import { uploadCustomers } from 'shared/services/customer.service'
import { primaryButtonStyle } from 'shared/styles/theme'
import cogoDefaultOptions from 'shared/util/toaster'
import { UploadCustomersButtonWrapper } from './styles'
import { IUploadCustomersButtonProps } from './types'

export const UploadCustomersInput: React.FC<IUploadCustomersButtonProps> = ({
	loading,
	openModal,
	setErrorsValue,
	getCustomersPage
}) => {
	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		try {
			loading(true)
			const selectedCustomers = event.target?.files?.item(0)
			if (!selectedCustomers) return

			const formData: FormData = new FormData()
			formData.append('customers', selectedCustomers)

			event.target.value = ''

			const messageValue = await uploadCustomers(formData)

			if (messageValue.status === 455) {
				handleShowError(messageValue.data.content)
			} else {
				cogoToast.success('Upload realizado com sucesso!', cogoDefaultOptions)
			}
		} catch (err) {
			console.log({ err })
		} finally {
			getCustomersPage(1)
			loading(false)
		}
	}

	function handleShowError(critics: ICritic[]) {
		setErrorsValue(critics)
		openModal()
	}

	return (
		<UploadCustomersButtonWrapper>
			<LabelButton
				type='file'
				accept='.xlsx'
				id='input'
				onChange={handleInputChange}
				{...primaryButtonStyle}
			>
				Upload de Clientes
			</LabelButton>
		</UploadCustomersButtonWrapper>
	)
}
