import React, { createElement, useEffect, useState } from 'react'
import { fetchUsersPage } from 'shared/services/user.service'
import { IUser, IUsersPage } from '../../shared/interfaces/user'
import { ITableColumn, ITableRow, IViewProps } from './types'

import UsersList from './view'
import { TableNavProps } from '@buildbox/components'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'
import { useDebounce } from 'use-debounce/lib'
import { backScrollBody } from 'shared/util/scroll'

const USERS_PER_PAGE = 10

function UsersPageContainer(): JSX.Element {
	const [usersPage, setUsersPage] = useState<IUsersPage>({
		pageIndex: 1,
		numberOfPages: 0,
		totalDocs: 0,
		pageContent: []
	})

	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [isLoading, setLoading] = useState(false)
	const [showModalNewUser, setShowModalNewUser] = useState(false)
	const [showModalEditUser, setShowModalEditUser] = useState(false)
	const [userEdit, setUserEdit] = useState<IUser>({} as IUser)
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)

	const [
		targetUserDelete,
		setTargetUserDelete
	] = useState<IModalDeleteTarget | null>(null)

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleSearchDebounce() {
		getUsersPage(1, searchDebounce)
	}

	useEffect(handleSearchDebounce, [searchDebounce])

	async function getUsersPage(pageIndex: number, searchString = '') {
		try {
			setLoading(true)
			const pageData = await fetchUsersPage(
				pageIndex,
				USERS_PER_PAGE,
				searchString
			)

			setUsersPage(pageData)
			handleRefetch(pageIndex, pageData.pageContent)

			const rows = pageData.pageContent.map((user) => {
				return {
					name: fullName(user),
					email: user.email,
					position: user.position,
					role: user.role === 'ADMIN' ? 'Sim' : 'Não',
					editIcon: renderEditIcon(user),
					trashIcon: renderTrashIcon(user)
				}
			})

			setTableRows(rows)
		} catch (err) {
			handleRefetch(usersPage.pageIndex, usersPage.pageContent)
		} finally {
			setLoading(false)
		}
	}

	function handleRefetch(pageIndex: number, arr: any[]) {
		const isEmpty = !!!arr.length
		const notFirstPage = pageIndex !== 1

		if (isEmpty && notFirstPage) {
			getUsersPage(1, search)
		}
	}

	async function updateUserPageAfterModalClosed() {
		await getUsersPage(usersPage.pageIndex, search)
		backScrollBody()
	}

	function handleModalNewUser() {
		setShowModalNewUser(!showModalNewUser)
		backScrollBody()
	}

	function fullName(user: IUser): JSX.Element {
		return (
			<p>
				{user.firstName} {user.lastName}
			</p>
		)
	}

	function renderEditIcon(user: IUser): JSX.Element {
		return (
			<button className='icon' onClick={() => handleEditUser(user)}>
				<img
					className='img'
					src={require('../../assets/images/file-text.svg')}
					alt='Editar'
				/>
			</button>
		)
	}

	async function handleEditUser(user: IUser) {
		setUserEdit(user)
		setShowModalEditUser(!showModalEditUser)
	}

	function renderTrashIcon(user: IUser): JSX.Element {
		return (
			<button
				className='icon'
				onClick={() =>
					setTargetUserDelete({ id: user._id, name: user.firstName })
				}
			>
				<img
					className='img'
					src={require('../../assets/images/trash.svg')}
					alt='Excluir'
				/>
			</button>
		)
	}

	function handleDeleteUserOnClose() {
		setTargetUserDelete(null)
		backScrollBody()
	}

	async function handleDeleteUserEnd() {
		setTargetUserDelete(null)
		backScrollBody()

		let pageIndex = usersPage.pageIndex
		const isLastPage =
			usersPage.pageContent.length === 1 && usersPage.pageIndex > 1

		if (isLastPage) pageIndex -= 1

		await getUsersPage(pageIndex, search)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Nome', accessor: 'name', sortType: 'basic' },
		{ Header: 'E-mail', accessor: 'email', sortType: 'basic' },
		{ Header: 'Cargo', accessor: 'position', sortType: 'basic' },
		{ Header: 'Administrador?', accessor: 'role', sortType: 'basic' },
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		},
		{
			Header: '',
			accessor: 'trashIcon',
			disableSortBy: true
		}
	]

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => getUsersPage(pageIndex + 1, search),
		previousPage: (pageIndex) => getUsersPage(pageIndex + 1, search),
		gotoPage: (pageIndex) => getUsersPage(pageIndex + 1, search),
		pageCount: usersPage.numberOfPages,
		pageIndex: usersPage.pageIndex - 1,
		totalDocs: usersPage.totalDocs
	}

	const viewProps: IViewProps = {
		tableColumns,
		tableRows,
		isLoading,
		navProps,
		usersPerPage: USERS_PER_PAGE,
		userEdit,
		updateUserPageAfterModalClosed,
		handleModalNewUser,
		handleModalEditUser: handleEditUser,
		showModalNewUser,
		showModalEditUser,
		handleDeleteUserOnClose,
		targetUserDelete,
		handleSearch,
		search,
		handleDeleteUserEnd
	}
	return createElement(UsersList, viewProps)
}

export default UsersPageContainer
