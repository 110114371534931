import React from 'react'
import {
	Button,
	FloatLabelInput,
	Loading,
	Modal,
	Select,
	Table
} from '@buildbox/components'
import {
	ModalConfigurationTitle,
	SwitchesRow,
	SwitchesRowLabel,
	SwitchesWrapper,
	SwitchContainer,
	Separator
} from '../../shared/components/ModalStyledComponents'
import {
	colors,
	primaryButtonStyle,
	primaryInputStyle,
	tableStyle
} from 'shared/styles/theme'
import {
	InputRow,
	ModalEventWrapper,
	EventFormWrapper,
	SwitchWrapper
} from './styles'
import { Row } from 'shared/styles'
import { IViewProps } from './types'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import DefaultPagePlaceholder from '../../shared/components/DefaultPagePlaceholder'
import EditorTextBox from '../../shared/components/EditorTextBox'
import FloatLabelDateInput from '../../shared/components/FloatLabelDateInput'
import FloatLabelTimeInput from '../../shared/components/FloatLabelTimeInput'
import ImageUpload from '../../shared/components/ImageUpload'
import Switch from '../../shared/components/Switch'
import ViewErrors from '../../shared/components/ViewErrors'
import { ImageInputWrapper } from 'modules/ModalTrend/styles'
import { Column } from 'shared/styles'
import { ITEMS_PER_PAGE, TableColumns } from './data'
import PdfFiles from 'shared/components/PdfFiles'
import ResponsiveInput from '../../shared/components/ResponsiveInput'
import SwitchLanguage from 'shared/components/SwitchLanguage'

export default function ModalEvent(props: IViewProps) {
	const {
		isActive,
		event,
		attendeesNavProps,
		feedbacksNavProps,
		handleImage,
		modalOptions,
		modalValues,
		eventForm,
		handleChangeForm,
		isEditing,
		handleInputChangeDate,
		handleInputChangeTime,
		handleVideoLink,
		handleTierSelect,
		handleEditor,
		handlePdf,
		handleNotificate,
		handleEmailNotificate,
		handleFeedback,
		handleAttending,
		isLoading,
		handleSubmit,
		handleClose,
		isLoadingCSV,
		download,
		tiersOptions,
		switchValue,
		setSwitchValue,
		isLoadingTable
	} = props

	function renderForm(): JSX.Element {
		return (
			<EventFormWrapper>
				<div className='upper-section'>
					<ImageInputWrapper>
						<Column sm='12' lg='6'>
							<ImageUpload
								isDisabled={modalOptions.eventIsPast}
								returnImage={handleImage}
								imageUrl={modalValues.image?.url || ''}
							/>
						</Column>
					</ImageInputWrapper>
					<SwitchWrapper>
						<SwitchLanguage
							setSwitchValue={setSwitchValue}
							switchValue={switchValue}
						/>
					</SwitchWrapper>

					<InputRow>
						<Column sm='12' lg='6'>
							{window.innerWidth > 480 ? (
								<FloatLabelInput
									id='title'
									label='Título'
									disabled={modalOptions.eventIsPast}
									value={eventForm.title}
									onChange={(event) =>
										handleChangeForm(event.target.value, 'title')
									}
									{...primaryInputStyle}
									className='input'
								/>
							) : (
								<ResponsiveInput
									id='title'
									label='Título'
									disabled={modalOptions.eventIsPast}
									value={eventForm.title}
									onChange={(value: string) => handleChangeForm(value, 'title')}
									{...primaryInputStyle}
									className='input'
								/>
							)}
							<Row>
								<Column sm='12' lg='7'>
									<FloatLabelDateInput
										id='date'
										label='Data'
										disabled={modalOptions.eventIsPast}
										date={eventForm.date}
										setDate={handleInputChangeDate}
										className='input-date'
										{...primaryInputStyle}
									/>
								</Column>
								<Column sm='12' lg='5'>
									<FloatLabelTimeInput
										id='time'
										label='Hora'
										disabled={modalOptions.eventIsPast}
										time={eventForm.time}
										setTime={handleInputChangeTime}
										className='input-date'
										{...primaryInputStyle}
									/>
								</Column>
							</Row>
							<FloatLabelInput
								id='youtube-link'
								label='Link Vídeo (Youtube)'
								disabled={modalOptions.eventIsPast}
								value={modalValues.youtubeLink}
								onChange={(event) => handleVideoLink(event.target.value)}
								{...primaryInputStyle}
								className='input'
							/>
						</Column>

						<Column sm='12' lg='6'>
							{window.innerWidth > 480 ? (
								<FloatLabelInput
									id='subtitle'
									label='Subtítulo (opcional)'
									disabled={modalOptions.eventIsPast}
									value={eventForm.subtitle}
									onChange={(event) =>
										handleChangeForm(event.target.value, 'subtitle')
									}
									{...primaryInputStyle}
									className='input'
								/>
							) : (
								<ResponsiveInput
									id='subtitle'
									label='Subtítulo (opcional)'
									disabled={modalOptions.eventIsPast}
									value={eventForm.subtitle}
									onChange={(value: string) =>
										handleChangeForm(value, 'subtitle')
									}
									{...primaryInputStyle}
									className='input'
								/>
							)}
							<Select
								id='tier'
								placeholder='Tier'
								isDisabled={modalOptions.eventIsPast}
								value={modalValues.selectedTier}
								options={tiersOptions}
								onChange={handleTierSelect as any}
								className='select'
							/>
						</Column>
					</InputRow>
				</div>

				<div className='text-editor-area'>
					<EditorTextBox
						value={modalValues.body}
						returnValue={handleEditor}
						disabled={modalOptions.eventIsPast}
						isActive={isActive}
					/>
				</div>

				<div className='list-pdfs-files'>
					<PdfFiles returnPdf={handlePdf} initialPdfs={event.attachments} />
				</div>

				<ModalConfigurationTitle children='Configurações do Evento' />

				<SwitchesRow className='first-switch-row' switchesQuantity={2}>
					<SwitchesRowLabel>Notificar Clientes:</SwitchesRowLabel>
					<SwitchesWrapper>
						<SwitchContainer>
							<Switch
								isChecked={eventForm.notificate}
								onChange={handleNotificate}
								label='Push'
								disabled={modalOptions.eventIsPast}
							/>
						</SwitchContainer>

						<SwitchContainer>
							<Switch
								isChecked={eventForm.emailNotificate}
								onChange={handleEmailNotificate}
								label='E-mail'
								disabled={modalOptions.eventIsPast}
							/>
						</SwitchContainer>
					</SwitchesWrapper>
				</SwitchesRow>

				<Separator />

				<SwitchesRow className='last-switch-row' switchesQuantity={2}>
					<SwitchesRowLabel>Habilitar:</SwitchesRowLabel>
					<SwitchesWrapper>
						<SwitchContainer>
							<Switch
								isChecked={modalValues.enableAttending}
								onChange={handleAttending}
								label='Interessados'
								disabled={modalOptions.eventIsPast}
							/>
						</SwitchContainer>
						<SwitchContainer>
							<Switch
								isChecked={modalValues.enableFeedback}
								onChange={handleFeedback}
								label='Feedbacks'
								disabled={modalOptions.eventIsPast}
							/>
						</SwitchContainer>
					</SwitchesWrapper>
				</SwitchesRow>

				<ViewErrors
					className='view-error'
					errors={modalOptions.viewErrors}
					color='red'
				/>

				{isLoading ? (
					<div className='loading-wrapper'>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</div>
				) : (
					<Button
						className='submit-button'
						disabled={modalOptions.eventIsPast || !modalValues.enableButton}
						onClick={handleSubmit}
						{...primaryButtonStyle}
					>
						{isEditing ? 'SALVAR ALTERAÇÕES' : 'PUBLICAR'}
					</Button>
				)}
			</EventFormWrapper>
		)
	}

	return (
		<ModalEventWrapper>
			<Modal
				className='modal-primary'
				size={752}
				title={modalValues.title}
				isActive={isActive}
				onClose={handleClose}
				scrollRef='scroll-ref'
			>
				<Tabs className='tabs'>
					<TabList>
						<Tab>Detalhes</Tab>
						{isEditing ? (
							<>
								<Tab>Interessados</Tab>
								<Tab>Feedbacks</Tab>
							</>
						) : null}
					</TabList>

					<TabPanel>{renderForm()}</TabPanel>

					{isEditing && (
						<TabPanel>
							<div className='export-button'>
								{isLoadingCSV ? (
									<Loading type='Sentry' primaryColor={colors.secondary} />
								) : (
									<>
										{!!modalValues.attendeesTable.pageContent.length ? (
											<Button
												className='button'
												onClick={() => download('attendees')}
												{...primaryButtonStyle}
											>
												{'EXPORTAR LISTA'}
											</Button>
										) : null}
									</>
								)}
							</div>

							{isLoadingTable ? (
								<div className='table-loading'>
									<Loading type='Sentry' primaryColor={colors.secondary} />
								</div>
							) : (
								<div className='table-container'>
									{modalValues.attendeesTable.pageContent.length ? (
										<Table
											columns={TableColumns as any}
											data={modalValues.attendeesTable.pageContent}
											navProps={attendeesNavProps}
											pageSize={ITEMS_PER_PAGE}
											{...tableStyle}
											padding={[24, 16, 24, 16]}
										/>
									) : (
										<DefaultPagePlaceholder
											className='placeholder-wrapper'
											text='No momento, não há Pré-Inscrição para serem exibidos..'
										/>
									)}
								</div>
							)}
						</TabPanel>
					)}

					{isEditing && (
						<TabPanel>
							<div className='export-button'>
								{isLoadingCSV ? (
									<Loading type='Sentry' primaryColor={colors.secondary} />
								) : (
									<>
										{!!modalValues.feedbackTable.pageContent.length ? (
											<Button
												className='button'
												onClick={() => download('feedback')}
												{...primaryButtonStyle}
											>
												{'EXPORTAR LISTA'}
											</Button>
										) : null}
									</>
								)}
							</div>

							{isLoadingTable ? (
								<div className='table-loading'>
									<Loading type='Sentry' primaryColor={colors.secondary} />
								</div>
							) : (
								<div className='table-container'>
									{modalValues.feedbackTable.pageContent.length ? (
										<Table
											columns={TableColumns as any}
											data={modalValues.feedbackTable.pageContent}
											navProps={feedbacksNavProps}
											pageSize={ITEMS_PER_PAGE}
											{...tableStyle}
											padding={[24, 16, 24, 16]}
										/>
									) : (
										<DefaultPagePlaceholder
											className='placeholder-wrapper'
											text='No momento, não há Feedback para serem exibidos.'
										/>
									)}
								</div>
							)}
						</TabPanel>
					)}
				</Tabs>
			</Modal>
		</ModalEventWrapper>
	)
}
