import { Button, Loading, Modal } from '@buildbox/components'
import { colors, secondaryButtonStyle } from 'shared/styles/theme'
import { IModalDeleteProps } from './types'
import { FUNC_ENUM, MESSAGE_ENUM, SUCCESS_MESSAGE, TITLE_ENUM } from './data'
import { ModalDeleteWrapper } from './styles'
import React, { useState } from 'react'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'

// TREND & REPORT was not implemented yet
export default function ModalDelete({
	onClose,
	type,
	onEnd,
	target
}: IModalDeleteProps) {
	const [isLoading, setLoading] = useState(false)

	const isActive = !!target
	const title = TITLE_ENUM[type]
	const message = MESSAGE_ENUM[type]
	const successMessage = SUCCESS_MESSAGE[type]
	const deleteTarget = FUNC_ENUM[type]

	function onSubmit() {
		;(async () => {
			try {
				setLoading(true)

				if (!target) return null
				await deleteTarget(target.id)

				cogoToast.success(successMessage, cogoDefaultOptions)

				onEnd()
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	return (
		<ModalDeleteWrapper>
			<Modal
				className='modal-primary'
				size={376}
				title={title}
				isActive={isActive}
				onClose={onClose}
				scrollRef='scroll-ref'
			>
				<div className='wrapper'>
					<p className='text'>{message}</p>

					{target?.name && <p className='target-name'>{target.name}</p>}

					{isLoading ? (
						<div className='loading-wrapper'>
							<Loading type='Sentry' primaryColor={colors.secondary} />
						</div>
					) : (
						<Button
							className='delete-button'
							onClick={onSubmit}
							{...secondaryButtonStyle}
						>
							{'EXCLUIR'}
						</Button>
					)}
				</div>
			</Modal>
		</ModalDeleteWrapper>
	)
}
