import { GlobalStyle } from './shared/styles/globalStyle'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './shared/store'
import { Provider } from 'react-redux'
import { theme } from './shared/styles/theme'
import { ThemeProvider } from 'styled-components'
import React, { StrictMode } from 'react'
import Routes from './modules/Routes'

import '@buildbox/components/lib/index.css'

function App() {
	return (
		<StrictMode>
			<GlobalStyle />
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeProvider theme={theme}>
						<Routes />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</StrictMode>
	)
}

export default App
