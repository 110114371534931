import React from 'react'
import { Button, Loading, Table } from '@buildbox/components'
import PageTitle from 'shared/components/PageTitle'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { tableStyle, primaryButtonStyle } from 'shared/styles/theme'
import { colors } from 'shared/styles/theme'
import ModalTrend from 'modules/ModalTrend/index'
import { Header, InputSearchWrapper, TrendsPageWrapper } from './styles'
import { IViewProps } from './types'
import InputSearch from 'shared/components/InputSearch'

function TrendsPage(props: IViewProps): JSX.Element {
	const {
		tableColumns,
		tableRows,
		trendsPerPage,
		navProps,
		isLoading,
		showModalTrend,
		modalAction,
		currentTrend,
		handleCreateTrend,
		handleHideModalTrend,
		handleReloadTrendsPage,
		handleDeleteTrendPage,
		handleSearch,
		search,
		handleFetchDowload,
		isDowloadCharge
	} = props

	return (
		<>
			<TrendsPageWrapper>
				<Header>
					<PageTitle>Hot Topics</PageTitle>

					<div>
						<InputSearchWrapper>
							{isDowloadCharge ? (
								<div className='loading-wrapper'>
									<Loading type='Sentry' primaryColor={colors.secondary} />
								</div>
							) : (
								<Button
									id='button'
									className='export-content'
									{...primaryButtonStyle}
									onClick={handleFetchDowload}
								>
									DOWLOAD DE HOT TOPIC
								</Button>
							)}
						</InputSearchWrapper>

						<InputSearchWrapper>
							<InputSearch
								id='search'
								label='Buscar Hot Topic'
								onChange={(e) => handleSearch(e.target.value)}
								value={search}
							/>
						</InputSearchWrapper>

						<Button
							id='button'
							{...primaryButtonStyle}
							onClick={() => handleCreateTrend()}
						>
							NOVO HOT TOPIC
						</Button>
					</div>
				</Header>

				{isLoading ? (
					<div className='table-loading'>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</div>
				) : (
					<div className='table-container'>
						{tableRows.length ? (
							<Table
								// Workaround while we need to fix columns type on lib
								columns={tableColumns as any}
								data={tableRows}
								navProps={navProps}
								pageSize={trendsPerPage}
								{...tableStyle}
							/>
						) : (
							<DefaultPagePlaceholder text='No momento, não há Hot topics para serem exibidos.' />
						)}
					</div>
				)}

				{showModalTrend ? (
					<ModalTrend
						isActive={showModalTrend}
						modalAction={modalAction}
						currentTrend={currentTrend}
						hideModal={handleHideModalTrend}
						reloadTrendsPage={handleReloadTrendsPage}
						reloadDeleteTrend={handleDeleteTrendPage}
					/>
				) : null}
			</TrendsPageWrapper>
		</>
	)
}

export default TrendsPage
