import { PickStyledComponentsDefaultProps } from '@buildbox/components/lib/styles/types'
import styled, { css } from 'styled-components'
import { fontType, toRem } from '../../styles/functions'
import { colors } from '../../styles/theme'

import { EditorPickedProps } from './types'

export const EditorTextBoxWrapper = styled.div<
	PickStyledComponentsDefaultProps<EditorPickedProps>
>`
	${({ styles }) => css`
		width: 100%;
		${fontType(0)};
		font-size: ${styles.fontSize}px;

		> div {
			overflow-y: auto;
			height: ${toRem(857)};
		}
	`}
`

export const Bold = styled.span`
	${fontType(2)};
`

export const Italic = styled.span`
	font-style: italic;
`
export const Underline = styled.span`
	text-decoration: underline;
`
export const Code = styled.code``

export const BlockQuote = styled.blockquote`
	padding: 0 16px;
	text-align: left;

	position: relative;
	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
	}
`
export const UnorderedList = styled.ul`
	margin-left: 24px;
`
export const OrderedList = styled.ol`
	margin-left: 24px;
`
export const ListItem = styled.li`
	text-align: left;
`
export const H1 = styled.h1`
	font-size: ${toRem(24)};
	text-align: left;
`
export const H2 = styled.h2`
	font-size: ${toRem(20)};
	text-align: left;
`
export const Paragraph = styled.p`
	font-size: ${toRem(16)};
	text-align: left;
	${fontType(0)};
`

export const Divider = styled.div`
	width: 1px;
	background: ${colors.grey};
	height: 16px;
	margin: 0 8px;
`

export const AlignCenterBlock = styled.div`
	text-align: center;
`
export const AlignJustifyBlock = styled.div`
	text-align: justify;
`
export const AlignRightBlock = styled.div`
	text-align: right;
`
export const AlignLeftBlock = styled.div`
	text-align: left;
`

export const Hyperlink = styled.span`
	color: #2980b9;
`
