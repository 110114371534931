import React from 'react'

import { Loading, Modal, Button } from '@buildbox/components'
import {
	ModalConfigurationTitle,
	SwitchesRow,
	SwitchesRowLabel,
	SwitchesWrapper,
	SwitchContainer,
	Separator
} from '../../shared/components/ModalStyledComponents'
import {
	colors,
	primaryInputStyle,
	primaryButtonStyle,
	selectStyle
} from 'shared/styles/theme'

import EditorTextBox from 'shared/components/EditorTextBox'
import ViewErrors from 'shared/components/ViewErrors'

import {
	ModalTrendWrapper,
	ModalDeleteTrend,
	ImageInputWrapper,
	SwitchWrapper,
	ModalReportContainer,
	LanguageLabel,
	SwicthLabelContainer
} from './styles'
import { IViewProps } from './types'
import { IMAGE_INITIAL_VALUE } from 'shared/util/Consts'
import Switch from 'shared/components/Switch'
import ImageUpload from 'shared/components/ImageUpload'
import { Column, Row } from 'shared/styles'
import PdfFiles from 'shared/components/PdfFiles'
import { defineModalButtonName, defineModalTitle } from './util'
import ResponsiveInput from 'shared/components/ResponsiveInput'
import SwitchLanguage from 'shared/components/SwitchLanguage'
import FloatLabelInput from 'shared/components/FloatLabelInput'
import Select from 'shared/components/Select'

function ModalTrend(props: IViewProps) {
	const {
		isActive,
		modalAction,
		currentTrend,
		hideModal,
		isLoading,
		initializeTrendFields,
		handleImage,
		handleSubmit,
		modalValue,
		modalOptions,
		handleDelete,
		handleEditor,
		handleSelectChange,
		handleSelectCategoryChange,
		handleTitle,
		handleYoutubeLink,
		handleSubtitle,
		handleSetPdf,
		setNotificate,
		setEmailNotificate,
		setIsPublic,
		setSwitchValue,
		switchValue,
		graphLink,
		handleGraphLink
	} = props

	return (
		<ModalReportContainer>
			<Modal
				className='modal-primary'
				title={defineModalTitle(modalAction)}
				size={modalAction !== 'DELETE' ? 752 : 375}
				isActive={isActive}
				onClose={() => {
					hideModal()
					initializeTrendFields()
					handleImage(IMAGE_INITIAL_VALUE)
				}}
				scrollRef='scroll-ref'
			>
				{modalAction !== 'DELETE' ? (
					<ModalTrendWrapper>
						<div className='upper-section'>
							<ImageInputWrapper>
								<Column sm='12' lg='6'>
									<ImageUpload
										returnImage={handleImage}
										imageUrl={modalValue.image?.url || ''}
									/>
								</Column>
							</ImageInputWrapper>
							<SwitchWrapper>
								<LanguageLabel>IDIOMA</LanguageLabel>
								<SwicthLabelContainer>
									<SwitchLanguage
										setSwitchValue={setSwitchValue}
										switchValue={switchValue}
									/>
								</SwicthLabelContainer>
							</SwitchWrapper>
							<Row>
								<Column sm='12' lg='6'>
									{window.innerWidth > 480 ? (
										<FloatLabelInput
											id='title'
											label='Título'
											value={modalValue.title}
											//disabled={modalAction !== 'CREATE'}
											onChange={(e) => handleTitle(e.target.value)}
											{...primaryInputStyle}
											className='input'
										/>
									) : (
										<ResponsiveInput
											id='title'
											label='Título'
											value={modalValue.title}
											//disabled={modalAction !== 'CREATE'}
											onChange={handleTitle}
											{...primaryInputStyle}
											className='input'
										/>
									)}
									<Select
										id='category'
										label='Categoria'
										isDisabled={false}
										options={modalOptions.categoryOptions}
										placeholder={'Selecione um categoria'}
										value={modalValue.selectedCategory}
										onChange={handleSelectCategoryChange}
										{...selectStyle}
										className='select'
									/>
									<FloatLabelInput
										id='youtube-link'
										label='Link Vídeo (Youtube)'
										value={modalValue.youtubeLink}
										onChange={handleYoutubeLink}
										{...primaryInputStyle}
										className='input'
									/>
								</Column>

								<Column sm='12' lg='6'>
									{window.innerWidth > 480 ? (
										<FloatLabelInput
											id='subtitle'
											label='Subtítulo (Opcional)'
											value={modalValue.subtitle}
											onChange={(e) => handleSubtitle(e.target.value)}
											{...primaryInputStyle}
											className='input'
										/>
									) : (
										<ResponsiveInput
											id='subtitle'
											label='Subtítulo (Opcional)'
											value={modalValue.subtitle}
											onChange={handleSubtitle}
											{...primaryInputStyle}
											className='input'
										/>
									)}
									<Select
										id='author'
										label='Author'
										options={modalOptions.authorsOptions}
										placeholder={'Selecione um autor'}
										value={modalValue.selectedAuthor}
										onChange={(value: any) => handleSelectChange(value)}
										{...selectStyle}
										className='select'
									/>

									<FloatLabelInput
										id='graph-link'
										label='Link Gráfico (POWER BI)'
										value={graphLink}
										onChange={handleGraphLink}
										{...primaryInputStyle}
										className='input'
									/>
								</Column>
							</Row>
						</div>

						<div className='text-editor-area'>
							<EditorTextBox
								value={modalValue.body}
								returnValue={handleEditor}
								isActive={isActive}
							/>
						</div>

						<div className='list-pdfs-files'>
							<PdfFiles
								returnPdf={handleSetPdf}
								initialPdfs={currentTrend.attachments}
							/>
						</div>

						<ModalConfigurationTitle children='Configurações do Hot Topic' />

						<SwitchesRow className='first-switch-row' switchesQuantity={2}>
							<SwitchesRowLabel>Notificar Clientes:</SwitchesRowLabel>
							<SwitchesWrapper>
								<SwitchesWrapper>
									<Switch
										label='Push'
										isChecked={modalValue.notificate}
										onChange={setNotificate}
									/>
								</SwitchesWrapper>
								<SwitchContainer>
									<Switch
										label='E-mail'
										isChecked={modalValue.emailNotificate}
										onChange={setEmailNotificate}
									/>
								</SwitchContainer>
							</SwitchesWrapper>
						</SwitchesRow>

						<Separator />

						<SwitchesRow className='last-switch-row' switchesQuantity={1}>
							<SwitchesRowLabel>Visibilidade:</SwitchesRowLabel>
							<SwitchesWrapper>
								<SwitchesWrapper>
									<Switch
										label='Público'
										isChecked={modalValue.isPublic}
										onChange={setIsPublic}
									/>
								</SwitchesWrapper>
							</SwitchesWrapper>
						</SwitchesRow>

						<ViewErrors errors={modalOptions.viewErrors} color='red' />

						{isLoading ? (
							<div className='loading-wrapper'>
								<Loading type='Sentry' primaryColor={colors.secondary} />
							</div>
						) : (
							<Button
								className='modal-button'
								disabled={!modalValue.isFormValid}
								onClick={handleSubmit}
								{...primaryButtonStyle}
							>
								{defineModalButtonName(modalAction)}
							</Button>
						)}
					</ModalTrendWrapper>
				) : (
					<ModalDeleteTrend>
						<p>Você deseja excluir o Hot Topics</p>
						<p>"{currentTrend.title}"?</p>
						{isLoading ? (
							<Loading type='Sentry' primaryColor={colors.secondary} />
						) : (
							<Button onClick={() => handleDelete(currentTrend._id)}>
								{'EXCLUIR'}
							</Button>
						)}
					</ModalDeleteTrend>
				)}
			</Modal>
		</ModalReportContainer>
	)
}

export default ModalTrend
