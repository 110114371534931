import React from 'react'
import { IViewProps } from './types'

import {
	TierTimerWrapper,
	Header,
	ContentContainer,
	LoadingContent
} from './styles'
import PageTitle from 'shared/components/PageTitle'
import { colors } from 'shared/styles/theme'
import { Loading } from '@buildbox/components'
import TierController from 'shared/components/TierController'

function TierTimer(props: IViewProps) {
	const { tiersArray, isLoading } = props

	return (
		<TierTimerWrapper>
			<Header>
				<PageTitle>Temporizador</PageTitle>
			</Header>
			{isLoading ? (
				<LoadingContent>
					<Loading type='Sentry' primaryColor={colors.secondary} />
				</LoadingContent>
			) : (
				<ContentContainer>
					{tiersArray.map((tier) => (
						<TierController key={tier._id} {...tier} />
					))}
				</ContentContainer>
			)}
		</TierTimerWrapper>
	)
}
export default TierTimer
