import styled from 'styled-components'

export const PageWrapper = styled.div`
	background: ${({ theme }) => theme.colors.pageBackground};
	min-height: 100vh;
	.container-wrapper {
		margin-top: 80px;
		padding: 16px;
		padding-top: 64px;
	}
`
