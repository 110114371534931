import React from 'react'
import { FaMagic } from 'react-icons/fa'
import { ButtonWrapper, IconWrapper } from './styles'
import { IProps } from './types'

const LinkButton = ({ onClick }: IProps) => {
	return (
		<ButtonWrapper onClick={onClick} >
			<IconWrapper className="active">
				<FaMagic color="#D1D1D1" />
			</IconWrapper>
		</ButtonWrapper>
	)
}

export default LinkButton
