import { Button } from '@buildbox/components'
import { toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const ModalUserEditWrapper = styled.form`
	.modal-input {
		margin: 0 0 16px 0;
	}
`

export const ModalButton = styled(Button)`
	text-align: center;
`

export const ModalSwitch = styled.div`
	margin-bottom: 32px;
	div {
		display: flex;
		justify-content: space-between;

		padding-top: ${toRem(8)};
	}
`

export const LoadingContent = styled.div`
	justify-content: center;
	display: flex;
`
