import styled from 'styled-components'
import { fontType, mediaQuery, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'

export const ModalContainer = styled.div`
	button[disabled] {
		color: ${colors.white};
	}

	button {
		cursor: pointer;
	}
`

export const BtnSubmit = styled.div`
	text-align: center;
	margin-top: ${toRem(10)};
`

export const ModalSelect = styled.fieldset`
	border: none;
	position: relative;

	&:nth-child(9) {
		grid-column: 2;
	}

	&:nth-child(2) {
		margin-bottom: 1rem;
	}
`

export const ModalCustomerEditWrapper = styled.form`
	.btn-submit {
		text-align: center;
		margin-top: 40px;
		color: ${colors.secondary};
	}
`

export const FormContainer = styled.div`
	display: grid;

	grid-template-columns: 1fr 1fr;
	gap: ${toRem(16)} ${toRem(32)};

	${mediaQuery('xs')(`
		grid-template-columns: 1fr;
	`)}
`

export const StatusCustomer = styled.div`
	background: ${colors.backgroundState};
	border-radius: 2px;
	display: flex;
	place-content: space-between;
	padding: 11px;
	place-items: center;
	border-radius: 4px;
	margin-bottom: ${toRem(16)};

	> p {
		font-size: 14px;
		font-family: 'Bold';
		letter-spacing: 0px;
		color: ${colors.darkGrey};
	}
`
export const StatusContainerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

export const LineSeparator = styled.div`
	width: 100%;
	height: 1px;
	background: ${colors.separatorColor};
`

export const InputCheckBoxContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 40px;

	p {
		margin-left: 8px;
		color: ${colors.lightBrown};
		font-size: 14px;
		${fontType(0)}
		margin-top:5px
	}
`

export const BZElectionTitle = styled.p`
	color: ${colors.lightBrown};
	${fontType(0)};
	font-size: 14px;
	margin-bottom: 8px;
	margin-top: 24px;
`

export const BZElectionCategory = styled.div`
	display: flex;

	div {
		display: flex;
	}
`
