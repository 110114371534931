import { IAttachment } from 'shared/interfaces/attachment'

export interface ITrend {
	_id: string
	title: string
	subtitle: string
	category: TrendCategoryEnum
	author: string
	createdBy: string
	coverImage: string
	body: string
	notificate: boolean
	emailNotificate: boolean
	videoLink: string
	languages: string[]
	status: TrendStatusEnum
	isPublic: boolean
	attachments: IAttachment[]
	createdAt?: Date
	updatedAt?: Date

	webPageURL?: string
}

export interface ITrendsPayload {
	title: string
	subtitle: string
	author: string
	createdBy: string
	category: TrendCategoryEnum
	coverImage: string
	notificate: boolean
	videoLink: string
	body: string
	attachments: IAttachment[]
}

export interface ITrendsPage {
	pageIndex: number
	numberOfPages: number
	totalDocs: number
	pageContent: ITrend[]
}

enum TrendCategory {
	POLITICS,
	MACRO_STRATEGY,
	SECTOR_MONITOR,
	INTERNATIONAL_POLITICS
}

export type TrendCategoryEnum = keyof typeof TrendCategory

export const trendCategory = [
	'POLITICS',
	'MACRO_STRATEGY',
	'SECTOR_MONITOR',
	'INTERNATIONAL_POLITICS'
]

enum TrendStatus {
	PENDING,
	ACTIVE,
	INACTIVE,
	DELETED
}

export type TrendStatusEnum = keyof typeof TrendStatus
