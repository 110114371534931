import { fontType, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const EventsListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.search-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 32px;
	}

	.table-loading {
		display: flex;
		justify-content: center;
		align-items: center;

		padding: 32px;
	}

	.table-container {
		margin-top: 50px;

		.event-title {
			min-width: 250px;
		}
		.event-subtitle {
			min-width: 250px;
		}

		.icon {
			border: none;
			display: flex;
			margin: auto;
			background: transparent;
			cursor: pointer;

			.img {
				max-width: 19px;
				max-height: 19px;
			}
		}

		.event-date {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 8px 16px;
			background: #f9f9f9;
			width: 50px;
			height: 50px;

			strong {
				${fontType(2)};
				font-size: ${toRem(18)};
			}

			span {
				font-size: ${toRem(10)};
			}
		}

		td {
			max-width: 300px;
		}
	}

	.delete-event-modal {
		width: 100%;

		p {
			font-size: 14px;
			text-align: center;
		}

		p + p {
			margin: 0.2rem 0 2rem;
		}

		& > * {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	div {
		display: flex;
		align-items: center;

		#search {
			min-width: ${toRem(250)};
		}
		#button {
			height: ${toRem(45)};
		}
	}

	@media (max-width: 700px) {
		& {
			flex-direction: column;

			> h1 {
				align-self: center;
				margin-bottom: 1.25rem;
			}

			div {
				flex-direction: column;

				#button {
					margin-top: ${toRem(20)};
				}
			}
		}
	}
`
export const InputSearchWrapper = styled.div`
	margin-right: ${toRem(20)};
`
