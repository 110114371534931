import styled, { css } from 'styled-components'

import { transition } from '../../styles/functions'

import { DateRangeOmitedProps, OmitStyledComponentsDefaultProps } from './types'

export const DayPickerWrapper = styled.div<
	OmitStyledComponentsDefaultProps<DateRangeOmitedProps>
>`
	${({ styles }) => css`
		font-family: ${styles.fontFamily};
		.date-picker-primary {
			position: relative;
			width: 100%;

			.DayPickerInput {
				width: 100%;
				input {
					border: none;
					box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
					background: ${styles.backgroundColor};
					color: #515151;
					padding: ${styles.padding};
					width: 100%;
					height: 58px;
					font-size: 14px;

					${transition(0)}

					/* &::placeholder {
						color: ${styles.secondaryColor};
					} */

					&[disabled] {
						border: 1px dashed ${styles.secondaryColor};
					}
				}
			}
		}

		.DayPicker-Day--today {
			color: ${styles.primaryColor} !important;
		}

		.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
			background-color: ${styles.primaryColor} !important;
			color: white !important;
		}

		.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
			background-color: ${styles.primaryColor} !important;
			color: white !important;
			filter: opacity(0.8);
		}
		.DayPicker-Day {
			border-radius: 0 !important;
		}
		.DayPicker-Day--start {
			border-top-left-radius: ${styles.round ? '40px' : '4px'} !important;
			border-bottom-left-radius: ${styles.round ? '40px' : '4px'} !important;
		}
		.DayPicker-Day--end {
			border-top-right-radius: ${styles.round ? '40px' : '4px'} !important;
			border-bottom-right-radius: ${styles.round ? '40px' : '4px'} !important;
		}
	`}
`

// border-radius: ${styles.round ? '40px' : '4px'};
// border: 1px solid ${styles.secondaryColor};
