import { colors } from 'shared/styles/theme'

const statusPending = `color: ${colors.darkGrey}; 
	background: ${colors.secondary}; 
	border: 1px solid ${colors.secondary}`

const statusActive = `color: ${colors.statusActiveColor}; 
	background: ${colors.backgroundGreen};
	border: 1px solid ${colors.backgroundGreen}`

const statusInactive = `color: ${colors.statusInactiveColor}; 
	background: ${colors.blackWithOpacity}; 
	border: 1px solid  ${colors.statusInactiveColor}`

const statusExpired = `color: ${colors.white}; 
	background: ${colors.darkGrey}; 
	border: 1px solid  ${colors.statusInactiveColor}`

export const handleColorType = (status: string = '') => {
	switch (status) {
		case 'PENDING':
			return statusPending
		case 'ACTIVE':
			return statusActive
		case 'INACTIVE':
			return statusInactive
		case 'EXPIRED':
			return statusExpired

		default:
			return colors.white
	}
}
