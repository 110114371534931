import { IProps } from './types'
import { logout } from 'shared/util/session'
import { NavLink, useHistory } from 'react-router-dom'
import { SideNavWrapper } from './styles'
import React from 'react'
import routesEnum from 'modules/Routes/enum'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { IRouteData } from 'modules/Routes/types'

export default function SideNav({
	isOpen,
	handleSetIsOpen,
	routes,
	name = ''
}: IProps): JSX.Element {
	const history = useHistory()
	const { user } = useTypedSelector(['user'])

	function Redirect() {
		const { LOGIN } = routesEnum

		logout()
		history.replace(LOGIN)
	}

	function listAllowedMenus(): IRouteData[] {
		const menus = routes.filter((x) => x.accessTypes?.includes(user.role))
		return menus
	}

	return (
		<SideNavWrapper className={isOpen ? 'active' : ''}>
			<div className='background' onClick={handleSetIsOpen} />
			<div className='menu-wrapper'>
				<div className='spacer' />
				<div className='nav-wrapper'>
					<nav>
						<div className='upper-part'>
							<ul className='nav-top'>
								<div className='profile'>
									{/* <div className='photo'>
										<img
											src={require('../../assets/images/logo-gpvc.svg')}
											alt='profile'
										/>
									</div> */}

									<div className='info'>
										<p className='name'>Olá, {name}</p>
										{/* <p className='profile-link'>
											Meu Perfil
										</p> */}
									</div>
								</div>
							</ul>
							<ul className='nav-mid'>
								{listAllowedMenus().map((route) => (
									<li key={route.name}>
										{route.noWrapper ? (
											<div></div>
										) : route.accessTypes &&
										  route.accessTypes.includes(user.role) ? (
											<NavLink
												className='nav-link'
												activeClassName='active'
												to={route.path}
											>
												<p className='nav-text'>{route.name}</p>
											</NavLink>
										) : (
											<div></div>
										)}
									</li>
								))}
							</ul>
						</div>

						<div className='bottom-part'>
							<ul className='nav-bottom'>
								<li onClick={Redirect}>Sair</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</SideNavWrapper>
	)
}
