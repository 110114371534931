import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'

export const HandleErrorWrapper = styled.div`
	${fontType(0)}

	p {
		margin-top: 10px;
	}
`
export const Messages = styled.p`
	margin-top: 3px;
`
