import axios, { AxiosInstance } from 'axios'

import handleError from '../util/handleError'
import { store } from '../store'

// export const API_URL = 'http://localhost:19999'
export const API_URL = 'https://apixpi.buildbox.com.br'

const axiosInstance: AxiosInstance = axios.create({
	baseURL: `${API_URL}/api`,
	timeout: 300000
})

axiosInstance.interceptors.request.use(
	(request) => {
		const accessToken = store.getState().token.accessToken
		const secret = store.getState().user._id
		const reqTypeConst = 'U'

		//		const accessToken = 'bearer_access_token'
		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`
		}
		if (secret) {
			request.headers.secret = secret
		}

		request.headers.reqtype = reqTypeConst

		return request
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('REQUEST', { err })
		}
		handleError(err?.request?.data)
		throw err
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	(err) => {
		if (process.env.NODE_ENV === 'development') {
			console.log('RESPONSE', { err })
		}
		handleError(err?.response?.data)
		throw err
	}
)

export default axiosInstance
