import { ellipsis, fontType, toRem } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'
import { StylesConfig } from 'react-select'

export const DashboardPageWrapper = styled.div`
	.page-header {
		margin-bottom: 32px;
	}

	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 64px;
	}

	.cards-wrapper {
		.card-item {
			background: white;
			padding: 32px 24px;
			.card-title {
				${fontType(0)};
				font-size: ${toRem(14)};
				color: ${colors.darkGrey};
				letter-spacing: -0.34px;
				margin-bottom: 16px;
			}

			.info {
				display: flex;
				align-items: center;
				.card-count {
					${fontType(-1)};
					font-size: ${toRem(36)};
					color: #000000;
					letter-spacing: -0.86px;
				}

				.percent, .churn, .percent-churn {
					${fontType(0)};
					color: ${colors.darkGrey};

					margin-left: 26px;
				}
				.percent {
					font-size: ${toRem(10)};
					max-width: 100px;
				}
				.churn, .percent-churn {
					font-size: ${toRem(14)};
				}
				.percent-churn {
					margin-left: 12px;
					font-weight: bold;
				}
			}
		}
	}

	.top-events-wrapper {
		.attendees {
		}

		.feedback {
		}

		.event-card {
			background: ${colors.white};
			border-radius: 4px;
			padding: 32px 24px;
			.title {
				font-size: ${toRem(18)};
				color: #000000;
				${fontType(-1)};
				max-width: 60%;
				line-height: ${toRem(26)};
				/* margin-bottom: 24px; */
				letter-spacing: -0.44px;
			}

			.event-item {
				background: #f9f9f9;
				display: flex;
				align-items: center;
				padding: 18px 16px;
				margin-bottom: 8px;

				&:last-of-type {
					margin-bottom: 0;
				}

				&:first-of-type {
					margin-top: 32px;
				}

				.people-count {
					display: flex;
					align-items: center;
					margin-right: 60px;
					.icon {
						margin-right: 8px;
						color: ${colors.darkGrey};
					}

					.count {
						font-size: ${toRem(16)};
						color: #000000;
						${fontType(0)};
					}
				}

				.event-name {
					font-size: ${toRem(12)};
					color: ${colors.darkGrey};
					${fontType(0)};

					${ellipsis()}
				}
			}
		}
	}
`

// '#858585'
export const reactSelectStyles: StylesConfig & {
	dropdownButton?: any
} = {
	container: (styles, state) => ({
		...styles,
		width: '100%',
		boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.08)'
	}),
	menu: (styles, state) => ({
		...styles
	}),
	option: (styles, state) => ({
		...styles,
		fontFamily: 'Regular',
		background: state.isSelected ? '#EEEEEE' : 'white',
		color: state.isSelected ? '#858585' : colors.darkGrey,
		cursor: 'pointer',
		transition: 'all 0.2s',
		':hover': {
			background: '#EEEEEE',
			color: '#858585'
		},

		input: {
			// display: 'none'
		}
	}),
	menuList: (styles, state) => ({
		...styles
	}),
	singleValue: (styles, state) => ({
		...styles
	}),
	placeholder: (styles, state) => ({
		...styles
	}),
	noOptionsMessage: (styles, state) => ({
		...styles,
		fontFamily: 'Regular'
	}),
	multiValue: (styles, state) => ({
		...styles
	}),
	multiValueLabel: (styles, state) => ({
		...styles
	}),
	multiValueRemove: (styles, state) => ({
		...styles
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: (styles, state) => ({
		...styles
	}),
	control: (styles, state) => ({
		...styles,
		fontFamily: 'Regular',
		outline: 'none'
		// ':hover': {
		// 	borderColor: '#EEEEEE',
		// 	boxShadow: 'none'
		// }
	}),
	valueContainer: (styles, state) => ({
		...styles
	}),
	input: (styles, state) => ({
		...styles
	}),
	dropdownButton: (styles: any, state: any) => ({
		...styles,
		width: '100%',
		height: 57,
		padding: 16,
		fontFamily: 'Regular'
	})
}
