import {
	getCustomerCompanies,
	getMetrics
} from 'shared/services/customer.service'

import { CATEGORY_OPTIONS } from 'shared/util/Consts'
import { formatISO, startOfMonth, endOfMonth } from 'date-fns'
import { getTopEvent } from 'shared/services/event.service'
import { IGetMetricsResponse } from 'shared/interfaces/customer.service'
import { IGetTopEventResponse } from 'shared/interfaces/event.service'
import { IPlaceholderChange, IViewProps } from './types'
import { RangeModifier } from 'react-day-picker'
import { SelectOption } from '@buildbox/components'
import { useDebounce } from 'use-debounce/lib'
import Dashboard from './view'
import { createElement, useEffect, useState } from 'react'
import { handleTierSelectOptions } from 'shared/util/tierUtil'

function DashboardPageContainer(): JSX.Element {
	const [isLoadingCompanies, setLoadingCompanies] = useState(true)
	const [isLoading, setLoading] = useState(false)

	const [rangePicked, setRange] = useState<RangeModifier>({
		from: startOfMonth(new Date()),
		to: endOfMonth(new Date())
	})
	const [selectedTiers, setSelectedTiers] = useState<SelectOption[]>([])
	const [selectedCategories, setSelectedCategories] = useState<SelectOption[]>(
		CATEGORY_OPTIONS
	)
	const [selectedCompanies, setSelectedCompanies] = useState<SelectOption[]>([])

	const [tierOptions, setTierOptions] = useState<SelectOption[]>([])

	const [companyOptions, setCompanyOptions] = useState<SelectOption[]>([])

	const [topEvents, setTopEvents] = useState<IGetTopEventResponse>({
		attendees: [],
		feedback: []
	})
	const [metrics, setMetrics] = useState<IGetMetricsResponse>({
		actives: 0,
		news: 0,
		ltc: 0,
		churn: 0,
		percentChurn: '',
		bzElectionTotalListingRequests: 0,
		percentWithoutAccess: ''
	})

	const [rangeDebounce] = useDebounce(rangePicked, 1000)
	const [tiersDebounce] = useDebounce(selectedTiers, 1000)
	const [categoriesDebounce] = useDebounce(selectedCategories, 1000)
	const [companiesDebounce] = useDebounce(selectedCompanies, 1000)

	function init() {
		;(async () => {
			try {
				const tierOptions = handleTierSelectOptions()

				setTierOptions(tierOptions)
				setSelectedTiers(tierOptions)

				setLoadingCompanies(true)
				await getSetCompanies()
			} catch (error) {
			} finally {
				setLoadingCompanies(false)
			}
		})()
	}

	function fetchData() {
		if (isLoadingCompanies) return
		;(async () => {
			try {
				setLoading(true)

				await getSetEvents()
				await getSetMetrics()
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	async function getSetCompanies() {
		const companies = await getCustomerCompanies()

		const companiesOptions = companies.map((company) => ({
			label: company,
			value: company
		}))

		setCompanyOptions(companiesOptions)
		setSelectedCompanies(companiesOptions)
	}

	async function getSetEvents() {
		const tiersToSend = tiersDebounce.map((x) => x.value)
		const startDate = formatISO(rangeDebounce.from)
		const endDate = formatISO(rangeDebounce.to)

		const topEvents = await getTopEvent({
			tiers: tiersToSend,
			startDate,
			endDate
		})

		setTopEvents(topEvents)
	}

	async function getSetMetrics() {
		const tiersToSend = tiersDebounce.map((x) => x.value)
		const categoriesToSend = categoriesDebounce.map((x) => x.value)
		const companiesToSend = companiesDebounce.map((x) => x.value)
		const startDate = formatISO(rangeDebounce.from)
		const endDate = formatISO(rangeDebounce.to)

		console.log({
			startDate,
			endDate,
			tiers: tiersToSend,
			categories: categoriesToSend,
			companies: companiesToSend
		})

		const metrics = await getMetrics({
			startDate,
			endDate,
			tiers: tiersToSend,
			categories: categoriesToSend,
			companies: companiesToSend
		})

		setMetrics(metrics)
	}

	function handleTierSelect(value: SelectOption[]): void {
		setSelectedTiers(value)
	}
	function handleCategorySelect(value: SelectOption[]): void {
		setSelectedCategories(value)
	}
	function handleCompanySelect(value: SelectOption[]): void {
		setSelectedCompanies(value)
	}

	function handleDatePickerChange(value: RangeModifier): void {
		setRange(value)
	}

	function handlePlaceholder({
		placeholderButtonLabel,
		value
	}: IPlaceholderChange) {
		if (value.length === 1) {
			return value[0].label
		}

		if (value.length > 1) {
			return `${value.length} ${placeholderButtonLabel}s`
		}

		return `Selecione um ${placeholderButtonLabel}`
	}

	useEffect(fetchData, [
		rangeDebounce,
		tiersDebounce,
		categoriesDebounce,
		companiesDebounce
	])

	useEffect(init, [])

	const viewProps: IViewProps = {
		companyOptions,
		selectedTiers,
		selectedCategories,
		selectedCompanies,
		handleTierSelect,
		handleCategorySelect,
		handleCompanySelect,
		handleDatePickerChange,
		rangePicked,
		handlePlaceholder,
		isLoading,
		topEvents,
		metrics,
		isLoadingCompanies,
		tierOptions
	}
	return createElement(Dashboard, viewProps)
}

export default DashboardPageContainer
