import styled from 'styled-components'

export const ButtonWrapper = styled.span`
	cursor: pointer;
	margin-left: 5px;
`

export const IconWrapper = styled.span`
	&.active {
		.material-icons {
			color: #000000;
		}
	}
`
