import React, { PropsWithChildren, Ref } from 'react'
import { BaseProps, OrNull } from '../types'
import { ToolbarWrapper } from './styles'

type Toolbar = {
	active: boolean
	reversed: boolean
} & BaseProps

export const Toolbar = React.forwardRef(
	(
		{ className, ...props }: PropsWithChildren<BaseProps>,
		ref: OrNull<Ref<HTMLSpanElement>>
	) => <ToolbarWrapper {...props} ref={ref} />
)
