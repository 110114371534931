import {
	validateResetToken,
	changePassword
} from 'shared/services/auth.service'
import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { Creators as TokenActions } from 'shared/store/ducks/token'
import { Creators as UserActions } from 'shared/store/ducks/user'
import routesEnum from '../Routes/enum'
import { UtilValidators } from '@buildbox/utils'

import { IViewProps } from './types'
import { useDispatch } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import ChangePassword from './view'
import { IUser } from 'shared/interfaces/user'
import { successMessages } from 'shared/util/Messages'

function ChangePasswordContainer(): JSX.Element {
	const dispatch = useDispatch()
	const history = useHistory()

	const { pathname } = useLocation()
	const { token } = useParams<{ token: string }>()

	const [isFormValid, setIsFormValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [user, setUser] = useState<IUser>({
		_id: '',
		firstName: '',
		lastName: '',
		email: '',
		position: '',
		role: '',
		status: ''
	})
	const [userName, setUserName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [passwordCheck, setPasswordCheck] = useState('')
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [message, setMessage] = useState('')

	const { checkPassword, checkPasswordMatch } = UtilValidators

	function handleInit() {
		;(async () => {
			try {
				setIsLoading(true)
				const response = await validateResetToken(token)
				const { user } = response

				setUser(user)
				setEmail(user.email)
				setUserName(`${user.firstName} ${user.lastName}`)

				const splitPathname = pathname.split('/')
				splitPathname[1] === 'set-pw'
					? setMessage('Para continuar, crie uma senha de acesso ao painel.')
					: setMessage('Crie uma nova senha de acesso.')
			} catch (error) {
				history.replace(routesEnum.LOGIN)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	async function handleSubmit() {
		try {
			setIsLoading(true)

			const hasError = await handleViewError()
			if (hasError) return

			const response = await changePassword(token, password)
			const { accessToken } = response

			cogoToast.success(successMessages.passwordUpdated, cogoDefaultOptions)
			dispatch(TokenActions.setToken({ accessToken: accessToken }))
			dispatch(UserActions.setUser(user))

			history.push(routesEnum.USERS_PAGE)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handlePassword(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setPassword(value)
	}

	function handlePasswordCheck(event: ChangeEvent<HTMLInputElement>) {
		const { value } = event.target
		setPasswordCheck(value)
	}

	async function handleViewError() {
		const errors: string[] = []

		if (!checkPassword(password)) {
			errors.push('Senha não preenchida e/ou inválida')
		}

		if (!checkPasswordMatch(password, passwordCheck)) {
			errors.push('Senhas não são iguais')
		}

		setViewErrors(errors)
		return errors.length
	}

	useEffect(() => {
		const isPasswordValid = password.length !== 0
		const isPasswordChecked = passwordCheck.length !== 0

		const result = isPasswordValid && isPasswordChecked

		setIsFormValid(result)
	}, [email, password, passwordCheck])

	useEffect(handleInit, [])

	const viewProps: IViewProps = {
		handlePassword,
		handlePasswordCheck,
		email,
		password,
		passwordCheck,
		handleSubmit,
		userName,
		isLoading,
		isFormValid,
		viewErrors,
		message
	}

	return createElement(ChangePassword, viewProps)
}

export default ChangePasswordContainer
