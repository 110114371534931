import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps, SelectOption } from '@buildbox/components'
import { useDebounce } from 'use-debounce/lib'
import {
	CustomerStatusEnum,
	ICritic,
	ICustomer,
	ICustomersPage
} from 'shared/interfaces/customer'
import {
	exportUsers,
	fetchCustomerPage
} from 'shared/services/customer.service'
import CustomerList from './view'
import { ColorStatus } from './styles'
import { translateStatus } from 'shared/util/translate'
import { ITableRow, IViewProps, ITableColumn } from './types'
import { backScrollBody } from 'shared/util/scroll'
import { handleTierSelectOptions } from 'shared/util/tierUtil'

const CUSTOMERS_PER_PAGE = 10

const customerFilterOptions: SelectOption[] = [
	{ value: 'All', label: 'Todos' },
	{ value: 'Approval', label: 'Aprovação (Pendentes)' },
	{ value: 'Renew', label: 'Renovação (Expirados e Inativos)' },
	{ value: 'Active', label: 'Ativos' }
]

function CustomerListContainer(): JSX.Element {
	// -------------------------- STATES ---------------------------------------
	const [
		selectedCustomerFilter,
		setSelectedCustomerFilter
	] = useState<SelectOption>({ value: 'All', label: 'Todos' })

	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)

	const [customersPage, setCustomersPage] = useState<ICustomersPage>({
		pageIndex: 1,
		numberOfPages: 0,
		totalDocs: 0,
		pageContent: []
	})

	const [isLoading, setIsLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [customerEdit, setCustomerEdit] = useState<ICustomer>({} as ICustomer)
	const [reportErrorModal, setReportErrorModal] = useState(false)
	const [errorsValue, setErrorsValue] = useState<ICritic[]>()
	const [tableRows, setTableRows] = useState<ITableRow[]>([])

	const [tierOptions, setTierOptions] = useState<SelectOption[]>([])

	// -------------------------- FUNCTIONS -------------------------------------
	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleSearchDebounce() {
		getCustomersPage(1, searchDebounce)
	}

	useEffect(handleSearchDebounce, [searchDebounce])

	async function getCustomersPage(pageIndex: number, searchString = '') {
		try {
			setIsLoading(true)

			const currentTierOptions = handleTierSelectOptions()

			setTierOptions(currentTierOptions)

			const pageData = await fetchCustomerPage(
				pageIndex,
				CUSTOMERS_PER_PAGE,
				translateCustomerFilterToStatus(),
				searchString
			)
			setCustomersPage(pageData)
			// setReportErrorModal(true)
		} catch (err) {
		} finally {
			setIsLoading(false)
		}
	}

	function translateCustomerFilterToStatus(): CustomerStatusEnum[] {
		if (selectedCustomerFilter?.value === 'Approval') return ['PENDING']
		if (selectedCustomerFilter?.value === 'Renew')
			return ['INACTIVE', 'EXPIRED']
		if (selectedCustomerFilter?.value === 'Active') return ['ACTIVE']

		// Default option AND when selectedCustomerFilter?.value === 'All'
		return ['PENDING', 'ACTIVE', 'INACTIVE', 'EXPIRED']
	}

	function handleCustomerFilterChange(selectedOption: any) {
		if (!selectedOption) return
		setSelectedCustomerFilter(selectedOption)
	}

	useEffect(() => {
		getCustomersPage(1, search)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCustomerFilter])

	async function updateCustomerPageAfterModalClosed() {
		await getCustomersPage(customersPage.pageIndex, search)
		backScrollBody()
	}

	function handleShowModal(customer: ICustomer) {
		setCustomerEdit(customer)
		setShowModal(!showModal)
	}

	function renderFileTextIcon(customer: ICustomer): JSX.Element {
		return (
			<button className='icon' onClick={() => handleShowModal(customer)}>
				<img
					className='img'
					src={require('../../assets/images/file-text.svg')}
					alt='Editar'
				/>
			</button>
		)
	}

	function renderStatus(customer: ICustomer): JSX.Element {
		return (
			<ColorStatus color={customer.status}>
				{translateStatus(customer.status)}
			</ColorStatus>
		)
	}

	function brazilElectionData(brazilElectionContentAccess: boolean) {
		return brazilElectionContentAccess ? 'sim' : 'não'
	}

	function updateTable() {
		const rows = customersPage.pageContent.map((customer) => {
			return {
				status: renderStatus(customer),
				firstName: customer.firstName,
				lastName: customer.lastName,
				email: customer.email,
				company: customer.company,
				electionAccess: brazilElectionData(
					customer.brazilElectionContentAccess
				),
				fileTextIcon: renderFileTextIcon(customer)
			}
		})

		setTableRows(rows)
	}

	async function dowloadCustomers() {
		await exportUsers()
	}

	function onCloseModal() {
		setReportErrorModal(!reportErrorModal)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: 'Status', accessor: 'status', sortType: 'basic' },
		{ Header: 'Nome', accessor: 'firstName', sortType: 'basic' },
		{ Header: 'Sobrenome', accessor: 'lastName', sortType: 'basic' },
		{ Header: 'Email', accessor: 'email', sortType: 'basic' },
		{ Header: 'Empresa', accessor: 'company', sortType: 'basic' },
		{
			Header: 'Acesso BZ Elections',
			accessor: 'electionAccess',
			sortType: 'basic'
		},
		{
			Header: '',
			accessor: 'fileTextIcon',
			disableSortBy: true
		}
	]

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => getCustomersPage(pageIndex + 1, search),
		previousPage: (pageIndex) => getCustomersPage(pageIndex + 1, search),
		gotoPage: (pageIndex) => getCustomersPage(pageIndex + 1, search),
		pageCount: customersPage.numberOfPages,
		pageIndex: customersPage.pageIndex - 1,
		totalDocs: customersPage.totalDocs
	}

	useEffect(() => {
		updateTable()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customersPage])

	const viewProps: IViewProps = {
		customerFilterOptions,
		selectedCustomerFilter,
		handleCustomerFilterChange,
		tableColumns,
		tableRows,
		showModal,
		isLoading,
		handleShowModal,
		customerEdit,
		navProps,
		customersPerPage: CUSTOMERS_PER_PAGE,
		setShowModal,
		updateCustomerPageAfterModalClosed,
		handleSearch,
		search,
		dowloadCustomers,
		setIsLoading,
		reportErrorModal,
		onCloseModal,
		setReportErrorModal,
		errorsValue,
		setErrorsValue,
		getCustomersPage,
		tierOptions
	}

	return createElement(CustomerList, viewProps)
}

export default CustomerListContainer
