import {
	IReport,
	IReportPayload,
	IReportsPage,
	TypeSpeechEnum
} from 'shared/interfaces/report'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { INITIAL_BODY_VALUE } from 'shared/util/Consts'
import axiosInstance from './axiosInstance'

export function initializeReport(): IReport {
	const report: IReport = {
		_id: '',
		title: '',
		subtitle: '',
		category: 'POLITICS_MACRO_STRATEGY',
		author: {
			_id: '',
			firstName: '',
			lastName: '',
			position: ''
		},
		createdBy: '',
		notificate: false,
		emailNotificate: false,
		body: JSON.stringify(INITIAL_BODY_VALUE),
		status: 'ACTIVE',
		languages: [],
		coverImage: '',
		videoLink: '',
		isPublic: false,
		attachments: [],
		webPageURL: ''
	}

	return report
}

export async function createReport(report: IReportPayload): Promise<IReport> {
	const response = await axiosInstance.post('/report', report)

	return response.data.content
}

export async function updateReport(
	reportId: string,
	report: IReportPayload
): Promise<IReport> {
	const response = await axiosInstance.patch('/report/' + reportId, report)

	return response.data.content
}

export async function deleteReport(reportId: string): Promise<IReport> {
	const response = await axiosInstance.delete('/report/' + reportId)

	return response.data.content
}

export async function getReportCategories() {
	const response = await axiosInstance.get('/report/')

	return response.data.content
}

interface ISpeechCategory {
	candidates: string[]
	categories: any[]
	types: TypeSpeechEnum[]
}

export async function getReportSpeechCategories(): Promise<ISpeechCategory> {
	const response = await axiosInstance.get('/report/categories/speech')

	return response.data.content
}

export async function getReportList() {
	const response = await axiosInstance.get('/report/export/content', {
		responseType: 'blob'
	})

	const fileName = `latest_charge_${format(
		new Date(),
		"dd-MM-yyyy'T'HH:mm:ss"
	)}.xlsx`

	fileDownload(response.data, fileName)
}

export async function fetchReportsPage(
	pageIndex: number,
	maxItemsInPage: number,
	searchString: string
): Promise<IReportsPage> {
	const response = await axiosInstance.post('/report/get-page', {
		pageIndex,
		maxItemsInPage,
		searchString
	})

	return response.data.content
}

export async function getReportSubcategories(category: string) {
	const response = await axiosInstance.get(`/report/subcategories/${category}`)

	return response.data.content
}
