import { fontType } from 'shared/styles/functions'
import styled, { css } from 'styled-components'
import { IOptionsProps, SwitchLabel } from './types'

export const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;

	input {
		display: none;
	}
`

export const PrimaryLabel = styled.label<IOptionsProps>`
	padding: 6px 16px;
	border-radius: 13px;
	cursor: pointer;
	border: none;
	background: #ffffff;

	${fontType(1)}
	font-size: 12px;

	${(props) =>
		props.isActive &&
		css`
			background-color: #ffc70952;
		`};

	${(props) =>
		props.option === SwitchLabel.PORTUGUESE &&
		css`
			margin-left: 12px;
		`}
`

export const OptionWrapper = styled.button<IOptionsProps>`
	padding: 6px 16px;
	border-radius: 13px;
	cursor: pointer;
	border: none;
	background: #ffffff;

	${(props) =>
		props.isActive &&
		css`
			background-color: #ffc70952;
		`};

	${(props) =>
		props.option === SwitchLabel.PORTUGUESE &&
		css`
			margin-left: 12px;
		`}
`
