import React from 'react'
import { FloatLabelInput, Loading, Modal } from '@buildbox/components'
import {
	colors,
	primaryButtonStyle,
	primaryInputStyle
} from 'shared/styles/theme'
import { IViewProps } from './types'
import {
	ModalButton,
	ModalUserEditWrapper,
	ModalSwitch,
	LoadingContent
} from './styles'
import Switch from 'shared/components/Switch'

function UserEdit(props: IViewProps): JSX.Element {
	const {
		show,
		hideModal,
		data,
		handleSubmit,
		filledData,
		email,
		firstName,
		handleRoleChange,
		lastName,
		position,
		setEmail,
		setFirstName,
		setLastName,
		setPosition,
		populateDataUser,
		isLoading,
		isFormValid
	} = props

	return (
		<Modal
			className='modal-primary'
			title='Editar Usuário'
			size={372}
			isActive={show}
			onClose={() => {
				populateDataUser()
				hideModal()
			}}
			scrollRef='scroll-ref'
		>
			<ModalUserEditWrapper onSubmit={handleSubmit}>
				<FloatLabelInput
					className='modal-input'
					id='first-name'
					label='Nome'
					value={firstName}
					onChange={({ target }) => setFirstName(target.value)}
					maxLength={40}
					{...primaryInputStyle}
				/>

				<FloatLabelInput
					className='modal-input'
					id='last-name'
					label='Sobrenome'
					value={lastName}
					onChange={({ target }) => setLastName(target.value)}
					maxLength={40}
					{...primaryInputStyle}
				/>

				<FloatLabelInput
					className='modal-input'
					id='email'
					label='E-mail'
					value={email}
					onChange={({ target }) => setEmail(target.value)}
					maxLength={80}
					{...primaryInputStyle}
				/>

				<FloatLabelInput
					className='modal-input'
					id='position'
					label='Cargo'
					value={position}
					onChange={({ target }) => setPosition(target.value)}
					maxLength={32}
					{...primaryInputStyle}
				/>

				<ModalSwitch>
					{show && (
						<Switch
							label='Administrador?'
							isChecked={data.role === 'ADMIN' ? true : false}
							onChange={handleRoleChange}
						/>
					)}
				</ModalSwitch>

				{isLoading ? (
					<LoadingContent>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</LoadingContent>
				) : (
					<ModalButton
						className='modal-button'
						disabled={!filledData || !isFormValid}
						onClick={handleSubmit}
						{...primaryButtonStyle}
					>
						{'SALVAR'}
					</ModalButton>
				)}
			</ModalUserEditWrapper>
		</Modal>
	)
}

export default UserEdit
