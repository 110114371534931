import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { ITrendsPage, ITrend, ITrendsPayload } from 'shared/interfaces/trend'
import { INITIAL_BODY_VALUE } from 'shared/util/Consts'
import axiosInstance from './axiosInstance'

export function initializeTrend(): ITrend {
	const trend: ITrend = {
		_id: '',
		title: '',
		subtitle: '',
		category: 'POLITICS',
		author: '',
		createdBy: '',
		notificate: false,
		emailNotificate: false,
		videoLink: '',
		languages: [],
		body: JSON.stringify(INITIAL_BODY_VALUE),
		status: 'ACTIVE',
		coverImage: '',
		isPublic: false,
		attachments: []
	}

	return trend
}

export async function createTrend(trend: ITrendsPayload): Promise<ITrend> {
	const response = await axiosInstance.post('/trend', trend)
	return response.data.content
}

export async function updateTrend(
	trendId: string,
	trend: ITrendsPayload
): Promise<ITrend> {
	const response = await axiosInstance.patch('/trend/' + trendId, trend)

	return response.data.content
}

export async function deleteTrend(trendId: string): Promise<ITrend> {
	const response = await axiosInstance.delete('/trend/' + trendId)

	return response.data.content
}

export async function fetchTrendsPage(
	pageIndex: number,
	maxItemsPage: number,
	category: string[],
	searchString = ''
): Promise<ITrendsPage> {
	const response = await axiosInstance.post('/trend/get-page', {
		pageIndex: pageIndex,
		maxItemsInPage: maxItemsPage,
		categories: category,
		searchString
	})

	return response.data.content
}

export async function getTrendList() {
	const response = await axiosInstance.get('/trend/export/content', {
		responseType: 'blob'
	})

	const fileName = `hot_topics_${format(
		new Date(),
		"dd-MM-yyyy'T'HH:mm:ss"
	)}.xlsx`

	fileDownload(response.data, fileName)
}
