import styled from 'styled-components'

export const CheckBoxVariationWrapper = styled.div`
	input {
		display: none;
	}
`

export const CheckBoxButton = styled.label`
	cursor: pointer;

	img {
		width: 15px;
		height: 15px;
	}
`
