import React from 'react'
import { HeaderWrapper } from './styles'
import { IProps } from './types'
import { MenuIcon } from '@buildbox/components'

export default function Header({
	isOpen,
	handleSetIsOpen,
	name
}: IProps): JSX.Element {
	return (
		<HeaderWrapper className={isOpen ? 'active' : ''}>
			<div className='menu-content'>
				<MenuIcon onClick={handleSetIsOpen} isActive={isOpen} />
			</div>
			<div className='xp-logo'>
				<img src={require('assets/images/xplogo-header.svg')} alt='XP Logo' />
			</div>

			<div className='placeholder-spacer'>
				<span>{name}</span>
				<div className='user-circle'>
					<img src={require('assets/images/user.svg')} alt='Avatar' />
				</div>
			</div>
		</HeaderWrapper>
	)
}
