export function defineModalTitle(action: string): string {
	switch (action) {
		case 'CREATE':
			return 'Novo Latest'
		case 'EDIT':
			return 'Detalhes do Latest'
		default:
			return 'Excluir Latest'
	}
}

export function defineModalButtonName(action: string): string {
	switch (action) {
		case 'CREATE':
			return 'PUBLICAR'
		case 'EDIT':
			return 'SALVAR ALTERAÇÕES'
		default:
			return 'EXCLUIR'
	}
}
