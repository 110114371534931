import React from 'react'
import CheckBox from 'shared/components/CheckBoxVariation'
import {
    BZElectionCategory,
    LineSeparator,
    InputCheckBoxContainer,
    SectionContentWrapper,
    BZElectionCategoryTitle,
    MainBZElectionAccessSection,
    BzElectionSettingsContainer
} from './styles'
import { IProps } from './types'

export function SectionBZElections({
    BZElectionAccess,
    handleElectionAccess,
    delayTimeInMacroPolitical,
    handleDelayMinuteChange,
    isFixed,
    handleIsFixed
}: IProps): JSX.Element {
    return (
        <MainBZElectionAccessSection>
            <BZElectionCategoryTitle>
                Sessão BZ Elections 2022
            </BZElectionCategoryTitle>

            <LineSeparator />

            <BzElectionSettingsContainer>
                <BZElectionCategory>
                    <InputCheckBoxContainer>
                        <CheckBox
                            checked={BZElectionAccess ? BZElectionAccess : false}
                            handleClick={handleElectionAccess}
                        />
                        <p>Replicar este conteúdo para sessão Macro Political</p>
                    </InputCheckBoxContainer>

                    <InputCheckBoxContainer>
                        <CheckBox
                            id="is-fixed-checkbox"
                            checked={isFixed}
                            handleClick={handleIsFixed}
                        />
                        <p>Fixar este conteúdo na seção BZ Elections 2022</p>
                    </InputCheckBoxContainer>
                </BZElectionCategory>

                {BZElectionAccess && (
                    <SectionContentWrapper>
                        <p>Delay da Notícia (Min)</p>

                        <input
                            type='number'
                            onChange={handleDelayMinuteChange}
                            value={delayTimeInMacroPolitical}
                        />
                    </SectionContentWrapper>
                )}
            </BzElectionSettingsContainer>
        </MainBZElectionAccessSection>
    )
}
