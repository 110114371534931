import React from 'react'
import { IViewProps } from './types'
import {
	Button,
	FloatLabelInput,
	Loading,
	Modal,
	Select
} from '@buildbox/components'
import {
	primaryInputStyle,
	colors,
	primaryButtonStyle
} from 'shared/styles/theme'
import { ColorStatus } from '../CustomersPage/styles'
import {
	ModalCustomerEditWrapper,
	FormContainer,
	ModalContainer,
	StatusCustomer,
	ModalSelect,
	BtnSubmit,
	LineSeparator,
	InputCheckBoxContainer,
	BZElectionTitle
} from './styles'
import { translateStatus } from 'shared/util/translate'
import CheckBox from 'shared/components/CheckBoxVariation'

function CustomerEdit(props: IViewProps): JSX.Element {
	const {
		hideModal,
		show,
		data,
		handleSubmit,
		statusSelection,
		filledData,
		newActionOptionSelected,
		isLoading,
		handleSelectCategory,
		handleSelectTier,
		handlerSelectNewAction,
		tierOptions,
		selectedCategory,
		selectedTier,
		categoryOptions,
		clearModal,
		handleChangeCompany,
		company,
		handleBrazilElection,
		brazilElectionContentAccess
	} = props

	return (
		<ModalContainer>
			<Modal
				className='modal-primary'
				title='Detalhes do Cliente'
				size={752}
				isActive={show}
				onClose={() => {
					hideModal()
					clearModal()
				}}
				scrollRef='scroll-ref'
			>
				<ModalCustomerEditWrapper onSubmit={handleSubmit}>
					<FormContainer>
						<StatusCustomer>
							<p>Status Atual</p>
							<ColorStatus color={data.status}>
								{translateStatus(data.status)}
							</ColorStatus>
						</StatusCustomer>

						<ModalSelect>
							<Select
								id='status'
								className='modal-input'
								placeholder='Ação'
								value={newActionOptionSelected}
								onChange={handlerSelectNewAction}
								options={statusSelection.map((item) => ({
									value: item,
									label: item
								}))}
								{...{ fontSize: 14 }}
							/>
						</ModalSelect>
					</FormContainer>
					<BZElectionTitle>Sessão BZ Elections 2022</BZElectionTitle>
					<LineSeparator />
					<InputCheckBoxContainer>
						<CheckBox
							checked={brazilElectionContentAccess}
							handleClick={handleBrazilElection}
						/>
						<p>Usuário com acesso à tela BZ Elections 2022</p>
					</InputCheckBoxContainer>
					<FormContainer>
						<FloatLabelInput
							disabled
							className='modal-input'
							id='firstName'
							label='Nome'
							value={data.firstName}
							{...primaryInputStyle}
						/>

						<FloatLabelInput
							disabled
							className='modal-input'
							id='lastName'
							label='Sobrenome'
							value={data.lastName}
							{...primaryInputStyle}
						/>

						<FloatLabelInput
							className='modal-input'
							id='company'
							label='Empresa'
							value={company}
							maxLength={32}
							onChange={(e) => handleChangeCompany(e.target.value)}
							{...primaryInputStyle}
						/>

						<FloatLabelInput
							disabled
							className='modal-input'
							id='email'
							label='E-mail'
							value={data.email}
							{...primaryInputStyle}
						/>

						<FloatLabelInput
							disabled
							className='modal-input'
							id='telephone'
							label='Telefone'
							value={data.phoneNumber || ''}
							{...primaryInputStyle}
						/>

						<ModalSelect>
							<Select
								className='modal-input'
								options={tierOptions}
								value={selectedTier}
								onChange={handleSelectTier}
								placeholder={'Tier'}
								{...{ padding: [6, 6, 6, 6], fontSize: 14 }}
							/>
						</ModalSelect>
						<div></div>
						<ModalSelect>
							<Select
								id='category'
								className='modal-input'
								options={categoryOptions}
								value={selectedCategory}
								onChange={handleSelectCategory}
								placeholder={'Categoria'}
								{...{ padding: [6, 6, 6, 6], fontSize: 14 }}
							/>
						</ModalSelect>
					</FormContainer>

					<BtnSubmit>
						{isLoading ? (
							<Loading type='Sentry' primaryColor={colors.secondary} />
						) : (
							<Button
								className='btn-submit'
								{...primaryButtonStyle}
								disabled={!filledData}
							>
								SALVAR
							</Button>
						)}
					</BtnSubmit>
				</ModalCustomerEditWrapper>
			</Modal>
		</ModalContainer>
	)
}

export default CustomerEdit
