import { Action, combineReducers, Reducer, ReducersMapObject } from 'redux'
import { IActionsCreators } from '../../interfaces/action'
import { IStore } from '../../interfaces/store'
import token from './token'
import user from './user'

const reducers: ReducersMapObject<IStore, Action<IActionsCreators>> = {
	user,
	token
}

export const Reducers: Reducer<
	IStore,
	Action<IActionsCreators>
> = combineReducers(reducers)
