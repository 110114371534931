import { IViewProps } from './types'
import { LoginWrapper } from './styles'
import React from 'react'
import ForgotPasswordText from 'shared/components/ForgotPasswordText'

import { Button, FloatLabelInput, Loading } from '@buildbox/components'
import InputPassword from 'shared/components/InputPassword'
import { primaryInputStyle, primaryButtonStyle } from 'shared/styles/theme'

function Login(props: IViewProps): JSX.Element {
	const { handleLogin, handleChange, userLogin, isFormValid, isLoading } = props

	return (
		<>
			<LoginWrapper>
				<div className='background-wrapper'>
					<img
						className='logo-wrapper'
						src={require('assets/images/xplogo-login.svg')}
						alt='Logo XP Investimentos'
					/>
					<form
						onSubmit={(event) => {
							event.preventDefault()
							handleLogin()
						}}
					>
						<div className='inputs-wrapper'>
							<div className='header'></div>
							<div className='fields'>
								<FloatLabelInput
									className='input'
									label='E-mail'
									id='email'
									value={userLogin.email}
									onChange={handleChange}
									{...primaryInputStyle}
								/>
								<InputPassword
									className='input'
									label='Senha'
									id='password'
									value={userLogin.password}
									onChange={handleChange}
									{...primaryInputStyle}
								/>

								{isLoading ? (
									<Loading type='Sentry' primaryColor='#ffc709' />
								) : (
									<Button
										className='button'
										id='submit-button'
										disabled={!isFormValid}
										{...primaryButtonStyle}
										type='submit'
									>
										{'ENTRAR'}
									</Button>
								)}

								<ForgotPasswordText type='EMAIL' />
							</div>
						</div>
					</form>
				</div>
			</LoginWrapper>
		</>
	)
}

export default Login
