import { fontType, toRem, transition } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'
import { Row } from 'shared/styles'

export const ModalEventWrapper = styled.div`
	${fontType(0)}

	.modal-wrapper {
		background: ${colors.pageBackground};
	}

	.export-button {
		display: flex;
		justify-content: center;
		margin-top: 32px;
		margin-bottom: 32px;
		.button {
		}
	}

	.placeholder-wrapper {
		margin-top: 0px;
		height: 400px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* TODO: Create a component to have always same style */
	.tabs {
		.react-tabs__tab-list {
			list-style: none;
			display: flex;
			.react-tabs__tab {
				position: relative;
				padding: 16px 32px 16px 12px;
				border-radius: 4px;
				background: ${colors.backgroundState};
				color: ${colors.darkGrey};
				font-size: ${toRem(14)};
				${fontType(0)};
				cursor: pointer;
				margin-right: 2px;
				${transition()};

				&:last-of-type {
					margin-right: 2px;
				}

				&.react-tabs__tab--selected {
					background: ${colors.white};
					color: ${colors.darkGrey};

					&::after {
						position: absolute;
						bottom: 0;
						left: 12px;
						content: '';
						height: 1px;
						width: 64px;
						background: ${colors.secondary};
					}
				}
			}
		}
		.react-tabs__tab-panel {
			background: ${colors.white};
			padding: 16px;
			display: none;

			&.react-tabs__tab-panel--selected {
				display: block;
			}
		}
	}

	@media (max-width: 600px) {
		.modal-wrapper {
			padding: 32px 16px;
		}
	}
`

export const EventFormWrapper = styled.div`
	.upper-section {
		.input {
			margin-bottom: 16px;
		}

		.select {
			margin-bottom: 16px;
		}
	}

	.text-editor-area {
		padding: 12px;
		border: 1px solid ${colors.grey};
		color: ${colors.darkGrey};
		margin-bottom: 16px;
	}

	.list-pdfs-files {
		margin-bottom: 16px;
	}

	.switches-label {
		font-size: 14px;
		color: ${colors.darkGrey};
		margin-right: 48px;
	}

	.submit-button {
		display: flex;
		justify-content: center;
	}

	.loading-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

export const InputRow = styled(Row)``

export const SwitchWrapper = styled.div`
	margin-bottom: 15px;
`
