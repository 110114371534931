import { ITiersEnum, ITierTimerProps } from 'shared/interfaces/tierTimer'
import axiosInstance from './axiosInstance'

export async function readTiers(): Promise<ITierTimerProps[]> {
	const response = await axiosInstance.get('/tier')

	return response.data.data
}

export async function updateTier(
	name: ITiersEnum,
	delayInMinutes: number
): Promise<ITierTimerProps> {
	const response = await axiosInstance.put('/tier', { name, delayInMinutes })

	return response.data.data
}
