import React from 'react'
import { Loading, Table, Select, Button } from '@buildbox/components'

import PageTitle from 'shared/components/PageTitle'
import { CustomersInput, CustomersListWrapper, LoadingContent } from './styles'
import {
	colors,
	tableStyle,
	selectStyle,
	primaryButtonStyle
} from 'shared/styles/theme'
import ModalCustomerEditContainer from 'modules/ModalCustomerEdit'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import InputSearch from 'shared/components/InputSearch'
import { IViewProps } from './types'
import { backScrollBody } from 'shared/util/scroll'
import { UploadCustomersInput } from 'shared/components/UploadCustomersButton'
import ReportErrorModal from 'shared/components/ReportErrorModal'

function CustomersList(props: IViewProps): JSX.Element {
	const {
		customerFilterOptions,
		selectedCustomerFilter,
		handleCustomerFilterChange,
		tableRows,
		tableColumns,
		showModal,
		handleShowModal,
		customerEdit,
		navProps,
		customersPerPage,
		isLoading,
		setShowModal,
		updateCustomerPageAfterModalClosed,
		handleSearch,
		search,
		dowloadCustomers,
		setIsLoading,
		reportErrorModal,
		onCloseModal,
		errorsValue,
		setErrorsValue,
		getCustomersPage,
		tierOptions
	} = props

	return (
		<>
			<CustomersListWrapper>
				<div className='page-header'>
					<PageTitle>Clientes</PageTitle>
					<div className='inputs-wrapper'>
						<InputSearch
							id='search'
							label='Buscar cliente'
							onChange={(e) => handleSearch(e.target.value)}
							value={search}
						/>

						<Select
							id='customer-filter'
							label='Filtro de clientes'
							isDisabled={false}
							options={customerFilterOptions}
							placeholder={'Selecione um filtro'}
							value={selectedCustomerFilter}
							onChange={handleCustomerFilterChange}
							{...selectStyle}
							className='select'
						/>
					</div>
				</div>

				<CustomersInput>
					<UploadCustomersInput
						loading={setIsLoading}
						openModal={onCloseModal}
						setErrorsValue={setErrorsValue}
						getCustomersPage={getCustomersPage}
					/>

					<Button
						id='upload-button'
						onClick={dowloadCustomers}
						{...primaryButtonStyle}
					>
						Download de Clientes
					</Button>
				</CustomersInput>

				<div>
					{isLoading ? (
						<LoadingContent>
							<Loading type='Sentry' primaryColor={colors.secondary} />
						</LoadingContent>
					) : (
						<div>
							{tableRows.length ? (
								<Table
									// Workaround while we need to fix columns type on lib
									columns={tableColumns as any}
									data={tableRows}
									navProps={navProps}
									pageSize={customersPerPage}
									{...tableStyle}
									padding={[22, 16, 22, 16]}
								/>
							) : (
								<DefaultPagePlaceholder text='No momento, não há Clientes para serem exibidos.' />
							)}
						</div>
					)}
				</div>
			</CustomersListWrapper>

			<ModalCustomerEditContainer
				show={showModal}
				handleAfterClose={updateCustomerPageAfterModalClosed}
				hideModal={() => {
					handleShowModal(customerEdit)
					backScrollBody()
				}}
				tierOptions={tierOptions}
				data={customerEdit}
				setShowModal={setShowModal}
			/>
			<ReportErrorModal
				isActive={reportErrorModal}
				onClose={onCloseModal}
				value={errorsValue}
			/>
		</>
	)
}
export default CustomersList
