import React from 'react'
import { Link } from 'react-router-dom'
//import ModalRequestChangePassword from '../ModalRequestChangePassword'
import { ForgotPasswordWrapper } from './styles'
import { IForgotPasswordTextProps } from './types'

function ForgotPasswordText({ type }: IForgotPasswordTextProps) {

	return (
		<ForgotPasswordWrapper>
			<span className='forgot-password'>
				Esqueceu a senha?
				<Link to="forgot-pw" className='click-here'>
					clique aqui
				</Link>
				{/* <span
					onClick={handleRequestChangePasswordModalState}
					className='click-here'
				>
					clique aqui
				</span> */}
			</span>
		</ForgotPasswordWrapper>
	)
}

export default ForgotPasswordText
