import React, { createElement, useEffect, useState } from 'react'
import { TableNavProps } from '@buildbox/components'
import { TrendCategoryValues } from 'shared/util/Consts'
import { ITrend, ITrendsPage } from 'shared/interfaces/trend'
import { ModalActionsEnum } from 'modules/ModalTrend/types'
import {
	fetchTrendsPage,
	getTrendList,
	initializeTrend
} from 'shared/services/trend.service'
import { ITableColumn, ITableRow, IViewProps } from './types'
import TrendsPage from './view'
import { TableImagePlaceholder } from './styles'
import { Image } from 'react-feather'
import { useDebounce } from 'use-debounce/lib'
import { backScrollBody } from 'shared/util/scroll'
import { mapCategoryContent } from '../../shared/util/translate'

const TRENDS_PER_PAGE = 10
const TREND_CATEGORIES = TrendCategoryValues

function TrendsPageContainer(): JSX.Element {
	const [trendsPage, setTrendsPage] = useState<ITrendsPage>({
		pageIndex: 1,
		numberOfPages: 0,
		totalDocs: 0,
		pageContent: []
	})
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [showModalTrend, setShowModalTrend] = useState(false)
	const [modalAction, setModalAction] = useState<ModalActionsEnum>('CREATE')
	const [currentTrend, setCurrentTrend] = useState<ITrend>(initializeTrend())
	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)

	const [isDowloadCharge, setIsDowloadCharge] = useState(false)

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleSearchDebounce() {
		getTrendsPage(1, searchDebounce)
	}

	useEffect(handleSearchDebounce, [searchDebounce])

	async function handleReloadTrendsPage() {
		getTrendsPage(trendsPage.pageIndex, search)
	}

	async function handleDeleteTrendPage() {
		if (trendsPage.pageContent.length === 1 && trendsPage.pageIndex > 1) {
			await getTrendsPage(trendsPage.pageIndex - 1, search)
			return
		}

		await getTrendsPage(trendsPage.pageIndex, search)
	}

	// pageIndex starts in 0. This function is called by Table component
	async function getTrendsPage(pageIndex: number, searchString = '') {
		try {
			setIsLoading(true)
			const pageData = await fetchTrendsPage(
				pageIndex,
				TRENDS_PER_PAGE,
				TREND_CATEGORIES,
				searchString
			)
			setTrendsPage(pageData)
			handleRefetch(pageIndex, pageData.pageContent)

			const rows = pageData.pageContent.map((trend) => {
				return {
					image: renderTrendImage(trend.coverImage),
					title: renderTitle(trend.title),
					subtitle: renderSubtitle(trend.subtitle),
					category: renderCategory(trend.category),
					editIcon: renderEditIcon(trend),
					trashIcon: renderTrashIcon(trend)
				}
			})

			setTableRows(rows)
		} catch (err) {
			handleRefetch(trendsPage.pageIndex, trendsPage.pageContent)
		} finally {
			setIsLoading(false)
		}
	}

	function handleRefetch(pageIndex: number, arr: any[]) {
		const isEmpty = !!!arr.length
		const notFirstPage = pageIndex !== 1

		if (isEmpty && notFirstPage) {
			getTrendsPage(1, search)
		}
	}

	function renderTitle(title: string): JSX.Element {
		return <p className='report-title'>{title}</p>
	}

	function renderSubtitle(subtitle: string | undefined): JSX.Element {
		return <p className='trend-subtitle'>{subtitle}</p>
	}

	// TODO: Improve this code
	function renderCategory(category: string): JSX.Element {
		const words = mapCategoryContent(category)

		return <span>{words}</span>
	}

	function renderTrendImage(localImage: string | undefined): JSX.Element {
		return (
			<div className='trend-image-container'>
				{localImage ? (
					<img className='trend-image' src={localImage} alt='' />
				) : (
					<TableImagePlaceholder>
						<Image className='placeholder-icon' />
					</TableImagePlaceholder>
				)}
			</div>
		)
	}

	function renderEditIcon(trend: ITrend): JSX.Element {
		return (
			<button
				className='icon'
				onClick={() => activateModalTrend('EDIT', trend)}
			>
				<img
					className='icon-image'
					src={require('../../assets/images/file-text.svg')}
					alt='Editar'
				/>
			</button>
		)
	}

	function renderTrashIcon(trend: ITrend): JSX.Element {
		return (
			<button
				className='icon'
				onClick={() => activateModalTrend('DELETE', trend)}
			>
				<img
					className='icon-image'
					src={require('../../assets/images/trash.svg')}
					alt='Excluir'
				/>
			</button>
		)
	}

	function handleCreateTrend() {
		activateModalTrend('CREATE', initializeTrend())
	}

	function activateModalTrend(action: ModalActionsEnum, trend: ITrend) {
		setCurrentTrend(trend)
		setModalAction(action)
		setShowModalTrend(true)
	}

	function handleHideModalTrend() {
		setShowModalTrend(false)
		backScrollBody()
	}

	async function handleFetchDowload() {
		setIsDowloadCharge(true)

		await getTrendList()

		setIsDowloadCharge(false)
	}

	const tableColumns: ITableColumn[] = [
		{ Header: '', accessor: 'image', disableSortBy: true },
		{ Header: 'Título', accessor: 'title', sortType: 'basic' },
		{ Header: 'Subtítulo', accessor: 'subtitle', sortType: 'basic' },
		{ Header: 'Categoria', accessor: 'category', sortType: 'basic' },
		{
			Header: '',
			accessor: 'editIcon',
			disableSortBy: true
		},
		{
			Header: '',
			accessor: 'trashIcon',
			disableSortBy: true
		}
	]

	const navProps: TableNavProps = {
		nextPage: (pageIndex) => getTrendsPage(pageIndex + 1, search),
		previousPage: (pageIndex) => getTrendsPage(pageIndex + 1, search),
		gotoPage: (pageIndex) => getTrendsPage(pageIndex + 1, search),
		pageCount: trendsPage.numberOfPages,
		pageIndex: trendsPage.pageIndex - 1,
		totalDocs: trendsPage.totalDocs
	}

	const viewProps: IViewProps = {
		tableColumns,
		tableRows,
		trendsPerPage: TRENDS_PER_PAGE,
		navProps,
		isLoading,
		showModalTrend,
		modalAction,
		currentTrend,
		handleCreateTrend,
		handleHideModalTrend,
		handleReloadTrendsPage,
		handleDeleteTrendPage,
		handleSearch,
		search,
		handleFetchDowload,
		isDowloadCharge
	}
	return createElement(TrendsPage, viewProps)
}

export default TrendsPageContainer
