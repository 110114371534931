import { ellipsis, fontType, toRem, transition } from '../../styles/functions'

import styled, { css } from 'styled-components'
import { colors } from '../../styles/theme'
import { isDashed } from 'shared/util/utils'

// OmitStyledComponentsDefaultProps<'tertiaryColor'>
// { infoColor: string, errorColor: string }
export const FloatLabelInputWrapper = styled.div<any>`
	${({ styles }) => css`
		position: relative;

		label.fli-label {
			color: ${styles.secondaryColor};
			font-size: ${toRem(styles.fontSize)};
			left: 16px;
			position: absolute;
			top: 18px;

			cursor: text;

			${transition()}
			${fontType(styles.fontFamily)};

			/* ${styles.isFilled && css``} */
		}

		input.fli-input {
			background: ${styles.backgroundColor};
			border-radius: ${styles.round ? '30px' : '4px'};

			color: ${styles.secondaryColor};
			font-size: ${toRem(styles.fontSize)};
			padding: ${styles.padding};
			width: 100%;
			border: solid 1px ${styles.secondaryColor};

			${transition()}
			${ellipsis()}
			${fontType(styles.fontFamily)};

			&:focus,
			&:valid {
				border: solid 1px ${colors.secondary};

				+ label {
					color: ${styles.primaryColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 12px;
					text-transform: uppercase;
				}
			}

			&[disabled] {
				border: ${isDashed(styles.dashedWhenDisabled)} 1px
					${styles.secondaryColor};

				+ label {
					color: ${styles.secondaryColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 12px;
				}
			}
		}

		p.fli-info-message {
			margin-top: 4px;
			font-size: ${toRem(styles.fontSize - 4)};
			color: ${styles.infoColor ? styles.infoColor : styles.primaryColor};
			${fontType(styles.fontFamily)};
		}

		p.fli-error-message {
			margin-top: 4px;
			font-size: ${toRem(styles.fontSize - 4)};
			color: ${styles.errorColor ? styles.errorColor : colors.error};
			${fontType(styles.fontFamily)};
		}
	`}
`
