import { toRem } from '../../styles/functions'
import { colors } from '../../styles/theme'
import styled from 'styled-components'

const eyeDefaultStyle = `
	position: absolute;
	right: 16px;
	top: 16px;
	width: ${toRem(20)};
	height: ${toRem(20)};
	cursor: pointer;
	color: ${colors.grey};
`

export const InputPasswordWrapper = styled.div`
	position: relative;
	width: 100%;

	input {
		padding: 26px 50px 10px 16px;
	}

	.eye-icon {
		${eyeDefaultStyle};
	}

	.eyeoff-icon {
		${eyeDefaultStyle};
	}
`
