import { fontType } from 'shared/styles/functions'
import styled, { css } from 'styled-components'
import { colors } from '../../styles/theme'

export const PdfFilesWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	${fontType(1)};
	margin-bottom: 2px;
`
export const PdfFilesContainer = styled.div`
	input {
		display: none;
	}
`
export const PdfFilesList = styled.div`
	background: ${colors.white};
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 90%;
	border-radius: 4px;
	padding: 18px;
	flex: 1;
	margin-right: 5px;
`
export const PdfFilesLabel = styled.div`
	img {
		width: 15px;
		height: 15px;
		margin-right: 8px;
	}
	color: ${colors.darkGrey};
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
`
export const PdfFilesNumber = styled.div`
	width: 30px;
	height: 23px;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	font-size: 12px;
	align-items: center;

	${(props) =>
		props.className === 'Active'
			? css`
					background: ${colors.secondary};
					color: ${colors.modalBackground};
			  `
			: null}

	${(props) =>
		props.className === 'Inactive'
			? css`
					background: ${colors.grey};
					color: ${colors.white};
			  `
			: null}
`

export const PdfAddFilesButton = styled.label`
	cursor: pointer;
	img {
		width: 59px;
		height: 59px;
	}
`
