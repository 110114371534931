import { createStore } from 'redux'
import { Persistor, persistReducer, persistStore } from 'redux-persist'
import { Reducers } from './ducks'
import storage from 'redux-persist/lib/storage'
import { APP_ENV } from 'shared/util/env'

const key = `XP_Portal_${APP_ENV.ENVIRONMENT}`
const persistConfig = {
	key: key,
	storage,
	whitelist: ['user', 'token']
}

const persistedReducer = persistReducer(persistConfig, Reducers)

export const store = createStore(persistedReducer)
export const persistor: Persistor = persistStore(store)
