import { fontType } from 'shared/styles/functions'
import styled from 'styled-components'
import { colors } from '../../../styles/theme'

export const PdfSectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 91%;
	${fontType(1)}
	height:60px;
	background: ${colors.white};
	border-radius: 4px;
	padding: 18px;
	margin-top: 1px;
	margin-right: 5px;

	button {
		background: transparent;
		border: none;
		outline: none;
	}
	@media (max-width: 450px) {
		width: 100%;
	}
`

export const PdfSectionLabel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		margin-right: 8px;
	}
`

export const PdfSectionLabelText = styled.div`
	color: ${colors.darkGrey};
	font-size: 12px;
`

export const PdfSectionDetailAndRemove = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		cursor: pointer;
	}
`

export const PdfSectionSizeText = styled.div`
	color: ${colors.grey};
	font-size: 12px;
	margin-right: 8px;
`
