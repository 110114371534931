import { colors } from '../../styles/theme'
import { FloatLabelInputWrapper } from './styles'
import { IFloatLabelInputProps } from './types'

import React, { useEffect, useState } from 'react'
import { paddingToString } from 'shared/util/Lib.inside.util'

function FloatLabelInput({
	label,
	id,
	className,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	infoMessage = '',
	errorMessage = '',
	hasError = false,
	infoColor = '',
	errorColor = '',
	dashedWhenDisabled = true,
	...rest
}: IFloatLabelInputProps) {
	const [isFocused, setFocused] = useState(false)
	const [isBluried, setBluried] = useState(false)

	const [isFilled, setIsFilled] = useState(false)

	const { value } = rest

	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString,
		dashedWhenDisabled,
		infoColor,
		errorColor,
		isFilled: isFilled
	}

	function handleOnBlur() {
		setFocused(false)
		setBluried(true)
	}

	function handleOnFocus() {
		setFocused(true)
		setBluried(false)
	}

	function handleFilled() {
		setIsFilled(!!value)
	}

	useEffect(handleFilled, [value])

	return (
		<FloatLabelInputWrapper styles={mergedStyles} className={className}>
			<input
				onBlur={handleOnBlur}
				onFocus={handleOnFocus}
				className='fli-input'
				{...rest}
				value={value}
				id={id}
				required
			/>
			<label className='fli-label' htmlFor={id}>
				{label}
			</label>

			{infoMessage && !isBluried && isFocused ? (
				<p className='fli-info-message'>{infoMessage}</p>
			) : null}

			{errorMessage && hasError && isBluried ? (
				<p className='fli-error-message'>{errorMessage}</p>
			) : null}
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelInput
