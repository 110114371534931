import React from 'react'
import { useSelected, useFocused } from 'slate-react'

import { ImageElementWrapper } from './styles'
import { IImageElementProps } from './types'

export default function ImageElement({
	attributes,
	children,
	element
}: IImageElementProps) {
	const selected = useSelected()
	const focused = useFocused()

	return (
		<ImageElementWrapper
			type={element.type}
			selected={selected}
			focused={focused}
			// {...attributes}
		>
			<div className='image-wrapper' contentEditable={false}>
				<img src={element.url} className='image' alt='img' />
			</div>
			{children}
		</ImageElementWrapper>
	)
}
