import { createElement, FormEvent, useEffect, useState } from 'react'
import { differenceInDays, differenceInCalendarDays } from 'date-fns'
import { IModalCustomerEdit } from './types'

import { IViewProps } from './types'
import CustomerEdit from './view'
import { fecthCustomerEdit } from 'shared/services/customer.service'
import { IUpdateCustomer } from 'shared/interfaces/customer'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { SelectOption } from '@buildbox/components'
import { CATEGORY_OPTIONS } from 'shared/util/Consts'
import { successMessages } from 'shared/util/Messages'

function ModalCustomerEditContainer(props: IModalCustomerEdit): JSX.Element {
	const {
		hideModal,
		show,
		data,
		setShowModal,
		handleAfterClose,
		tierOptions
	} = props
	const [statusSelection, setStatusSelection] = useState<string[]>([])

	const [tierEdit, setTierEdit] = useState('')
	const [
		brazilElectionContentAccess,
		setBrazilElectionContentAccess
	] = useState(false)

	const [categoryCustomer, setCategoryCustomer] = useState('')
	const [selectedAction, setSelectedAction] = useState('')
	const [company, setCompany] = useState('')
	const [filledData, setFilledData] = useState(false)
	const [checkIfDataHasBeenChanged, setCheckIfDataHasBeenChanged] = useState(
		false
	)
	const [oldInputValues, setOldInputValues] = useState<string[]>([])

	const [isLoading, setIsLoading] = useState(false)

	const [selectedTier, setSelectedTier] = useState<SelectOption | null>(null)
	const [
		newActionOptionSelected,
		setNewActionOptionSelected
	] = useState<SelectOption | null>(null)

	const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
	const [selectedCategory, setSelectedCategory] = useState<SelectOption | null>(
		null
	)

	function wasThereAnUpdateInInputs() {
		const newInputValues = [tierEdit, categoryCustomer, selectedAction, company]

		const hasNewData = oldInputValues
			// checks whether it contains the same data
			.map((x) => newInputValues.includes(x))
			// make sure you have any different items
			.some((x) => x === false)

		return hasNewData
	}

	function findTierSelect(): SelectOption | null {
		const authorTrendCreator = tierOptions.find((x) => {
			return x.label === String(tierEdit)
		})

		if (authorTrendCreator === undefined) return null

		return {
			label: authorTrendCreator.label,
			value: authorTrendCreator.value
		}
	}

	function findCategorySelect(): SelectOption | null {
		const categoryCreator = CATEGORY_OPTIONS.find((x) => {
			return x.value === String(categoryCustomer)
		})

		if (categoryCreator === undefined) return null

		return {
			label: categoryCreator.label,
			value: categoryCreator.value
		}
	}

	function handlerSelectNewAction(selectedOption: SelectOption | null): void {
		if (!selectedOption) return

		setNewActionOptionSelected(selectedOption)
		setSelectedAction(selectedOption.value)
	}

	function handleSelectTier(selectedOption: SelectOption | null) {
		if (!selectedOption) return

		setTierEdit(selectedOption.value)
		setSelectedTier(selectedOption)
	}

	function handleSelectCategory(selectedOption: SelectOption | null) {
		if (!selectedOption) return

		setCategoryCustomer(selectedOption.value)
		setSelectedCategory(selectedOption)
	}

	function handleEffectCategory() {
		const categoryOptions = CATEGORY_OPTIONS.map((item) => ({
			label: item.label,
			value: item.value
		}))

		setCategoryOptions(categoryOptions)
	}

	function populateData() {
		actionsStatusEdit(data.status)
		setCategoryCustomer(data.category || '')
		setTierEdit(data.tier || '')
		setCompany(data.company || '')

		setBrazilElectionContentAccess(data.brazilElectionContentAccess || false)

		setSelectedAction('')
		setOldInputValues([
			data.tier,
			data.category,
			'',
			data.company
		]) /** selectedAction */
		setCheckIfDataHasBeenChanged(true)
	}

	function clearModal() {
		setSelectedAction('')
		setFilledData(false)
		setSelectedAction('')
		setCompany('')
		setOldInputValues([data.tier, data.category, selectedAction, data.company])
		setNewActionOptionSelected(null)
		setBrazilElectionContentAccess(false)
		populateData()
	}

	function verifyIfCanRenew(activationDate: Date) {
		const thisYear = new Date().getFullYear(),
			nextYear = thisYear + 1,
			beginDate = new Date(thisYear, 0, 1),
			endDate = new Date(nextYear, 0, 0)

		let qtyDaysInThisYear = differenceInCalendarDays(endDate, beginDate)

		//verify if leap year
		if (qtyDaysInThisYear === 366) qtyDaysInThisYear -= 1

		const qtyDays = differenceInDays(new Date(), activationDate)

		const qtyOfDaysLeft = qtyDays - qtyDaysInThisYear - 1

		const canRenew = qtyOfDaysLeft >= 0 && qtyOfDaysLeft <= 7

		if (canRenew) {
			setStatusSelection((items) => [...items, 'RENOVAR'])
		}
	}

	function actionsStatusEdit(status: string) {
		switch (status) {
			case 'PENDING':
				setStatusSelection(['APROVAR', 'RECUSAR'])
				break

			case 'ACTIVE':
				setStatusSelection(['INATIVAR'])
				break

			case 'INACTIVE':
				setStatusSelection(['REATIVAR'])
				break

			case 'EXPIRED':
				setStatusSelection(['RENOVAR', 'INATIVAR'])
				break

			default:
				setStatusSelection([])
				break
		}

		if (!data.activationDate) return

		if (status === 'ACTIVE') verifyIfCanRenew(new Date(data.activationDate))
	}

	async function handleSubmit(e: FormEvent) {
		e.preventDefault()

		try {
			setIsLoading(true)
			const updateCustomer: IUpdateCustomer = {
				tier: tierEdit,
				category: categoryCustomer,
				status: selectedAction || data.status,
				company,
				brazilElectionContentAccess: brazilElectionContentAccess
			}

			await fecthCustomerEdit(data._id, updateCustomer)

			cogoToast.success(successMessages.customerUpdated, cogoDefaultOptions)
			handleAfterClose()
			setShowModal(false)
			clearModal()
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handleChangeCompany(value: string) {
		setCompany(value)
	}

	function handleBrazilElection() {
		setBrazilElectionContentAccess(!brazilElectionContentAccess)
	}

	useEffect(() => {
		setSelectedTier(findTierSelect())
		setSelectedCategory(findCategorySelect())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tierEdit, categoryCustomer])

	useEffect(handleEffectCategory, [categoryCustomer])

	useEffect(() => {
		populateData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	useEffect(() => {
		if (!checkIfDataHasBeenChanged) return

		if (data.status === 'PENDING') {
			if (selectedAction && categoryCustomer && tierEdit) {
				setFilledData(true)
			}
			return
		}

		const hasUpdate = wasThereAnUpdateInInputs()

		const brazilElectionContentAccessChange =
			brazilElectionContentAccess !== data.brazilElectionContentAccess

		setFilledData(hasUpdate || brazilElectionContentAccessChange)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedAction,
		categoryCustomer,
		tierEdit,
		company,
		brazilElectionContentAccess
	])

	const viewProps: IViewProps = {
		hideModal,
		show,
		data,
		setTierEdit,
		tierEdit,
		setCategoryCustomer,
		handleSubmit,
		statusSelection,
		filledData,
		selectedAction,
		setSelectedAction,
		setNewActionOptionSelected,
		handlerSelectNewAction,
		newActionOptionSelected,
		isLoading,
		handleSelectTier,
		handleSelectCategory,
		tierOptions,
		categoryOptions,
		selectedCategory,
		selectedTier,
		clearModal,
		handleChangeCompany,
		company,
		handleBrazilElection,
		brazilElectionContentAccess
	}

	return createElement(CustomerEdit, viewProps)
}

export default ModalCustomerEditContainer
