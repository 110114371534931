import 'react-day-picker/lib/style.css'
import { colors } from '../../styles/theme'
import {
	DATE_FORMAT,
	monthNamesArray,
	weekNamesLong,
	weekNamesShort
} from './util'
import { DayPickerWrapper } from './styles'
import { format, isPast, isValid, parse, parseISO } from 'date-fns'
import { IDatePickerProps } from './types'

import BR from 'date-fns/locale/pt-BR'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import React, { useState } from 'react'
import { RangeModifier, DateUtils } from 'react-day-picker'
import { paddingToString } from 'shared/util/Lib.inside.util'

const locale = { locale: BR }

function DatePicker({
	placeholder,
	value,
	returnDate,
	disabled,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontFamily = 'sans-serif',
	padding = [16, 16, 16, 16]
}: IDatePickerProps) {
	const [range, setRange] = useState<RangeModifier>(value)

	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontFamily,
		padding: paddingString
	}

	function renderDate(date: Date) {
		return <p>{date.getDate()}</p>
	}

	function renderWeek(weekNumber: number) {
		return <p>{weekNamesLong[weekNumber]}</p>
	}

	function formatDate(date: Date): string {
		if (!date || !isValid(date)) return ''

		return format(date, DATE_FORMAT, locale)
	}

	function parseDate(date: string): Date | void {
		if (!date || !isValid(parseISO(date))) return

		if (isPast(parseISO(date))) return

		return parse(date, DATE_FORMAT, new Date(), locale)
	}

	function dayChange(date: Date): void {
		const newRange = DateUtils.addDayToRange(date, range)
		returnDate(newRange)
		setRange(newRange)
	}

	function parseToDisplay(date: Date) {
		if (!date) return 'DD/MM/YY'

		return format(date, 'dd/MM/yy', { locale: BR })
	}

	const { from, to } = range
	const modifiers = { start: from, end: to }
	const dayPickerProps = {
		months: monthNamesArray,
		weekdaysLong: weekNamesLong,
		weekdaysShort: weekNamesShort,
		renderDay: renderDate,
		renderWeek: renderWeek,
		modifiers: modifiers,
		selectedDays: [from, { from, to }]
	}

	return (
		<DayPickerWrapper styles={mergedStyles}>
			<div className='date-picker-primary'>
				<DayPickerInput
					placeholder={placeholder}
					inputProps={{ readOnly: true, disabled }}
					dayPickerProps={dayPickerProps}
					formatDate={formatDate}
					parseDate={parseDate}
					onDayChange={dayChange}
					value={`${parseToDisplay(from)} até ${parseToDisplay(to)}`}
					hideOnDayClick={false}
					onDayPickerHide={() => null}
					onDayPickerShow={() => null}
				/>
			</div>
		</DayPickerWrapper>
	)
}

export default DatePicker
