import {
	ReportCategoryValueEnum,
	TypeSpeechEnum
} from 'shared/interfaces/report'

export function translateStatus(status: string) {
	switch (status) {
		case 'PENDING':
			return 'PENDENTE'
		case 'ACTIVE':
			return 'ATIVO '
		case 'INACTIVE':
			return 'INATIVO'
		case 'EXPIRED':
			return 'EXPIRADO'
		default:
			return status
	}
}

export function mapCategoryContent(category: string) {
	switch (category) {
		case 'POLITICS':
			return 'Politics'
		case 'SECTOR_MONITOR':
			return 'Economics'
		case 'MACRO_STRATEGY':
			return 'Macro Strategy'
		case 'INTERNATIONAL_POLITICS':
			return 'International Politics'
		default:
			return category
	}
}
export function mapCategoryReport(category: string): string {
	switch (category) {
		case 'POLITICS_MACRO_STRATEGY':
			return 'Politics & Macro Strategy'
		case 'SECTOR_MONITOR':
			return 'Sector Monitor'
		case 'GLOBALS':
			return 'Globals'
		case 'EQUITIES':
			return 'Equities'
		case 'BZ_ELECTION':
			return 'BZ Elections 2022'
		case 'SPEECH':
			return 'Discursos'
		default:
			return category
	}
}

export function mapCategorySpeechCategory(category: ReportCategoryValueEnum) {
	switch (category) {
		case 'AGRO':
			return 'Agro'
		case 'FOOD_AND_DRINKS':
			return 'Alimentos e Bebidas'
		case 'FINANCIAL_SECTOR':
			return 'Setor Financeiro'
		case 'ENERGY_AND_FUEL':
			return 'Energia e Combustíveis'
		case 'MINING_AND_STEEL_INDUSTRY':
			return 'Mineração e Siderurgia'
		case 'SUPPLEMENTARY_HEALTH':
			return 'Saúde Suplementar'
		case 'PHARMACEUTICAL':
			return 'Farmacêuticas'
		case 'INFRASTRUCTURE':
			return 'Infraestrutura'
		case 'TELECOM':
			return 'Telecom'
		case 'TOURISM_AND_AIRLINES':
			return 'Turismo e Aéreas'
		case 'RETAIL':
			return 'Varejo'
		case 'TAX_POLICY':
			return 'Política Fiscal'
		case 'ENVIRONMENTAL_PRESERVATION':
			return 'Preservação Ambiental'
		case 'EDUCATION':
			return 'Educação'
		case 'REFORMS':
			return 'Reformas'

		default:
			return category
	}
}

export function mapCategoryType(category: TypeSpeechEnum) {
	switch (category) {
		case 'INTERVIEW':
			return 'Entrevista'
		case 'EVENT':
			return 'Evento'
		case 'DEBATE':
			return 'Debate'
		case 'ELECTORAL_PROPAGANDA':
			return 'Propaganda Eleitoral'
		case 'OTHERS':
			return 'Outros'

		default:
			return category
	}
}

export function mapSectorMonitorSubcategory(subcategory: string): string {
	switch (subcategory) {
		case 'GENERAL':
			return 'General'
		case 'UTILITIES':
			return 'Utilities'
		case 'FINANCIAL_SECTOR':
			return 'Financial Sector'
		case 'REAL_STATE':
			return 'Real State'
		case 'HEALTH_CARE':
			return 'Health Care'
		case 'INFRASTUCTURE':
			return 'Infrastucture'
		case 'MINING_STEEL':
			return 'Mining & Steel'
		case 'TRANSPORTATION':
			return 'Transportation'
		case 'RETAIL':
			return 'Retail'
		default:
			return subcategory
	}
}
export function mapEquitiesSubcategory(subcategory: string): string {
	switch (subcategory) {
		case 'COMMODITIES_AND_AGRO':
			return 'Commodities and Agro'
		case 'AIRLINES_AND_TOURISM':
			return 'Airlines and Tourism'
		case 'FOOD_BEVERAGE':
			return 'Food & Beverage'
		case 'FINANCIAL_SECTOR_AND_PAYMENTS':
			return 'Financial Sector and Payments'
		case 'RETAIL':
			return 'Retail'
		case 'CAR_RENTAL':
			return 'Car Rental'
		case 'FUEL_OIL_AND_GAS':
			return 'Fuel Oil and Gas'
		case 'EDUCATION':
			return 'Education'
		case 'REAL_ESTATE_AND_COMMERCIAL_PROPERTIES':
			return 'Real Estate and Commercial Properties'
		case 'ENERGY_AND_SANITATION':
			return 'Energy and Sanitation'
		case 'HEALTH_AND_PHARMA':
			return 'Health and Pharma'
		case 'INFRAESTRUCTURE_AND_LOGISTICS':
			return 'Infrastructure and Logistics'
		case 'TECH_AND_TELECOM':
			return 'Tech and Telecom'
		default:
			return subcategory
	}
}
