import { IError } from '../interfaces/error'
import cogoDefaultOptions from './toaster'
import cogoToast from 'cogo-toast'
import { logout } from './session'
import { errorMessages } from './Messages'

function handleError(error: IError): void {
	if (!error?.message) {
		cogoToast.error(errorMessages.serverUnavailable, cogoDefaultOptions)
		return
	}

	if (error.code === 401) {
		logout()

		return
	}

	// Handle Error Diferently because need to redirect
	if (error.code === 418) {
		throw error
	}

	if (error.code === 421) {
		throw error
	}

	cogoToast.error(error.message, cogoDefaultOptions)
}

export default handleError
