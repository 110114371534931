import React from 'react'
import { Button, Loading, Table } from '@buildbox/components'
import PageTitle from 'shared/components/PageTitle'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import { tableStyle, primaryButtonStyle } from 'shared/styles/theme'
import { colors } from 'shared/styles/theme'
import ModalReport from 'modules/ModalReport/index'
import InputSearch from 'shared/components/InputSearch'

import { Header, InputSearchWrapper, ReportsPageWrapper } from './styles'
import { IViewProps } from './types'

function ReportsPage(props: IViewProps): JSX.Element {
	const {
		tableColumns,
		tableRows,
		reportsPerPage,
		navProps,
		isLoading,
		showModalReport,
		modalAction,
		currentReport,
		search,
		handleSearch,
		handleCreateReport,
		handleHideModalReport,
		handleReloadReportsPage,
		handleDeleteReportsPage,
		handleFetchDowload,
		isDowloadCharge
	} = props

	return (
		<>
			<ReportsPageWrapper>
				<Header>
					<PageTitle>Latests</PageTitle>

					<div>
						<InputSearchWrapper>
							{isDowloadCharge ? (
								<div className='loading-wrapper'>
									<Loading type='Sentry' primaryColor={colors.secondary} />
								</div>
							) : (
								<Button
									{...primaryButtonStyle}
									onClick={handleFetchDowload}
									id='button'
								>
									DOWLOAD DE LATESTS
								</Button>
							)}
						</InputSearchWrapper>

						<InputSearchWrapper>
							<InputSearch
								id='search'
								label='Buscar Latest'
								onChange={(e) => handleSearch(e.target.value)}
								value={search}
							/>
						</InputSearchWrapper>
						<Button
							{...primaryButtonStyle}
							onClick={() => handleCreateReport()}
							id='button'
						>
							NOVO LATEST
						</Button>
					</div>
				</Header>
				{/* 
					<PageTitle>Eventos</PageTitle>

					<div>
						<InputSearchWrapper>
							<InputSearch
								id='search'
								label='Buscar evento'
								onChange={(e) => handleSearch(e.target.value)}
								value={search}
							/>
						</InputSearchWrapper>
						<Button
							{...primaryButtonStyle}
							onClick={() => handleSelectEvent(initializeEvent(), true)}
						>
							{'NOVO EVENTO'}
						</Button>
					</div>
				*/}

				{isLoading ? (
					<div className='table-loading'>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</div>
				) : (
					<div className='table-container'>
						{tableRows.length ? (
							<Table
								// Workaround while we need to fix columns type on lib
								columns={tableColumns as any}
								data={tableRows}
								navProps={navProps}
								pageSize={reportsPerPage}
								{...tableStyle}
							/>
						) : (
							<DefaultPagePlaceholder text='No momento, não há latests para serem exibidos.' />
						)}
					</div>
				)}

				{showModalReport ? (
					<ModalReport
						isActive={showModalReport}
						modalAction={modalAction}
						currentReport={currentReport}
						hideModal={handleHideModalReport}
						reloadReportsPage={handleReloadReportsPage}
						reloadDeleteReport={handleDeleteReportsPage}
					/>
				) : null}
			</ReportsPageWrapper>
		</>
	)
}

export default ReportsPage
