export function defineModalTitle(action: string): string {
	switch (action) {
		case 'CREATE':
			return 'Novo Hot Topic'
		case 'EDIT':
			return 'Detalhes do Hot Topic'
		default:
			return 'Excluir Hot Topic'
	}
}

export function defineModalButtonName(action: string): string {
	switch (action) {
		case 'CREATE':
			return 'PUBLICAR'
		case 'EDIT':
			return 'SALVAR ALTERAÇÕES'
		default:
			return 'EXCLUIR'
	}
}
