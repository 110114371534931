import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { redirectRoute } from 'shared/util/redirect'
import { routesData } from './data'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import Analytics from 'shared/components/Analytics'
import Page from 'shared/components/Page'
import React, { createElement } from 'react'
import { UserRolesEnum } from 'shared/interfaces/user'

function Routes(): JSX.Element {
	const { user, token } = useTypedSelector(['user', 'token'])

	function userHasPermission(accessTypes: UserRolesEnum[]): boolean {
		if (!accessTypes || accessTypes.length === 0) return true

		const hasPermission = accessTypes.some((type) => type === user.role)

		return hasPermission
	}

	return (
		<BrowserRouter>
			<Analytics />
			<Switch>
				{routesData.map(
					({ accessTypes, component, path, noWrapper, ...props }) => {
						const isPublic = !accessTypes
						if (accessTypes && userHasPermission(accessTypes)) {
							return (
								<Route key={path} exact path={path}>
									{noWrapper ? (
										<>{createElement(component, props)}</>
									) : (
										<Page>{createElement(component, props)}</Page>
									)}
								</Route>
							)
						} else if (isPublic) {
							return (
								<Route key={path} exact path={path}>
									{createElement(component, props)}
								</Route>
							)
						} else {
							return null
						}
					}
				)}

				<Redirect from={'*'} to={redirectRoute(user.role, token.accessToken)} />
			</Switch>
		</BrowserRouter>
	)
}

export default React.memo(Routes)
