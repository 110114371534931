import styled from 'styled-components'
import { colors } from 'shared/styles/theme'

export const ModalContainerWrapper = styled.div`
	.modal-primary {
		.modal-wrapper {
			background-color: ${colors.pageBackground};
		}
	}
`

export const ModalReportWrapper = styled.form`
	display: flex;
	flex-direction: column;

	.input {
		margin-bottom: 16px;
	}

	.select {
		margin-bottom: 16px;
	}

	.text-editor-area {
		padding: 12px;
		border: 1px solid #979e977a;
		color: ${colors.darkGrey};
		margin-bottom: 16px;
	}

	.speech-form {
		margin-bottom: 40px;

		margin-top: 32px;
	}

	.list-pdfs-files {
		margin-bottom: 16px;
	}

	.switches-label {
		font-size: 14px;
		color: ${colors.lightBrown};
	}

	.modal-switch {
		margin-left: 48px;
	}

	.modal-button {
		align-self: center;
	}

	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export const ModalDeleteReport = styled.div`
	width: 100%;

	p {
		font-size: 14px;
		text-align: center;
	}

	p + p {
		margin: 0.2rem 0 2rem;
	}

	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export const ImageInputWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`

export const SwitchesBackground = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 40px;
`

export const SwitchWrapper = styled.div`
	margin-bottom: 15px;
`

export const LanguageLabel = styled.span`
	font-size: 10px;
	color: #0000008f;
	font-weight: bold;
`

export const SwicthLabelContainer = styled.div`
	margin-top: 8px;
`
