import styled, { css } from 'styled-components'

export const ImageElementWrapper = styled.span<{
	selected: boolean
	focused: boolean
	type: string
}>`
	${({ selected, focused, type }) => css`
		.image-wrapper {
			.image {
				display: block;
				max-width: 100%;
				max-height: 20em;
				box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
			}

			display: flex;
			justify-content: ${type === 'align_center'
				? 'center'
				: type === 'align_justify'
				? 'center'
				: type === 'align_right'
				? 'flex-end'
				: type === 'align_left'
				? 'flex-start'
				: ''};
		}
	`}
`
// This align is a workaround, please consider improve later.
