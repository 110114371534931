import styled from 'styled-components'
import { transition } from '../../../styles/functions'

export const IconWrapper = styled.span`
	vertical-align: text-bottom;

	.material-icons {
		color: #cccccc;
		${transition()}
	}

	&.active {
		.material-icons {
			color: #000000;
		}
	}
`
