import { fontType } from 'shared/styles/functions'
import { colors } from 'shared/styles/theme'
import styled from 'styled-components'

export const Input = styled.input`
	width: 69px;
	height: 36px;

	border: none;
	background-color: #f5f5f0;
	padding: 10px 12px;
	${fontType(0)}

	&:enabled {
		background-color: ${colors.white};
		border: 1px solid #979e977a;
	}
`

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const Icon = styled.img`
	margin-left: 8px;
	width: 20px;
	height: 20px;
`

export const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
`
