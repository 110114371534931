import React from 'react'
import { Modal } from '@buildbox/components'
import { ModalReportErrorWrapper } from './styles'
import { IReportErrorModalProps } from './types'
import HandleError from './handleError'

const ReportErrorModal: React.FC<IReportErrorModalProps> = ({
	isActive,
	onClose,
	value
}) => {
	return (
		<Modal
			isActive={isActive}
			onClose={onClose}
			title='Crítica de erros do arquivo'
			size={550}
			className='modal-primary'
		>
			<ModalReportErrorWrapper>
				{value?.map((critic) => (
					<HandleError critic={critic} />
				))}
			</ModalReportErrorWrapper>
		</Modal>
	)
}

export default ReportErrorModal
