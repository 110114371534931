import styled from 'styled-components'

export const FloatLabelInputWrapper = styled.div`
	position: relative;

	img.icon {
		right: 16px;
		position: absolute;
		top: 18px;

		width: 20px;
		height: 20px;
	}
`
