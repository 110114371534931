import React from 'react'

import { IViewProps } from './types'
import { DashboardPageWrapper, reactSelectStyles } from './styles'

import { CATEGORY_OPTIONS } from 'shared/util/Consts'
import { Column, Row } from 'shared/styles'
import {
	colors,
	datePickerPrimaryStyles,
	selectStyle
} from 'shared/styles/theme'
import DatePicker from 'shared/components/DateRangePicker'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { Users } from 'react-feather'
import { Loading } from '@buildbox/components'
import PageTitle from 'shared/components/PageTitle'

function Dashboard(props: IViewProps): JSX.Element {
	const {
		companyOptions,
		selectedTiers,
		selectedCategories,
		selectedCompanies,
		handleTierSelect,
		handleCategorySelect,
		handleCompanySelect,
		handleDatePickerChange,
		rangePicked,
		handlePlaceholder,
		isLoading,
		topEvents,
		metrics,
		isLoadingCompanies,
		tierOptions
	} = props

	return (
		<>
			<DashboardPageWrapper>
				<div className='page-header'>
					<PageTitle>Dashboard</PageTitle>
				</div>

				{isLoadingCompanies ? (
					<div className='loading-wrapper'>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</div>
				) : (
					<>
						<div className='selects-wrapper'>
							<Row>
								<Column sm='12' md='6' lg='3'>
									<DatePicker
										placeholder='Selecione uma data'
										returnDate={handleDatePickerChange}
										value={rangePicked}
										{...datePickerPrimaryStyles}
									/>
								</Column>
								<Column sm='12' md='6' lg='3'>
									<div className='select-wrapper'>
										<ReactMultiSelectCheckboxes
											id='tier'
											placeholder='Tier'
											value={selectedTiers}
											options={tierOptions}
											onChange={handleTierSelect}
											isMulti
											noOptionsMessage={() => 'Nenhum tier para mostrar.'}
											placeholderButtonLabel='Tier'
											getDropdownButtonLabel={handlePlaceholder}
											{...selectStyle}
											styles={reactSelectStyles}
										/>
									</div>
								</Column>

								<Column sm='12' md='6' lg='3'>
									<div className='select-wrapper'>
										<ReactMultiSelectCheckboxes
											id='category'
											placeholder='Categoria'
											value={selectedCategories}
											options={CATEGORY_OPTIONS}
											onChange={handleCategorySelect}
											className='select'
											isMulti
											noOptionsMessage={() => 'Nenhuma categoria para mostrar.'}
											placeholderButtonLabel='Categoria'
											getDropdownButtonLabel={handlePlaceholder}
											{...selectStyle}
											styles={reactSelectStyles}
										/>
									</div>
								</Column>

								<Column sm='12' md='6' lg='3'>
									<div className='select-wrapper'>
										<ReactMultiSelectCheckboxes
											id='company'
											placeholder='Empresa'
											value={selectedCompanies}
											options={companyOptions}
											onChange={handleCompanySelect}
											className='select'
											isMulti
											noOptionsMessage={() => 'Nenhuma empresa para mostrar.'}
											placeholderButtonLabel='Empresa'
											getDropdownButtonLabel={handlePlaceholder}
											{...selectStyle}
											styles={reactSelectStyles}
											isLoading={isLoadingCompanies}
										/>
									</div>
								</Column>
							</Row>
						</div>

						<div className='cards-wrapper'>
							<Row>
								<Column sm='12' md='6' lg='3'>
									<div className='card-item'>
										<p className='card-title'>Total de clientes ativos</p>

										<div className='info'>
											<p className='card-count'>{metrics.actives || '0'}</p>
											<span className='churn'>Churn</span>
											<span className='percent-churn'>
												{`${metrics.churn || '0'} (${metrics.percentChurn || '0%'})`}
											</span>
										</div>
									</div>
								</Column>

								<Column sm='12' md='6' lg='3'>
									<div className='card-item'>
										<p className='card-title'>Novos clientes no período</p>

										<div className='info'>
											<p className='card-count'>{metrics.news || '0'}</p>
										</div>
									</div>
								</Column>

								<Column sm='12' md='6' lg='3'>
									<div className='card-item'>
										<p className='card-title'>Life time cycle</p>

										<div className='info'>
											<p className='card-count'>{metrics.ltc || '0'} dias</p>
										</div>
									</div>
								</Column>

								<Column sm='12' md='6' lg='3'>
									<div className='card-item'>
										<p className='card-title'>BZ Elections Access</p>

										<div className='info'>
											<p className='card-count'>{metrics.bzElectionTotalListingRequests}</p>
											<span className='percent'>
												{`${metrics.percentWithoutAccess || '0%'} SEM PERMISSÃO`}
											</span>
										</div>
									</div>
								</Column>
							</Row>
						</div>

						<div className='top-events-wrapper'>
							<Row>
								<Column md='12' lg='6'>
									<div className='attendees event-card'>
										{isLoading ? (
											<div className='loading-wrapper'>
												<Loading
													type='Sentry'
													primaryColor={colors.secondary}
												/>
											</div>
										) : (
											<>
												<h2 className='title'>
													{`${topEvents.attendees.length} eventos com maior número de solicitações de participação`}
												</h2>

												{topEvents.attendees.map((att) => (
													<div className='event-item'>
														<div className='people-count'>
															<Users className='icon' width={18} height={18} />
															<p className='count'>{att.count}</p>
														</div>

														<p className='event-name'>{att.name}</p>
													</div>
												))}
											</>
										)}
									</div>
								</Column>
								<Column md='12' lg='6'>
									<div className='feedback event-card'>
										{isLoading ? (
											<div className='loading-wrapper'>
												<Loading
													type='Sentry'
													primaryColor={colors.secondary}
												/>
											</div>
										) : (
											<>
												<h2 className='title'>
													{`${topEvents.feedback.length} eventos com maior número de solicitações de feedback`}
												</h2>

												{topEvents.feedback.map((att) => (
													<div className='event-item'>
														<div className='people-count'>
															<Users className='icon' width={18} height={18} />
															<p className='count'>{att.count}</p>
														</div>

														<p className='event-name'>{att.name}</p>
													</div>
												))}
											</>
										)}
									</div>
								</Column>
							</Row>
						</div>
					</>
				)}
			</DashboardPageWrapper>
		</>
	)
}

export default Dashboard
