export interface ISwitchLanguageProps {
	setSwitchValue: React.Dispatch<
		React.SetStateAction<{
			portuguese: boolean
			english: boolean
		}>
	>
	switchValue: ILanguageOptionProps
}

export interface IOptionsProps {
	isActive: boolean
	option: string
}

export const SwitchLabel = {
	PORTUGUESE: 'PORTUGUESE',
	ENGLISH: 'ENGLISH'
}

enum SwitchLabelOption {
	PORTUGUESE,
	ENGLISH
}

export type SwitchLabelEnum = keyof typeof SwitchLabelOption

export interface ILanguageOptionProps {
	portuguese: boolean
	english: boolean
}
