import styled from 'styled-components'

export const ToolbarWrapper = styled.span`
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
	border-bottom: 1px solid #eee;
	margin-bottom: 16px;

	.toolbar-group {
		display:flex;
		align-items: center;
	}

	@media(max-width: 485px) {
		flex-direction: column;
	}
`
