import axiosInstance from './axiosInstance'
import {
	IChangePasswordResponse,
	ILoginResponse,
	IValidateResetTokenResponse,
	RequestChangePayload
} from '../interfaces/auth.service'

export async function login(
	email: string,
	password: string
): Promise<ILoginResponse> {
	const response = await axiosInstance.post('/auth/user-signin', {
		email,
		password
	})

	return response.data.content
}

export async function validateResetToken(
	resetToken: string
): Promise<IValidateResetTokenResponse> {
	const response = await axiosInstance.get(
		`/auth/user-by-reset-token/${resetToken}`
	)

	return response.data.content
}

export async function requestChangePassword(
	payload: RequestChangePayload
): Promise<ILoginResponse> {
	const response = await axiosInstance.post(
		'/auth/user-reset-password',
		payload
	)

	return response.data
}

export async function changePassword(
	resetToken: string,
	password: string
): Promise<IChangePasswordResponse> {
	const response = await axiosInstance.patch('/auth/user-change-password', {
		resetToken,
		password
	})

	return response.data.content
}
