import React, { useEffect, useState } from 'react'

import { SwitchWrapper } from './styles'
import { ISwitchProps } from './types'

function Switch(props: ISwitchProps) {
	const { isChecked, onChange, label, className, ...rest } = props

	const [checkboxState, setCheckBoxState] = useState(isChecked)

	function handleCheckBoxState() {
		setCheckBoxState(!checkboxState)
		onChange(!checkboxState)
	}

	useEffect(() => {
		setCheckBoxState(isChecked)
	}, [isChecked])

	useEffect(() => { }, [checkboxState])

	// TODO: Improve
	return (
		<SwitchWrapper className={className}>
			<span className='checkbox-label'>{label}</span>
			<label className='switch'>
				<input
					type='checkbox'
					checked={checkboxState}
					onChange={handleCheckBoxState}
					{...rest}
				/>
				<span className='slider round' />
			</label>
		</SwitchWrapper>
	)
}

export default Switch
