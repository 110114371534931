import routesEnum from '../../modules/Routes/enum'
import { UserRolesEnum } from 'shared/interfaces/user'

export function redirectRoute(userType: UserRolesEnum, accessToken: string) {
	if (userType === 'ADMIN' && accessToken) {
		return routesEnum.USERS_PAGE
	}

	if (userType === 'EDITOR' && accessToken) {
		return routesEnum.TRENDS_PAGE
	}

	return routesEnum.LOGIN
}
