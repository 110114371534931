import React, { PropsWithChildren, Ref } from 'react'
import { IconType, OrNull } from '../types'
import { IconWrapper } from './styles'

import MaterialIcon from 'material-icons-react'

export const Icon = React.forwardRef(function (
	{ className, icon, active, ...props }: PropsWithChildren<IconType>,
	ref: OrNull<Ref<HTMLSpanElement>>
) {
	return (
		<IconWrapper className={active ? 'active' : ''} {...props} ref={ref}>
			<MaterialIcon icon={icon} />
		</IconWrapper>
	)
})
