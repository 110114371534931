import React from 'react'
import { PrimaryLabel, SwitchWrapper } from './styles'
import { ISwitchLanguageProps, SwitchLabel } from './types'

function SwitchLanguage(props: ISwitchLanguageProps) {
	const { switchValue, setSwitchValue } = props

	function handleEnglishToggle() {
		setSwitchValue({
			...switchValue,
			english: !switchValue.english
		})
	}

	function handlePortugueseToggle() {
		setSwitchValue({
			...switchValue,
			portuguese: !switchValue.portuguese
		})
	}

	return (
		<SwitchWrapper>
			<PrimaryLabel
				option={SwitchLabel.ENGLISH}
				isActive={switchValue.english}
				htmlFor='english_input'
				className='checkbox-label'
			>
				INGLÊS
			</PrimaryLabel>
			<input
				type='checkbox'
				checked={switchValue.english}
				onChange={handleEnglishToggle}
				id='english_input'
			/>
			<span className='slider round' />

			<PrimaryLabel
				option={SwitchLabel.PORTUGUESE}
				isActive={switchValue.portuguese}
				htmlFor='portuguese_input'
				className='checkbox-label'
			>
				PORTUGUÊS
			</PrimaryLabel>
			<input
				type='checkbox'
				checked={switchValue.portuguese}
				onChange={handlePortugueseToggle}
				id='portuguese_input'
			/>
			<span className='slider round' />
		</SwitchWrapper>
	)
}

export default SwitchLanguage
