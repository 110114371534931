import { format } from 'date-fns'
import { INITIAL_BODY_VALUE } from 'shared/util/Consts'
import { ICustomersPage } from 'shared/interfaces/customer'
import { IEvent, IEventsPage } from 'shared/interfaces/event'
import {
	DownloadTypesEnum,
	IGetTopEventRequest,
	IGetTopEventResponse,
	INewEventPayload
} from 'shared/interfaces/event.service'
import axiosInstance from './axiosInstance'
import fileDownload from 'js-file-download'

export function initializeEvent(): IEvent {
	const event: IEvent = {
		_id: '',
		title: '',
		subtitle: '',
		author: '',
		createdBy: '',
		coverImage: '',
		date: '',
		notificate: false,
		emailNotificate: false,
		status: 'ACTIVE',
		body: JSON.stringify(INITIAL_BODY_VALUE),
		tier: '',
		videoLink: '',
		languages: [],
		enableFeedback: false,
		enableAttending: false,
		attachments: []
	}

	return event
}

export async function createEvent(event: INewEventPayload): Promise<IEvent> {
	const response = await axiosInstance.post('/event/', event)

	return response.data.content
}

export async function updateEvent(
	eventId: string,
	event: INewEventPayload
): Promise<IEvent> {
	const response = await axiosInstance.patch('/event/' + eventId, event)

	return response.data.content
}

export async function deleteEvent(eventId: string): Promise<IEvent> {
	const response = await axiosInstance.delete('/event/' + eventId)

	return response.data.content
}

export async function fetchEventsPage(
	pageIndex: number,
	maxItemsInPage: number,
	searchString = ''
): Promise<IEventsPage> {
	const response = await axiosInstance.post('/event/get-page', {
		pageIndex,
		maxItemsInPage,
		searchString,
		statistics: true
	})

	// FIXME: change to content, when WS is corrected
	return response.data.content
}

export async function getEventAttendeesOrFeedbacks(
	type: DownloadTypesEnum,
	eventId: string,
	pageIndex: number,
	maxItemsInPage: number
): Promise<ICustomersPage> {
	const response = await axiosInstance.post('/event/customers/' + eventId, {
		type,
		pageIndex,
		maxItemsInPage,
		searchString: ''
	})

	return response.data.content
}

export async function downloadCSV(type: DownloadTypesEnum, eventId: string) {
	const response = await axiosInstance.get(
		'/event/export-customers/' + type + '/' + eventId,
		{
			responseType: 'blob'
		}
	)

	const fileName = `${type}_${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}.xlsx`
	fileDownload(response.data, fileName)
}

export async function getTopEvent(
	payload: IGetTopEventRequest
): Promise<IGetTopEventResponse> {
	const response = await axiosInstance.post(
		'/event/top-subscribers-by-period',
		payload
	)

	return response.data.content
}
