import styled, { css } from 'styled-components'
import { colors } from 'shared/styles/theme'
import { fontType } from 'shared/styles/functions'

export const ModalConfigurationTitle = styled.h1`
	color: #5d615d;
	font-size: 20px;
	margin-bottom: 24px;
	margin-top: 32px;
	${fontType(0)}

	@media(max-width: 500px) {
		display: none;
	}
`

interface ISwitchesRowProps {
	switchesQuantity?: number
}

export const SwitchesRow = styled.div<ISwitchesRowProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;

	span {
		${fontType(0)}
	}

	${(props) =>
		props.className === 'first-switch-row'
			? css`
					margin-bottom: 16px;
			  `
			: null}

	${(props) =>
		props.className === 'last-switch-row'
			? css`
					margin-top: 16px;
					margin-bottom: 40px;
			  `
			: null}

	${(props) =>
		props.switchesQuantity && props.switchesQuantity > 1
		&& css`
				@media(max-width: 630px) {
					flex-direction: column;
					align-items: flex-start;

					& > span {
						margin-bottom: 16px;
						margin-top: 8px;
					}

					& > div {
						width: 100%;
						justify-content: space-between;
					}
				}
			  `}
`

export const SwitchesRowLabel = styled.span`
	font-size: 14px;
	color: ${colors.darkGrey};
	margin-right: 48px;
`

export const SwitchesWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

interface ISwitchContainerProps {
	width?: number
}

export const SwitchContainer = styled.div<ISwitchContainerProps>`
	@media (min-width: 485px) {
		${(props) =>
		props.width
			? css`
						width: ${props.width}px;
				  `
			: css`
						width: 200px;
				  `}
	}
`

export const Separator = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.separatorColor};
	margin-top: 16px;
`
