import { toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const ModalNewUserWrapper = styled.form`
	display: flex;
	flex-direction: column;

	.modal-input {
		margin: 0 0 16px 0;
	}

	.modal-button {
		align-self: center;
	}
`

export const LoadingContent = styled.div`
	justify-content: center;
	display: flex;
`

export const ModalSwitch = styled.div`
	margin-bottom: 32px;
	div {
		display: flex;
		justify-content: space-between;
		padding-top: ${toRem(8)};
	}
`
