import { createElement, useEffect, useState } from 'react'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import {
	HandleChangeFormID,
	IFormNewEvent,
	IModalEventProps,
	IModalOptionsProps,
	IModalValueProps,
	IViewProps
} from './types'
import {
	DEFAULT_CUSTOMER_PAGE,
	EVENT_FORM_PLACEHOLDER,
	ITEMS_PER_PAGE
} from './data'
import { Node } from 'slate'
import ModalEvent from './view'
import { SelectOption, TableNavProps } from '@buildbox/components'
import {
	ArrayIsEmpty,
	handleInitializeArrayOptions,
	IMAGE_INITIAL_VALUE,
	INITIAL_BODY_VALUE
} from 'shared/util/Consts'
import { IImageUpload } from 'shared/components/ImageUpload/types'
import { IAttachment } from 'shared/interfaces/attachment'
import { DownloadTypesEnum } from 'shared/interfaces/event.service'
import {
	downloadCSV,
	getEventAttendeesOrFeedbacks,
	updateEvent,
	createEvent
} from 'shared/services/event.service'
import { format, formatISO, isPast, isValid, parse } from 'date-fns'
import { NumberFormatValues } from 'react-number-format'
import { errorMessages, successMessages } from 'shared/util/Messages'
import {
	isYoutubeVideo,
	normalizeYoutubeLink
} from 'shared/util/youtubeValidator'
import {
	removePdfFileToS3,
	uploadPdfFileToS3,
	uploadToS3
} from 'shared/services/awsService'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'
import { handleTierSelectOptions } from 'shared/util/tierUtil'

function ModalEventContainer(props: IModalEventProps): JSX.Element {
	const { isActive, onClose, onEnd, event } = props

	const { user } = useTypedSelector(['user'])
	const [isLoadingCSV, setLoadingCSV] = useState(false)
	const [isLoadingTable, setLoadingTable] = useState(false)
	const [attendeesTable, setAttendeesTable] = useState(DEFAULT_CUSTOMER_PAGE)
	const [feedbackTable, setFeedbackTable] = useState(DEFAULT_CUSTOMER_PAGE)

	// EventForm
	const [eventIsPast, setEventIsPast] = useState(false)
	const [eventForm, setEventForm] = useState(EVENT_FORM_PLACEHOLDER)
	const [eventFormCopy, setEventFormCopy] = useState(EVENT_FORM_PLACEHOLDER)
	const [eventDateTime, setEventDateTime] = useState<Date | null>(null)
	const [eventDate, setEventDate] = useState<Date | null>(null)
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [selectedTier, setSelectedTier] = useState<SelectOption | null>(null)
	const [image, setImage] = useState<IImageUpload>(IMAGE_INITIAL_VALUE)
	const [youtubeLink, setYoutubeLink] = useState<string>('')
	const [body, setBody] = useState<Node[]>(INITIAL_BODY_VALUE)
	const [pdfs, setPdfs] = useState<IAttachment[]>([])
	const [enableButton, setEnableButton] = useState(false)
	const [enableAttending, setEnableAttending] = useState<boolean>(true)
	const [enableFeedback, setEnableFeedback] = useState<boolean>(true)
	const [switchValue, setSwitchValue] = useState({
		portuguese: false,
		english: false
	})
	const [ArrayOptions, setArrayOptions] = useState<string[]>([])
	function handleArrayOptions() {
		if (switchValue.english && switchValue.portuguese) {
			setArrayOptions(['PT', 'EN'])
		} else if (switchValue.english) {
			setArrayOptions(['EN'])
		} else if (switchValue.portuguese) {
			setArrayOptions(['PT'])
		} else {
			setArrayOptions([])
		}
	}
	const [tiersOptions, setTiersOptions] = useState<SelectOption[]>([])

	const isEditing = !!event?._id
	const eventId = event?._id
	const title = isEditing ? 'Detalhe do Evento' : 'Novo Evento'

	function init() {
		clearAll()
		;(async () => {
			const tierOptions = handleTierSelectOptions()

			setTiersOptions(tierOptions)

			if (isEditing) {
				getTableData(0, 'attendees')
				getTableData(0, 'feedback')

				if (!event?.date) return

				checkEventIsPast(event?.date)

				const toDate = new Date(event.date)
				const eventForm = {
					title: event.title,
					subtitle: event.subtitle || '',
					time: formatToTime(toDate),
					date: formatToDate(toDate),
					notificate: event.notificate,
					videoLink: event.videoLink,
					languages: event.languages,
					emailNotificate: event.emailNotificate,
					enableAttending: event.enableAttending,
					enableFeedback: event.enableFeedback
				}

				setEventForm(eventForm)
				setEventFormCopy(eventForm)
				setEventDateTime(toDate)
				setEventDate(toDate)
				setYoutubeLink(eventForm.videoLink)
				setSwitchValue(handleInitializeArrayOptions(eventForm.languages))

				if (
					eventForm.enableFeedback !== undefined ||
					eventForm.enableAttending !== undefined
				) {
					setEnableAttending(eventForm.enableAttending)
					setEnableFeedback(eventForm.enableFeedback)
				}

				setSelectedTier({
					label: event.tier,
					value: event.tier
				})
				setImage({
					file: null,
					url: event.coverImage
				})

				setBody(JSON.parse(event.body))
			}
		})()
	}

	function handleClose() {
		onClose()
		setTimeout(() => {
			clearAll()
		}, 0)
	}

	function handleCloseEnd() {
		onEnd()
		setTimeout(() => {
			clearAll()
		}, 0)
	}

	async function download(type: DownloadTypesEnum) {
		try {
			if (!isEditing) return
			setLoadingCSV(true)

			await downloadCSV(type, eventId as string)
		} catch (error) {
		} finally {
			setLoadingCSV(false)
		}
	}

	function getTableData(pageIndex: number, type: DownloadTypesEnum) {
		;(async () => {
			try {
				if (!isEditing) return
				setLoadingTable(true)

				const response = await getEventAttendeesOrFeedbacks(
					type,
					eventId as string,
					pageIndex + 1,
					ITEMS_PER_PAGE
				)

				type === 'attendees'
					? setAttendeesTable(response)
					: setFeedbackTable(response)
			} catch (err) {
			} finally {
				setLoadingTable(false)
			}
		})()
	}

	function checkEventIsPast(date: string) {
		const checkDateTime = isPast(new Date(date))
		setEventIsPast(checkDateTime)
	}

	function handleInputChangeDate(value: NumberFormatValues) {
		const { formattedValue } = value

		setEventForm((state) => ({ ...state, date: formattedValue }))

		const newDate = parse(formattedValue, 'dd/MM/yyyy', new Date())

		if (formattedValue.length !== 10 || !isValid(newDate)) {
			setEventDate(null)
			return
		}

		setEventDate(newDate)
	}

	function handleInputChangeTime(value: NumberFormatValues) {
		const { formattedValue } = value

		setEventForm((state) => ({ ...state, time: formattedValue }))

		const newDate = parse(formattedValue, 'HH:mm', new Date())
		if (formattedValue.length !== 5 || !isValid(newDate)) {
			setEventDateTime(null)
			return
		}
		setEventDateTime(newDate)
	}

	function mergeDates(date: Date, time: Date): Date | null {
		const dateFormated = formatToDate(date)
		const timeFormated = formatToTime(time)

		if (!dateFormated || !timeFormated) return null

		return parse(
			`${dateFormated} ${timeFormated}`,
			'dd/MM/yyyy HH:mm',
			new Date()
		)
	}

	function formatToDate(date: Date) {
		return format(date, 'dd/MM/yyyy')
	}

	function formatToTime(date: Date) {
		return format(date, 'HH:mm')
	}

	function handleChangeForm(value: string, id: HandleChangeFormID) {
		setEventForm((state) => ({ ...state, [id]: value }))
	}

	function handleTierSelect(selected: SelectOption): void {
		setSelectedTier(selected)
	}

	function handleImage(image: IImageUpload) {
		setImage(image)
	}

	function handleEditor(value: Node[]) {
		setBody(value)
	}

	function handleNotificate(switchState: boolean) {
		setEventForm((state) => ({ ...state, notificate: switchState }))
	}

	function handleEmailNotificate(switchState: boolean) {
		setEventForm((state) => ({ ...state, emailNotificate: switchState }))
	}

	function handleFeedback(switchState: boolean) {
		setEnableFeedback(switchState)
	}

	function handleAttending(switchState: boolean) {
		setEnableAttending(switchState)
	}

	function handleVideoLink(youtubeVideo: string) {
		setEventForm((state) => ({ ...state, videoLink: youtubeVideo }))
		setYoutubeLink(youtubeVideo)
	}

	function handleViewError(): boolean {
		const errors: string[] = []
		const verifyLink = youtubeLink !== '' ? isYoutubeVideo(youtubeLink) : true

		if (!eventForm.title) {
			errors.push('O Título é obrigatório')
		}

		if (!verifyLink) {
			errors.push(errorMessages.insertAValidLink)
		}

		if (!eventDate || !eventForm.date) {
			errors.push('Insira um data válida.')
		}

		if (!eventDateTime || !eventForm.time) {
			errors.push('Insira um horário válido.')
		}

		if (eventDate && eventDateTime) {
			const date = mergeDates(eventDate, eventDateTime)

			if (!date || isPast(date)) {
				errors.push('A data e hora do evento precisam ser futuras.')
			}
		}

		if (!selectedTier) {
			errors.push('O Tier é obrigatório')
		}

		if (!body) {
			errors.push('A "matéria" não pode estar vazia')
		}

		if (ArrayIsEmpty(ArrayOptions)) {
			errors.push('Preencha ao menos um idioma.')
		}

		setViewErrors(errors)

		return !!errors.length
	}

	function clearAll() {
		setEventIsPast(false)
		setEventForm(EVENT_FORM_PLACEHOLDER)
		setEventFormCopy(EVENT_FORM_PLACEHOLDER)
		setEventDateTime(null)
		setEventDate(null)
		setViewErrors([])
		setIsLoading(false)
		setSelectedTier(null)
		setImage(IMAGE_INITIAL_VALUE)
		setBody(INITIAL_BODY_VALUE)
		setEnableButton(false)
	}

	async function handleCoverImage(image: IImageUpload) {
		if (image.file) {
			const { Location } = await uploadToS3(image.file)
			return Location
		}

		return image.url || ''
	}

	async function handleUploadAttachment(pdf: IAttachment) {
		if (!pdf.file) return
		const { Location } = await uploadPdfFileToS3(pdf.file)
		return {
			url: Location,
			name: pdf.name,
			size: pdf.size
		}
	}

	function handleSubmit() {
		;(async () => {
			const errorsCount = handleViewError()
			if (errorsCount) return

			setIsLoading(!isLoading)

			let coverImage = await handleCoverImage(image)

			if (!eventDate || !eventDateTime) return
			const merged = mergeDates(eventDate, eventDateTime)
			if (!merged) return
			const dateToSend = formatISO(merged)

			const bodyToSend = JSON.stringify(body)

			const eventToSend: IFormNewEvent = {
				title: eventForm.title,
				subtitle: eventForm.subtitle || '',
				author: user._id,
				createdBy: user._id,
				coverImage: coverImage,
				date: dateToSend,
				body: bodyToSend,
				notificate: eventForm.notificate,
				tier: selectedTier?.value || '',
				videoLink: normalizeYoutubeLink(youtubeLink),
				languages: ArrayOptions,
				attachments: [] as IAttachment[],
				emailNotificate: eventForm.emailNotificate,
				enableAttending: enableAttending,
				enableFeedback: enableFeedback
			}

			try {
				if (isEditing) {
					const toUpload = pdfs.filter((pdf) => !pdf._id && !pdf.url)
					const toDelete = event.attachments.filter(
						(attachment) =>
							attachment._id && attachment.url && !pdfs.includes(attachment)
					)
					const toKeep = pdfs.filter(
						(pdf) => !toUpload.includes(pdf) && !toDelete.includes(pdf)
					)

					toDelete.map((pdf) => removePdfFileToS3(pdf.url))

					Promise.all(toUpload.map(handleUploadAttachment)).then(
						async (response) => {
							const attachments = [...response, ...toKeep]
							await updateEvent(eventId as string, {
								...eventToSend,
								attachments: attachments as IAttachment[]
							})
							cogoToast.success(
								successMessages.eventUpdated,
								cogoDefaultOptions
							)
							handleCloseEnd()
						}
					)
				} else {
					Promise.all(pdfs.map(handleUploadAttachment)).then(
						async (response) => {
							await createEvent({
								...eventToSend,
								attachments: response as IAttachment[]
							})
							cogoToast.success(
								successMessages.eventCreated,
								cogoDefaultOptions
							)
							handleCloseEnd()
						}
					)
				}
			} catch (err) {
			} finally {
				setIsLoading(!isLoading)
			}
		})()
	}

	function handleHasModification() {
		if (eventForm.videoLink !== eventFormCopy.videoLink || youtubeLink === null)
			return true
		if (eventForm.title !== eventFormCopy.title) return true
		if (eventForm.subtitle !== eventFormCopy.subtitle) return true
		if (eventForm.date !== eventFormCopy.date) return true
		if (eventForm.time !== eventFormCopy.time) return true
		if (eventForm.emailNotificate !== eventFormCopy.emailNotificate) return true
		if (enableAttending !== event.enableAttending) return true
		if (enableFeedback !== event.enableFeedback) return true
		if (!selectedTier?.value) {
			if ('' !== event?.tier) return true
		} else if (selectedTier?.value !== event?.tier) return true
		if (JSON.stringify(body) !== event?.body) return true
		if (image.url !== event?.coverImage) return true
		if (eventForm.notificate !== eventFormCopy.notificate) return true
		if (JSON.stringify(pdfs) !== JSON.stringify(event.attachments)) {
			return true
		}
		if (ArrayOptions !== event.languages) return true

		return false
	}

	function handleCheckRequiredFields() {
		return !!title && !!eventDate && !!eventDateTime && !!selectedTier
	}

	function handlePdf(value: IAttachment[]) {
		setPdfs(value)
	}

	const attendeesNavProps: TableNavProps = {
		nextPage: (pageIndex) => getTableData(pageIndex, 'attendees'),
		previousPage: (pageIndex) => getTableData(pageIndex, 'attendees'),
		gotoPage: (pageIndex) => getTableData(pageIndex, 'attendees'),
		pageCount: attendeesTable.numberOfPages,
		pageIndex: attendeesTable.pageIndex - 1,
		totalDocs: attendeesTable.totalDocs
	}

	const feedbacksNavProps: TableNavProps = {
		nextPage: (pageIndex) => getTableData(pageIndex, 'feedback'),
		previousPage: (pageIndex) => getTableData(pageIndex, 'feedback'),
		gotoPage: (pageIndex) => getTableData(pageIndex, 'feedback'),
		pageCount: feedbackTable.numberOfPages,
		pageIndex: feedbackTable.pageIndex - 1,
		totalDocs: feedbackTable.totalDocs
	}

	const modalValues: IModalValueProps = {
		image: image,
		languages: ArrayOptions,
		youtubeLink: youtubeLink,
		selectedTier: selectedTier,
		body: body,
		enableFeedback: enableFeedback,
		enableAttending: enableAttending,
		enableButton: enableButton,
		title: title,
		feedbackTable: feedbackTable,
		attendeesTable: attendeesTable
	}

	const modalOptions: IModalOptionsProps = {
		eventIsPast: eventIsPast,
		viewErrors: viewErrors
	}

	useEffect(init, [event])
	useEffect(() => {
		const enableButton = isEditing
			? handleHasModification()
			: handleCheckRequiredFields()
		setEnableButton(enableButton)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		eventForm,
		selectedTier,
		image,
		body,
		pdfs,
		ArrayOptions,
		isEditing,
		enableAttending,
		enableFeedback
	])

	useEffect(handleArrayOptions, [switchValue])

	const viewProps: IViewProps = {
		isActive,
		event,
		attendeesNavProps,
		feedbacksNavProps,
		handleImage,
		modalValues,
		modalOptions,
		eventForm,
		handleChangeForm,
		isEditing,
		handleInputChangeDate,
		handleInputChangeTime,
		handleVideoLink,
		handleTierSelect,
		handleEditor,
		handlePdf,
		handleNotificate,
		handleEmailNotificate,
		handleFeedback,
		handleAttending,
		isLoading,
		handleSubmit,
		handleClose,
		isLoadingCSV,
		download,
		isLoadingTable,
		switchValue,
		setSwitchValue,
		tiersOptions
	}

	return createElement(ModalEvent, viewProps)
}

export default ModalEventContainer
