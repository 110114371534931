import { SizeEnum } from './types'
import { font, grid } from './variables'

export function getWidthString(span: string | number) {
	if (!span) return

	let width = (Number(span) / grid.cols) * 100

	return `max-width: ${width}%;`
}

export function mediaQuery(size: SizeEnum) {
	return (style: string) => {
		if (size === 'lg') {
			return `@media screen and (max-width: ${grid.lg}px) {
				${style}
			}`
		} else if (size === 'md') {
			return `@media screen and (max-width: ${grid.md}px) {
				${style}
			}`
		} else if (size === 'sm') {
			return `@media screen and (max-width: ${grid.sm}px) {
				${style}
			}`
		} else if (size === 'xs') {
			return `@media screen and (max-width: ${grid.xs}px) {
				${style}
			}`
		}
	}
}

export function notSelectText() {
	return `
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	pointer-events: none;
	`
}

export function transition(seconds = 0.3) {
	return `-webkit-transition: all ${seconds}s;
	-moz-transition: all ${seconds}s;
	transition: all ${seconds}s;`
}

export function fontType(type = 0) {
	if (type === -1) {
		return `font-family: 'Light', sans-serif;
		font-weight: 300;
		`
	} else if (type === 0) {
		return `font-family: 'Regular', sans-serif;
		font-weight: 400;
		`
	} else if (type === 1) {
		return `font-family: 'Medium', sans-serif;
		font-weight: 500;
		`
	} else if (type === 2) {
		return `font-family: 'Bold', sans-serif;
		font-weight: 700;
		`
	}
}

export function toRem(size: number) {
	return `${size / font.baseFontSize}rem`
}

export function ellipsis() {
	return `
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: normal;
		max-width: 100%;
		overflow: hidden;
		line-height: 1rem;
	`
}
