import React, { useState } from 'react'

import { IInputPasswordProps } from './types'
import { Eye, EyeOff } from 'react-feather'
import { InputPasswordWrapper } from './styles'
import { FloatLabelInput } from '@buildbox/components'

function InputPassword({
	id,
	label,
	value,
	onChange,
	...props
}: IInputPasswordProps) {
	const [eye, setEye] = useState(false)

	function handleClickOnEye() {
		setEye(!eye)
	}

	return (
		<InputPasswordWrapper>
			<FloatLabelInput
				id={id}
				label={label}
				value={value}
				onChange={onChange}
				type={eye ? 'text' : 'password'}
				{...props}
			/>
			<>
				{eye ? (
					<Eye className='eye-icon' onClick={handleClickOnEye} />
				) : (
					<EyeOff className='eyeoff-icon' onClick={handleClickOnEye} />
				)}
			</>
		</InputPasswordWrapper>
	)
}

export default InputPassword
