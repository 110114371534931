import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import {
	CustomerStatusEnum,
	ICustomer,
	ICustomersPage,
	IUpdateCustomer
} from 'shared/interfaces/customer'
import {
	IGetMetricsRequest,
	IGetMetricsResponse
} from 'shared/interfaces/customer.service'
import axiosInstance from './axiosInstance'

export async function fetchCustomerPage(
	pageIndex: number,
	maxItemsInPage: number,
	status: CustomerStatusEnum[],
	searchString = ''
): Promise<ICustomersPage> {
	const response = await axiosInstance.post('/customer/get-page', {
		pageIndex,
		maxItemsInPage,
		status,
		searchString
	})

	return response.data.content
}

export async function fecthCustomerEdit(
	customerId: string,
	data: IUpdateCustomer
): Promise<ICustomer[]> {
	const response = await axiosInstance.patch('/customer/' + customerId, data)

	return response.data.content
}

export async function getCustomerCompanies(): Promise<string[]> {
	const response = await axiosInstance.get('/customer/companies')

	return response.data.content
}

export async function getMetrics(
	payload: IGetMetricsRequest
): Promise<IGetMetricsResponse> {
	const response = await axiosInstance.post('/customer/metrics', payload)

	return response.data.content
}

export async function exportUsers() {
	const response = await axiosInstance.get('/customer/download-customer', {
		responseType: 'blob'
	})
	const fileName = `customers_${format(
		new Date(),
		"dd-MM-yyyy'T'HH:mm:ss"
	)}.xlsx`
	fileDownload(response.data, fileName)
}

export async function uploadCustomers(file: FormData) {
	try {
		const response = await axiosInstance.post(
			'/customer/upload-customer',
			file,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		)

		return response.data
	} catch (err) {
		return err.response
	}
}
