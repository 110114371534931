import React from 'react'
import { Button, Table, Loading } from '@buildbox/components'

import { IViewProps } from './types'
import { EventsListWrapper, Header, InputSearchWrapper } from './styles'

import PageTitle from 'shared/components/PageTitle'
import { tableStyle, primaryButtonStyle, colors } from 'shared/styles/theme'
import DefaultPagePlaceholder from 'shared/components/DefaultPagePlaceholder'
import ModalEvent from 'modules/ModalEvent/index'
import ModalDelete from 'shared/components/ModalDelete'
import InputSearch from 'shared/components/InputSearch'
import { initializeEvent } from 'shared/services/event.service'

function EventsList(props: IViewProps): JSX.Element {
	const {
		tableRows,
		tableColumns,
		eventsPerPage,
		navProps,
		isLoading,
		handleSelectEvent,
		selectedEvent,
		eventToDelete,
		handleSearch,
		search,
		handleCloseModal,
		handleCloseDeleteModal,
		handleEndModal,
		handleEndDeleteModal
	} = props

	return (
		<>
			<EventsListWrapper>
				<Header>
					<PageTitle>Eventos</PageTitle>

					<div>
						<InputSearchWrapper>
							<InputSearch
								id='search'
								label='Buscar evento'
								onChange={(e) => handleSearch(e.target.value)}
								value={search}
							/>
						</InputSearchWrapper>
						<Button
							{...primaryButtonStyle}
							onClick={() => handleSelectEvent(initializeEvent(), true)}
							id='button'
						>
							{'NOVO EVENTO'}
						</Button>
					</div>
				</Header>

				{isLoading ? (
					<div className='table-loading'>
						<Loading type='Sentry' primaryColor={colors.secondary} />
					</div>
				) : (
					<div className='table-container'>
						{tableRows.length ? (
							<Table
								columns={tableColumns as any}
								data={tableRows}
								navProps={navProps}
								pageSize={eventsPerPage}
								{...tableStyle}
								padding={[8, 16, 8, 16]}
							/>
						) : (
							<DefaultPagePlaceholder text='No momento, não há Eventos para serem exibidos.' />
						)}
					</div>
				)}

				{selectedEvent.status ? (
					<ModalEvent
						event={selectedEvent.event}
						isActive={selectedEvent.status}
						onClose={handleCloseModal}
						onEnd={handleEndModal}
					/>
				) : null}

				<ModalDelete
					target={eventToDelete}
					onClose={handleCloseDeleteModal}
					type='EVENT'
					onEnd={handleEndDeleteModal}
				/>
			</EventsListWrapper>
		</>
	)
}

export default EventsList
