// TODO: Criar um Bucket para a XP

import * as AWS from 'aws-sdk'
import { UtilGenerators } from '@buildbox/utils'

const region = 'us-east-1'
const bucketName = 'xpi-macro-politica'
const accessKeyId = 'AKIAIKWWJZO4UODKWBPA'
const secretAccessKey = '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'

function config(): void {
	try {
		AWS.config.update({
			region,
			accessKeyId,
			secretAccessKey
		})
	} catch (err) {
		console.log({ err })
	}
}
export function uploadToS3(file: any) {
	config()
	const S3: AWS.S3 = new AWS.S3({
		params: { Bucket: bucketName }
	})

	// const [name, ...rest] = file.name.split('.')
	const [, ...rest] = file.name.split('.')
	const extension: string = rest[rest.length - 1] || 'jpg'
	const fileKey = `${UtilGenerators.generateRandomSerial(16)}.${extension}`
	const uploadPromise: Promise<AWS.S3.ManagedUpload.SendData> = S3.upload({
		Key: fileKey,
		Bucket: bucketName,
		Body: file
	}).promise()

	return uploadPromise
}

export function uploadToS364(base64: string) {
	config()
	const S3: AWS.S3 = new AWS.S3({
		params: { Bucket: bucketName }
	})

	const base64Data = Buffer.from(
		base64.replace(/^data:image\/\w+;base64,/, ''),
		'base64'
	)

	const extension = base64.split('/')[1].split(';')[0] || 'jpg'

	const fileKey = `${UtilGenerators.generateRandomSerial(16)}.${extension}`

	const uploadPromise: Promise<AWS.S3.ManagedUpload.SendData> = S3.upload({
		Key: fileKey,
		Bucket: bucketName,
		Body: base64Data,
		ContentEncoding: 'base64',
		ContentType: `image/${extension}`
	}).promise()

	return uploadPromise
}

export function uploadPdfFileToS3(file: File) {
	config()
	const S3: AWS.S3 = new AWS.S3({
		params: { Bucket: bucketName }
	})

	const [, ...rest] = file.name.split('.')
	const extension: string = rest[rest.length - 1] || 'jpg'
	const fileKey = `${UtilGenerators.generateRandomSerial(16)}.${extension}`
	const uploadPromise: Promise<AWS.S3.ManagedUpload.SendData> = S3.upload({
		Key: fileKey,
		Bucket: bucketName,
		Body: file,
		ContentType: 'application/pdf',
		ACL: 'public-read'
	}).promise()

	return uploadPromise
}

export function removePdfFileToS3(pdfUrl: string | undefined) {
	config()
	const S3: AWS.S3 = new AWS.S3({
		params: { Bucket: bucketName }
	})

	if (!pdfUrl) return

	// "https://s3-buildbox.s3.amazonaws.com/kVFqM6EIauTtBTX6.pdf"
	// "https://s3-buildbox.s3.amazonaws.com/>>>kVFqM6EIauTtBTX6.pdf<<<"

	// eslint-disable-next-line
	const [_, __, ___, key] = pdfUrl.split('/')

	S3.deleteObject({ Bucket: bucketName, Key: key }, function (err, data) {
		if (err) console.log(err)
		else return data
	})

	// return deletePdfPromise
}
