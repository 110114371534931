import { mediaQuery, toRem } from 'shared/styles/functions'
import styled from 'styled-components'

export const LoginWrapper = styled.div`
	display: flex;
	width: 100%;
	min-height: 100vh;

	.background-wrapper {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		background-image: url(${require('assets/images/login-background-cement-1920@2x.png')});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		${mediaQuery('md')(`
			flex-direction: column;
			align-items: center;
		`)};

		.logo-wrapper {
			margin: 100px 28px 100px 215px;
			max-width: ${toRem(283)};
			${mediaQuery('md')(`
				margin: 100px 0 0 0;
			`)};
		}

		.inputs-wrapper {
			flex-direction: column;
			background-color: #fff;
			display: flex;
			width: 360px;
			height: 346px;
			align-items: center;
			margin: 100px 175px 100px 28px;
			border-radius: 4px;

			${mediaQuery('md')(`
				margin: 0 0 240px 0;
			`)};

			.header {
				flex: 1;
				text-align: center;
				margin: 0;
				height: 20px;
			}

			.fields {
				flex: 1;
				text-align: center;
				margin: 0px 16px;

				.input {
					margin-bottom: 12px;
					width: 312px;
					height: 58px;
					@media (max-width: 380px) {
						width: 90%;
					}
				}

				.button {
					margin-top: 40px;
				}

				@media (max-width: 380px) {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
			}

			@media (max-width: 380px) {
				width: 90%;
			}
		}
	}
`
