import { IImageUploadProps } from './types'
import React, { ChangeEvent } from 'react'
import { ImageUploadWrapper } from './styles'
import { Image, Trash } from 'react-feather'

export default function ImageUpload({
	isDisabled = false,
	returnImage,
	imageUrl
}: IImageUploadProps) {
	function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		const selectedImage = event.target?.files?.item(0)

		if (!selectedImage) return

		returnImage({
			file: selectedImage,
			url: URL.createObjectURL(selectedImage)
		})

		event.target.value = ''
	}

	function handleDelete() {
		returnImage({ file: null, url: '' })
	}

	return (
		<ImageUploadWrapper>
			<label
				className='label'
				htmlFor='image'
				style={{
					opacity: `${isDisabled ? 0.4 : 1.0}`,
					cursor: isDisabled ? 'not-allowed' : 'pointer'
				}}
			>
				{imageUrl ? (
					<img id='preview-image' src={imageUrl} alt='Imagem de capa' />
				) : (
					<div className='placeholder'>
						<div className='wrapper'>
							<Image className='icon' />
							<span className='text'>
								imagem de capa <br />
								800x432
							</span>
						</div>
					</div>
				)}
				<input
					disabled={isDisabled}
					className='input'
					id='image'
					type='file'
					accept='.png, .jpg, .jpeg'
					onChange={handleInputChange}
				/>
			</label>

			{imageUrl && !isDisabled && (
				<Trash onClick={handleDelete} className='trash-icon' />
			)}
		</ImageUploadWrapper>
	)
}
