export const successMessages = {
	passwordUpdated: 'Senha alterada com sucesso!',
	passwordResetEmailSent: 'Enviamos um link de redefinição de senha para: ',
	userCreated: 'Usuário cadastrado com sucesso!',
	userUpdated: 'Usuário atualizado com sucesso!',
	userDeleted: 'Usuário excluído com sucesso!',
	customerUpdated: 'Cliente atualizado com sucesso!',
	liveCreated: 'Latest publicado com sucesso!',
	liveUpdated: 'Latest atualizado com sucesso!',
	liveDeleted: 'Latest excluído com sucesso!',
	trendCreated: 'Hot Topic publicado com sucesso!',
	trendUpdated: 'Hot Topic atualizado com sucesso!',
	trendDeleted: 'Hot Topic excluído com sucesso!',
	eventCreated: 'Evento publicado com sucesso!',
	eventUpdated: 'Evento atualizado com sucesso!',
	eventDeleted: 'Evento excluído com sucesso!'
}

export const errorMessages = {
	serverUnavailable: 'Servidor indisponível...',
	insertAValidLink: 'Digite um Link valido!'
}
