import { FloatLabelInputWrapper } from './styles'
import { IFloatLabelDateInputProps } from './types'

import calendarIcon from '../../../assets/icons/calendar.svg'
import React from 'react'

import NumberFormat from 'react-number-format'
import { FloatLabelInput } from '@buildbox/components'

function FloatLabelDateInput({
	label,
	id,
	className,
	setDate,
	date,
	...rest
}: IFloatLabelDateInputProps) {
	return (
		<FloatLabelInputWrapper className={className}>
			<NumberFormat
				className='fli-input'
				id={id}
				required
				format='##/##/####'
				mask='_'
				label={label}
				value={date}
				onValueChange={setDate}
				{...rest}
				customInput={FloatLabelInput}
			/>

			<img src={calendarIcon} alt='calendar icon' className='icon' />
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelDateInput
